import { API } from 'aws-amplify';

import { makeTransaction, addTsWatchlistSymbol } from './mutations';
import {
  // esSearchInstitution as searchInstitute,
  portfolioHealthCheckByInst,
  portfolioHealthCheckBySector,
  portfolioHealthCheckByFavInst,
  // stockPrice,
  transactionHistory,
  portfolioSnapshot,
  userPortfolio,
  userPortfolioMovement,
  symbolDetail,
  getUserWallet,
  tsWatchList,
  //resetPortfolio,
  updatePortfolioStockNote,
  userPortfolioList,
  addPortfolio,
  movePortfolioItem,
  deletePortfolio,
  updateTsWatchlistNote,
} from './queries';

// export const searchInstitutions = (keyword: string) =>
//   API.graphql({
//     variables: {
//       keyword,
//     },
//     query: searchInstitute,
//   }).then(({ data }) =>
//     (data.esSearchInstitution || []).map((item) => ({
//       name: item.name,
//       cik: item.cik,
//     })),
//   );

export const portFolioSearchByInst = (payload) =>
  API.graphql({
    variables: { request: payload },
    query: portfolioHealthCheckByInst,
  }).then(({ data }) => data.portfolioHealthCheckByInst);

export const getTSWatchlistData = (payload) =>
  API.graphql({
    variables: { request: payload },
    query: tsWatchList,
  }).then(({ data }) => data.tsWatchList);

export const portFolioSearchBySector = (payload) =>
  API.graphql({
    variables: { request: payload },
    query: portfolioHealthCheckBySector,
  }).then(({ data }) => data.portfolioHealthCheckBySector);

export const portFolioSearchByFavInst = (payload) =>
  API.graphql({
    variables: { request: payload },
    query: portfolioHealthCheckByFavInst,
  }).then(({ data }) => data.portfolioHealthCheckByFavInst);

export const getUserPortFolio = (payload) =>
  API.graphql({
    variables: {
      request: payload,
    },
    query: userPortfolio,
  }).then(({ data }) => data.userPortfolio);

export const getUserPortFolioMovement = (payload) =>
  API.graphql({
    variables: {
      request: payload,
    },
    query: userPortfolioMovement,
  }).then(({ data }) => data.userPortfolioMovement);

export const savePortfolioInfo = (
  UserInfo,
  portfolioType: string,
  portfolioName: string,
) =>
  API.graphql({
    variables: {
      user: {
        portfolio: UserInfo,
        portfolioType: portfolioType,
        portfolioName: portfolioName,
      },
    },
    query: `mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      portfolio {
        avg_cost
        id
        no_of_shares
        company {
          cik
          industry
          ipo_year
          market_cap
          name
          sec_company_name
          sector
          symbol
        }
      }
    }
  }`,
  }).then(({ data }) => data.updateUser);

export const BuyOrSellStock = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: makeTransaction,
  }).then(({ data }) => data.makeTransaction);

export const addSymbolToTsWatchlist = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: addTsWatchlistSymbol,
  }).then(({ data }) => data.addTsWatchlistSymbol);

export const getUserWalletBalances = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: getUserWallet,
  }).then(({ data }) => data.getUserWallet);

export const getTransactionHistory = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: transactionHistory,
  }).then(({ data }) => data.transactionHistory);

export const getPortfolioSnapshot = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: portfolioSnapshot,
  }).then(({ data }) => data.portfolioSnapshot);

export const getSymbolDetail = async (symbol) =>
  API.graphql({
    variables: { symbol: symbol },
    query: symbolDetail,
  }).then(({ data }) => data.symbolDetail);

export const updateStockNote = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: updatePortfolioStockNote,
  }).then(({ data }) => data.updatePortfolioStockNote);

export const updateWatchlistNote = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: updateTsWatchlistNote,
  }).then(({ data }) => data.updateTsWatchlistNote);

export const getUserPortfolios = async () =>
  API.graphql({
    query: userPortfolioList,
  }).then(({ data }) => data.userPortfolioList);

export const addNewPortfolio = async (payload: Object) =>
  API.graphql({
    variables: { request: payload },
    query: addPortfolio,
  }).then(({ data }) => data.addPortfolio);

export const movePortfolioStock = async (payload: Object) =>
  API.graphql({
    variables: { request: payload },
    query: movePortfolioItem,
  }).then(({ data }) => data.movePortfolioItem);

// export const setAlertSetting = async (payload: Object) =>
//   API.graphql({
//     variables: { user: { alerts: payload } },
//     query: `mutation UpdateUser($user: UserInput) {
//       updateUser(user: $user) {
//         alerts {
//           all_symbols
//           all_fav_symbols
//           top_10_hedge
//           all_fav_institutions
//         }
//       }
//     }`,
//   }).then(({ data }) => data.updateUser);

// export const getFavoriteInsAndSymbols = async () =>
//   API.graphql({
//     query: `query GetUser {
//       getUser {
//         favCompanies {
//           company {
//             name
//             sector
//             symbol
//           }
//           id
//         }
//         favInstitutions {
//           institution {
//             cik
//             name
//           }
//           id
//         }
//       }
//     }`,
//   }).then(({ data }) => data.getUser);

// export const addFavoriteSymbol = async (payload: Object) =>
//   API.graphql({
//     variables: { user: { favCompanies: payload } },
//     query: `mutation UpdateUser($user: UserInput) {
//       updateUser(user: $user) {
//         favCompanies {
//           company {
//             symbol
//             name
//             sector
//           }
//           id
//         }
//       }
//     }`,
//   }).then(({ data }) => data.updateUser);

// export const addFavoriteInstitution = async (payload: Object) =>
//   API.graphql({
//     variables: { user: { favInstitutions: payload } },
//     query: `mutation UpdateUser($user: UserInput) {
//       updateUser(user: $user) {
//         favInstitutions {
//           id
//           institution {
//             cik
//             name
//           }
//         }
//       }
//     }`,
//   }).then(({ data }) => data.updateUser);

// export const deleteFavoriteInstitution = async (payload: Object) =>
//   API.graphql({
//     variables: { user: { favInstitutions: payload } },
//     query: `mutation UpdateUser($user: UserInput) {
//       updateUser(user: $user) {
//         favInstitutions {
//           id
//           institution {
//             cik
//             name
//           }
//         }
//       }
//     }`,
//   }).then(({ data }) => data.updateUser);

// export const deleteFavoriteSymbol = async (payload: Object) =>
//   API.graphql({
//     variables: { user: { favCompanies: payload } },
//     query: `mutation UpdateUser($user: UserInput) {
//       updateUser(user: $user) {
//         favCompanies {
//           company {
//             symbol
//             name
//             sector
//           }
//           id
//         }
//       }
//     }`,
//   }).then(({ data }) => data.updateUser);

export const deleteUserPortfolio = async (payload) =>
  API.graphql({
    variables: { request: payload },
    query: deletePortfolio,
  }).then(({ data }) => data.deletePortfolio);
// export const isUserProfileComplete = async () =>
//   API.graphql({
//     variables: { request: { dashboard: true } },
//     query: `query GetUser {
//       getUser {
//         financialInfo {
//           expected_return_range
//           experiences
//           id
//           income_range
//           investment_exp
//           investment_range
//           lost_money
//           has_portfolio
//         }
//       }
//     }`,
//   }).then(({ data }) => data.getUser);

// export const getStockLivePrice = async (symbol) =>
//   API.graphql({
//     variables: { request: { symbol: symbol } },
//     query: stockPrice,
//   }).then(({ data }) => data.stockPrice);

// export const getUserVirtualPortFolio = (payload) =>
//   API.graphql({
//     variables: {
//       request: payload,
//     },
//     query: userPortfolio,
//   }).then(({ data }) => data.userPortfolio);
