import React from 'react';

import { get } from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import {
  statusOptions,
  statusOptions2,
  statusOptions3,
  marketCapOptions,
  formatColumn,
  statusOptionsDowHighLevelReport,
  formatNumber2,
  //getTimeRange,
} from '../../utils';
import styles from './style.module.scss';

// export const dividendsInfo = [
//   'symbol',
//   'lastQtr1Dividend',
//   'lastQtr2Dividend',
//   'lastQtr3Dividend',
//   'lastQtr4Dividend',
//   'lastQtr5Dividend',
//   'lastQtr6Dividend',
//   'lastQtr7Dividend',
//   'lastQtr8Dividend',
// ];

// export const priceHistory = [
//   'symbol',
//   'lastSale',
//   'yearBeginPrice',
//   'c52WkLow',
//   'c52WkHigh',
//   'c5YrHigh',
//   'c5YrLow',
//   'c5YrHighDate',
//   'c5YrLowDate',
// ];

// export const quarterlyPriceHistory = [
//   'symbol',
//   'lastQtr1Avg',
//   'lastQtr2Avg',
//   'lastQtr3Avg',
//   'lastQtr4Avg',
//   'lastQtr5Avg',
//   'lastQtr6Avg',
//   'lastQtr7Avg',
//   'lastQtr8Avg',
//   'lastSale',
// ];

// export const quarterlyCounts = [
//   'symbol',
//   'lastQtr1Count',
//   'lastQtr2Count',
//   'lastQtr3Count',
//   'lastQtr4Count',
//   'lastQtr5Count',
//   'lastQtr6Count',
//   'lastQtr7Count',
//   'lastQtr8Count',
//   'status',
//   'lastSale',
//   'oneYrGrowth',
//   'fiveYrGrowth',
//   'ytd',
//   'conclusion',
// ];

// export const quarterlyShares = [
//   'symbol',
//   'lastQtr1Shares',
//   'lastQtr2Shares',
//   'lastQtr3Shares',
//   'lastQtr4Shares',
//   'lastQtr5Shares',
//   'lastQtr6Shares',
//   'lastQtr7Shares',
//   'lastQtr8Shares',
//   'status',
//   'conclusion',
// ];

// export const instMfShares = [
//   'symbol',
//   'lastQtr1Count',
//   'lastQtr1Shares',
//   'top10MFShares',
//   'totalInstMfShares',
//   'lastSale',
//   'totalInstMfShareValue',
// ];

// export const mfSharesDetails = [
//   'mfName',
//   'dateReported',
//   'shares',
//   'sharesValue',
// ];

// export const statusCounts = [
//   'status',
//   'lastQtr1Count',
//   'lastQtr1Shares',
//   'lastQtr1InvestedValue',
// ];

// export const top10InstShares = [
//   'institutionName',
//   'lastQtr1Shares',
//   'lastQtr2Shares',
//   'lastQtr3Shares',
//   'lastQtr4Shares',
//   'lastQtr5Shares',
//   'lastQtr6Shares',
//   'lastQtr7Shares',
//   'lastQtr8Shares',
//   'status',
//   'lastQtr1InvestedValue',
//   'allocation',
//   'tmv',
// ];

// export const instShares = [
//   'institutionName',
//   'lastQtr1Shares',
//   'lastQtr2Shares',
//   'lastQtr3Shares',
//   'lastQtr4Shares',
//   'lastQtr5Shares',
//   'lastQtr6Shares',
//   'lastQtr7Shares',
//   'lastQtr8Shares',
//   'rishiPrevShares',
//   'rishiCurrShares',
//   'dateReported',
//   'status',
//   'lastQtr1InvestedValue',
//   'allocation',
//   'aum',
// ];

// export const recentSECShares = [
//   'institutionName',
//   'dateReported',
//   'prevShares',
//   'currentShares',
//   'secForm',
// ];

export const compare1 = [
  'symbol',
  'name',
  'sector',
  'sec13dCount',
  'lastQtr1Avg',
  'lastQtr1Count',
  'c52WkHigh',
  'lastSale',
  'priceDiff',
  'possGrowth',
];

export const compare2 = [
  'symbol',
  'name',
  'sector',
  'sec13dCount',
  'lastQtr1Avg',
  'lastQtr1Count',
  'lastQtr2Count',
  'changeCountStatus',
  'lastQtr3Count',
  'lastQtr4Count',
  'lastQtr5Count',
  'lastQtr6Count',
  'lastQtr7Count',
  'lastQtr8Count',
  'lastSale',
  'ytdPriceChange',
  'ytd',
  'oneYrGrowth',
  'fiveYrGrowth',
  'yearBeginPrice',
  'conclusion',
];

export const compare3 = [
  'symbol',
  'name',
  'sector',
  'lastQtr1HedgeCount',
  'lastQtr2HedgeCount',
  'changeHedgeCountStatus',
  'lastQtr3HedgeCount',
  'lastQtr4HedgeCount',
  'lastQtr5HedgeCount',
  'lastQtr6HedgeCount',
  'lastQtr7HedgeCount',
  'lastQtr8HedgeCount',
  'lastSale',
  'ytdPriceChange',
  'ytd',
  'oneYrGrowth',
  'fiveYrGrowth',
  'yearBeginPrice',
  'conclusion',
];

export const compare4 = [
  'symbol',
  'lastQtr1Count',
  'lastQtr2Count',
  'changeCountStatus',
  'lastQtr3Count',
  'lastQtr4Count',
  'lastQtr5Count',
  'lastQtr6Count',
  'lastQtr7Count',
  'lastQtr8Count',
  'lastQtr1HedgeCount',
  'lastQtr2HedgeCount',
  'changeHedgeCountStatus',
  'lastQtr3HedgeCount',
  'lastQtr4HedgeCount',
  'lastQtr5HedgeCount',
  'lastQtr6HedgeCount',
  'lastQtr7HedgeCount',
  'lastQtr8HedgeCount',
  'lastSale',
  'action',
];

export const compare5 = [
  'symbol',
  'lastQtr1Shares',
  'lastQtr2Shares',
  'changeSharesStatus',
  'lastQtr3Shares',
  'lastQtr4Shares',
  'lastQtr5Shares',
  'lastQtr6Shares',
  'lastQtr7Shares',
  // 'lastQtr8Shares',
  'lastQtr1HedgeShares',
  'lastQtr2HedgeShares',
  'changeHedgeSharesStatus',
  'lastQtr3HedgeShares',
  'lastQtr4HedgeShares',
  'lastQtr5HedgeShares',
  'lastQtr6HedgeShares',
  'lastQtr7HedgeShares',
  // 'lastQtr8HedgeShares',
];

export const compare6 = [
  'symbol',
  'name',
  'institutionName',
  'lastQtr1Shares',
  'lastQtr2Shares',
  'changeSharesStatus',
  'lastQtr3Shares',
  'lastQtr4Shares',
  'lastQtr5Shares',
  'lastQtr6Shares',
  'lastQtr7Shares',
  'lastQtr8Shares',
  'marketValue',
];
export const compare7 = [
  'symbol',
  'lastQtr1Avg',
  'lastQtr2Avg',
  'pctgain',
  'lastQtr3Avg',
  'lastQtr4Avg',
  'lastQtr5Avg',
  'lastQtr6Avg',
  'lastQtr7Avg',
  'lastQtr8Avg',
  'lastSale',
];

export const arrangeColumns2 = (columns) => {
  const formattedColumns = (columns || []).map((col, index) => ({
    key: col.key,
    name: col,
    value: col.value,
    dataIndex: col.key,
    index: col.key === 'symbol' ? 0 : index + 1,
  }));
  return formattedColumns;
};

export const arrangeColumns = (columns = {}) => {
  const formattedColumns = Object.keys(columns).map((col, index) => ({
    key: col,
    name: col,
    value: columns[col],
    index: col === 'symbol' || col === 'symbols' ? 0 : index + 1,
  }));
  return formattedColumns;
};

export const filtersNotToShow = (type: string) => {
  switch (type) {
    case 'TRENDING_STOCK':
      return ['date', 'isSP', 'isNasdaq', 'isDow'];
    case 'INDIVIDUAL_STATISTICAL_REPORT':
      return [];
    case 'PRICE_HISTORY':
      return [];
    case 'INDIVIDUAL_STATUS_REPORT':
      return [];
    case 'COMPARE_SYMBOLS':
      return [];
    case 'VERIFY_SYMBOLS_VS_INSTITUTIONS':
      return [];
    case 'TOP_TWENTY_FIVE_SYMBOLS':
      return [];
    case 'MOVING_AVERAGES':
      return [];
    case 'DOW_THEORY_UPTREND':
    case 'TS_WEEKLY_REPORT':
    case 'DOW_DAILY_PRICE_UPTREND':
    case 'DOW_THEORY_DOWNTREND':
    case 'DOW_DAILY_PRICE_DOWNTREND':
    case 'DOW_DAILY_PRICE_VOLUME_UPTREND':
    case 'DOW_DAILY_PRICE_VOLUME_DOWNTREND':
      return [];
    case 'ALL_SYMBOLS':
      return [];
    case 'INDIVIDUAL_INSTITUTION_REPORT':
      return [];
    case 'INSTITUTION_PERFORMANCE_REPORT':
      return ['status'];
    case 'INSTITUTION_ALLOCATION_REPORT':
      return [];
    case 'COMPARE_INSTITUTIONS':
      return [];
    case 'VERIFY_INSTITUTIONS_VS_SYMBOLS':
      return [];
    case 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS':
      return [];
    case 'THINKSABIO_DIAMONDS':
      return [];
    case 'INCREASED':
      return ['criterion'];
    case 'NO_CHANGE':
      return ['criterion'];
    case 'DECREASED':
      return ['criterion'];
    case 'NEWLY_BOUGHT':
      return ['criterion'];
    case 'SOLD_OFF':
      return ['criterion'];
    case 'DAILY_REPORT':
      return [];
    case 'SYMBOL_SUMMARY':
      return [];
    case 'DETAIL_SUMMARY_REPORT':
      return [];
    case 'BY_APPROX_TXN_VAL':
      return ['status'];
    case 'DAILY_REPORT_MUTUAL_FUNDS':
      return [];
    case 'BY_FUND_SYMBOL':
      return [];
    case 'SYMBOL_SUMMARY_MUTUAL_FUNDS':
      return [];
    case 'BY_FUND_NAME':
      return [];
    case 'ALL_INSTITUTIONS':
      return [];
    case 'TOP_10_HEDGE':
      return [];
    case 'ALLOCATIONS':
    case 'SECTOR_TRENDING':
      return ['category'];
    case 'PRICE':
      return [];
    case 'INSIDER_DAILY_REPORT':
      return ['type'];
    case 'INSIDER_ALL_CUMULATIVE_REPORT':
      return ['type'];
    case 'INSIDER_DETAIL_SUMMARY_REPORT':
      return ['type'];
    case 'INSIDER_SYMBOL_SUMMARY':
      return ['endDate', 'startDate'];
    case 'OPTION_ALL_INSTITUTION':
      return [];
    case 'OPTION_BY_SYMBOL':
      return [];
    case 'OPTION_BY_INSTITUTION':
      return [];
    case 'BUILD_YOUR_OWN':
      return [];
    case 'TOP_GAINERS':
      return ['endDate', 'startDate'];
    case 'WEEKLY_GAINS_REPORT':
      return [];
    case 'TELEGRAM_OPTIONS':
      return [];
    case 'DOW_HIGH_LEVEL_REPORT':
      return [];
    case 'DOW_DOWN_TREND_REPORT':
      return [];
    case 'WEEKLY_YTD':
      return [];
    default:
      return [];
  }
};

export const NonClickAbleFilter = (type: string) => {
  switch (type) {
    case 'TRENDING_STOCK':
      return ['date', 'isSP', 'isNasdaq', 'isDow'];
    case 'INDIVIDUAL_STATISTICAL_REPORT':
      return [];
    case 'PRICE_HISTORY':
      return [];
    case 'INDIVIDUAL_STATUS_REPORT':
      return [];
    case 'COMPARE_SYMBOLS':
      return [];
    case 'VERIFY_SYMBOLS_VS_INSTITUTIONS':
      return [];
    case 'TOP_TWENTY_FIVE_SYMBOLS':
      return [];
    case 'MOVING_AVERAGES':
      return [];
    case 'DOW_THEORY_UPTREND':
    case 'TS_WEEKLY_REPORT':
    case 'DOW_DAILY_PRICE_UPTREND':
    case 'DOW_THEORY_DOWNTREND':
    case 'DOW_DAILY_PRICE_DOWNTREND':
    case 'DOW_DAILY_PRICE_VOLUME_UPTREND':
    case 'DOW_DAILY_PRICE_VOLUME_DOWNTREND':
      return [];
    case 'ALL_SYMBOLS':
      return [];
    case 'INDIVIDUAL_INSTITUTION_REPORT':
      return [];
    case 'COMPARE_INSTITUTIONS':
      return [];
    case 'VERIFY_INSTITUTIONS_VS_SYMBOLS':
      return [];
    case 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS':
      return [];
    case 'THINKSABIO_DIAMONDS':
      return [];
    case 'INCREASED':
      return ['criterion'];
    case 'NO_CHANGE':
      return ['criterion'];
    case 'DECREASED':
      return ['criterion'];
    case 'NEWLY_BOUGHT':
      return ['criterion'];
    case 'SOLD_OFF':
      return ['criterion'];
    case 'DAILY_REPORT':
      return [];
    case 'SYMBOL_SUMMARY':
      return [];
    case 'DETAIL_SUMMARY_REPORT':
      return [];
    case 'BY_APPROX_TXN_VAL':
      return ['status'];
    case 'ALL_INSTITUTIONS':
      return ['sector'];
    case 'TOP_10_HEDGE':
      return ['sector'];
    case 'ALLOCATIONS':
      return ['category'];
    case 'SECTOR_TRENDING':
      return ['endDate', 'startDate'];
    case 'PRICE':
      return [];
    case 'INSIDER_DAILY_REPORT':
      return ['type'];
    case 'INSIDER_ALL_CUMULATIVE_REPORT':
      return ['type'];
    case 'INSIDER_DETAIL_SUMMARY_REPORT':
      return ['type'];
    case 'INSIDER_SYMBOL_SUMMARY':
      return ['endDate', 'startDate'];
    case 'OPTION_ALL_INSTITUTION':
      return [];
    case 'OPTION_BY_SYMBOL':
      return [];
    case 'OPTION_BY_INSTITUTION':
      return [];
    case 'BUILD_YOUR_OWN':
      return [];
    case 'TOP_GAINERS':
      return ['endDate', 'startDate'];
    case 'WEEKLY_YTD':
      return ['noOfWeeklyYTDWeeks'];
    default:
      return [];
  }
};

export const getSummaryFilteredData = (data) => {
  const newData = { ...data[0] };
  delete newData.inst_cik;
  return [newData];
};

export const getSymbolAnalysisData = (rawData = {}) => {
  const formattedData = [];
  for (let key of Object.keys(rawData)) {
    formattedData.push(rawData[key]);
  }
  return formattedData;
};

export const getOptionBySymbolTablesData = (rawData, symbol, theme) => {
  const data = [];

  for (let key of Object.keys(rawData)) {
    switch (key) {
      case 'top10Ins':
        data.push({
          title: `TOP 10 INSTITUTIONS OWNING for ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),
            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          type: 'single',
          rank: 1,
        });
        break;
      case 'countsVolume':
        data.push({
          title: `COUNTS FOR ${symbol}`,
          columns: formatColumn(
            arrangeColumns(rawData[key].columns).filter(
              (col) => col.visible !== false && col.value,
            ),

            false,
            [],
            null,
            false,
            rawData[key].note ? rawData[key].note : null,
            null,
            null,
            null,
            theme,
          ),
          data: rawData[key].data,
          key: `${key}-${uuidv4()}`,
          type: 'single',
          rank: 2,
        });
        break;
      default:
        break;
    }
  }
  return data
    .filter((tableData) => tableData.data.length > 0)
    .sort((a, b) => b.rank - a.rank);
};

export const getReportInfo = (
  type: string,
  queryData: {},
  reportType: string,
  returnedReport: string,
) => {
  switch (type) {
    case 'INDIVIDUAL_STATISTICAL_REPORT':
      return {
        title: 'Individual Statistical Report',
        symbol: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'Individual Statistical Report'],
      };
    case 'INDIVIDUAL_STATUS_REPORT':
      return {
        title: 'Status Report',
        symbol: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'Status Report'],
      };
    case 'PRICE_HISTORY':
      return {
        title: 'Price History',
        symbol: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'Price History'],
      };
    case 'COMPARE_SYMBOLS':
      return {
        title: 'Compare Symbols',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'Compare Symbols'],
      };
    case 'VERIFY_SYMBOLS_VS_INSTITUTIONS':
      return {
        title: 'Verify Symbols vs Institutions',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'Verify Symbols vs Institutions'],
        institutionNames: get(queryData, 'institutionsName', []),
        showQuarterDropDown: true,
        showShareQuarter: true,
        showCountQuarter: true,
      };
    case 'MOVING_AVERAGES':
      return {
        title: 'Moving Averages',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'Moving Averages'],
      };
    case 'DOW_THEORY_UPTREND':
      return {
        title: 'TS Weekly Uptrend',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'TS Weekly Uptrend'],
        showNoOfWeeks: true,
        showSP500: true,
      };
    case 'TS_WEEKLY_REPORT':
      return {
        title:
          'TS WEEKLY GEMS THAT ARE CONSISTENTLY GOING UP SINCE ATLEAST 2 WEEKS',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'TS Weekly Report'],
        showNoOfWeeks: true,
        // showSP500: true,
      };
    case 'DOW_THEORY_DOWNTREND':
      return {
        title: 'TS Weekly Downtrend',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'TS Weekly Downtrend'],
        showNoOfWeeks: true,
        showSP500: true,
      };
    case 'DOW_DAILY_PRICE_UPTREND':
      return {
        title: 'TS Daily Price Uptrend',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'TS Daily Price Uptrend'],
        showNoOfWeeks: true,
        showSP500: true,
      };
    case 'DOW_DAILY_PRICE_DOWNTREND':
      return {
        title: 'TS Daily Price Downtrend',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'TS Daily Price Downtrend'],
        showNoOfWeeks: true,
        showSP500: true,
      };
    case 'DOW_DAILY_PRICE_VOLUME_UPTREND':
      return {
        title: 'TS Daily Price & Volume Uptrend',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'TS Daily Price & Volume Uptrend'],
        showNoOfWeeks: true,
        showSP500: true,
      };
    case 'DOW_DAILY_PRICE_VOLUME_DOWNTREND':
      return {
        title: 'TS Daily Price & Volume Downtrend',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'TS Daily Price & Volume Downtrend'],
        showNoOfWeeks: true,
        showSP500: true,
      };
    case 'TRENDING_STOCK':
      return {
        title: 'Trending Stocks',
        // symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Symbol', 'Trending Stocks'],
        showDates: true,
        showSP500: true,
        // showNoOfWeeks: true,
      };
    case 'ALL_SYMBOLS':
      return {
        title: 'All Symbols',
        symbols: get(queryData, 'symbols', ''),
        breadcrumb: ['Symbol', 'All Symbols'],
        showQuarterDropDown: true,
        showShareQuarter: true,
        showCountQuarter: true,
      };
    case 'INDIVIDUAL_INSTITUTION_REPORT':
      return {
        title: `Individual Institution Report for ${get(
          queryData,
          'institutionsName',
          '',
        )}`,
        symbols: get(queryData, 'symbols', []),
        breadcrumb: [
          reportType === 'TOP_10_HEDGE' ? 'Top 10 Hedge' : 'Institution',
          'Individual Institution Report',
        ],
        institutionNames: get(queryData, 'institutionsName', []),
        showQuarterDropDown: true,
        showShareQuarter: returnedReport === 'IIR',
        showCountQuarter: true,
      };
    case 'INSTITUTION_PERFORMANCE_REPORT':
      return {
        title: `Institutional Ranking Report`,
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Institution', 'Institutional Ranking Report'],
        showQuarterDropDown: true,
        showShareQuarter: returnedReport === 'IIR',
        showCountQuarter: true,
      };
    case 'INSTITUTION_ALLOCATION_REPORT':
      return {
        title: `Institution Sector Allocation Report`,
        breadcrumb: ['Institution', 'Institution Sector Allocation Report'],
        showQuarterDropDown: true,
        showShareQuarter: returnedReport === 'IIR',
        showCountQuarter: true,
      };
    case 'TOP_TWENTY_FIVE_SYMBOLS':
      return {
        title: 'Quarterly Institutional  Buys/Sells',
        breadcrumb: ['Institution', 'Quarterly Buys & Sells'],
        showQuarterDropDown: true,
      };
    case 'COMPARE_INSTITUTIONS':
      return {
        title: 'Compare Institutions',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: [
          reportType === 'TOP_10_HEDGE' ? 'Top 10 Hedge' : 'Institution',
          'Compare Institutions',
        ],
        institutionNames: get(queryData, 'institutionsName', []),
        showQuarterDropDown: true,
        showShareQuarter: true,
        showCountQuarter: true,
      };
    case 'VERIFY_INSTITUTIONS_VS_SYMBOLS':
      return {
        title: 'Verify Institutions Vs Symbols',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: [
          reportType === 'TOP_10_HEDGE' ? 'Top 10 Hedge' : 'Institution',
          'Verify Institutions Vs Symbols',
        ],
        institutionNames: get(queryData, 'institutionsName', []),
        showQuarterDropDown: true,
        showShareQuarter: true,
        showCountQuarter: true,
      };
    case 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS':
      return {
        title:
          reportType === 'TOP_10_HEDGE'
            ? 'Top 10 Hedge Institutions count by Symbols'
            : 'All Institutions count by Symbols',
        symbols: get(queryData, 'symbols', []),
        breadcrumb:
          reportType === 'TOP_10_HEDGE'
            ? ['Top 10 Hedge', 'Top 10 Hedge Institutions count by Symbols']
            : ['Institution', 'All Institutions count by Symbols'],
        showQuarterDropDown: true,
        showShareQuarter: true,
        showCountQuarter: true,
      };
    case 'THINKSABIO_DIAMONDS':
      return {
        title: 'Thinsabio Diamonds',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Thinsabio Diamonds', 'Quarterly %Shares Change Uptrend'],
        showQuarterDropDown: true,
        showShareQuarter: false,
        showCountQuarter: false,
      };
    case 'THINKSABIO_DIAMONDS_PV':
      return {
        title: 'Thinsabio Diamonds',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Thinsabio Diamonds', 'Quarterly Price & Volume Gainers'],
        showQuarterDropDown: true,
        showShareQuarter: false,
        showCountQuarter: false,
      };
    case 'THINKSABIO_DIAMONDS_PVCS':
      return {
        title: 'Thinsabio Diamonds',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Thinsabio Diamonds', 'Quarterly CSAPV Gainers'],
        showQuarterDropDown: true,
        showShareQuarter: false,
        showCountQuarter: false,
      };
    case 'INCREASED':
      return {
        title: 'Consistently Increased by ' + get(queryData, 'criterion', ''),
        symbols: get(queryData, 'symbols', []),
        breadcrumb: [
          reportType === 'TOP_10_HEDGE' ? 'Top 10 Hedge' : 'Institution',
          'Increased',
        ],
        criterion: get(queryData, 'criterion', ''),
        showQuarterDropDown: true,
        showShareQuarter: true,
      };
    case 'NO_CHANGE':
      return {
        title: 'No Change by ' + get(queryData, 'criterion', ''),
        symbols: get(queryData, 'symbols', []),
        breadcrumb: [
          reportType === 'TOP_10_HEDGE' ? 'Top 10 Hedge' : 'Institution',
          'No Change',
        ],
        criterion: get(queryData, 'criterion', ''),
        showQuarterDropDown: true,
        showShareQuarter: true,
        showCountQuarter: true,
      };
    case 'DECREASED':
      return {
        title: 'Consistently Decreased by ' + get(queryData, 'criterion', ''),
        symbols: get(queryData, 'symbols', []),
        breadcrumb: [
          reportType === 'TOP_10_HEDGE' ? 'Top 10 Hedge' : 'Institution',
          'Decreased',
        ],
        criterion: get(queryData, 'criterion', ''),
        showQuarterDropDown: true,
        showShareQuarter: true,
      };
    case 'NEWLY_BOUGHT':
      return {
        title: 'Newly Bought',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: [
          reportType === 'TOP_10_HEDGE' ? 'Top 10 Hedge' : 'Institution',
          'Newly Bought',
        ],
        showQuarterDropDown: true,
      };
    case 'SOLD_OFF':
      return {
        title: 'Sold Off',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: [
          reportType === 'TOP_10_HEDGE' ? 'Top 10 Hedge' : 'Institution',
          'Sold Off',
        ],
        showQuarterDropDown: true,
      };
    case 'DAILY_REPORT':
      return {
        title: `Latest Report`,
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['SC-13G/D', `Latest Report`],
        showDates: true,
      };
    case 'DAILY_REPORT_MUTUAL_FUNDS':
      return {
        title: `Latest Report`,
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['MUTUAL_FUNDS', `Latest Report`],
        showDates: true,
      };
    case 'BY_FUND_NAME':
      return {
        title: `By Fund Name`,
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['MUTUAL_FUNDS', `By Fund Name`],
      };
    case 'SYMBOL_SUMMARY_MUTUAL_FUNDS':
      return {
        title: `By Symbol`,
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['MUTUAL_FUNDS', `By Symbol`],
      };
    case 'BY_FUND_SYMBOL':
      return {
        title: `By Mutual Fund Symbol`,
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['MUTUAL_FUNDS', `By Mutual Fund Symbol`],
      };
    case 'SYMBOL_SUMMARY':
      return {
        title: 'By Symbol',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Sec-13G/D', 'By Symbol'],
      };
    case 'DETAIL_SUMMARY_REPORT':
      return {
        title: 'Detail',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Sec-13G/D', 'All'],
      };
    case 'BY_APPROX_TXN_VAL':
      return {
        title: 'Latest Top Buys and Sells',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Sec-13G/D', 'Latest Top Buys and Sells'],
      };
    case 'ALL_INSTITUTIONS':
      return {
        title: 'All Institutions Report By Sector',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Sector', 'All Institutions'],
        sector: get(queryData, 'sector', ''),
        showQuarterDropDown: false,
        showShareQuarter: false,
        showCountQuarter: false,
      };

    case 'TOP_10_HEDGE':
      return {
        title: 'Top 10 Hedge Reports by Sector',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Sector', 'Top 10 Hedge'],
        sector: get(queryData, 'sector', ''),
        showShareQuarter: false,
      };
    case 'ALLOCATIONS':
      return {
        title: 'Allocations By Sector',
        symbols: get(queryData, 'symbols', ''),
        breadcrumb: ['Sector', 'Allocations'],
        sector: get(queryData, 'sector', ''),
      };
    case 'SECTOR_TRENDING':
      return {
        title: 'Sector Trending',
        symbols: get(queryData, 'symbols', ''),
        breadcrumb: ['Sector', 'Trending'],
        sector: get(queryData, 'sector', ''),
      };
    case 'PRICE':
      return {
        title: 'Price',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Price'],
        maxPrice: get(queryData, 'maxPrice', ''),
        minPrice: get(queryData, 'minPrice', ''),
        showQuarterDropDown: true,
        showShareQuarter: true,
        showCountQuarter: true,
      };
    case 'MARK_MINERVINI_STRATEGY':
      return {
        title: 'Mark Minervini Strategy',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Mark Minervini Strategy', 'Meet Criteria'],
        showQuarterDropDown: false,
        showShareQuarter: false,
        showCountQuarter: false,
      };
    case 'CRITERIA_MET':
      return {
        title: 'Mark Minervini Strategy',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Mark Minervini Strategy', 'Meet Criteria'],
        showQuarterDropDown: false,
        showShareQuarter: false,
        showCountQuarter: false,
      };
    case 'EXIT_REPORT':
      return {
        title: 'Mark Minervini Strategy',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Mark Minervini Strategy', 'No Longer Meet Criteria'],
        showQuarterDropDown: false,
        showShareQuarter: false,
        showCountQuarter: false,
      };
    case 'INSIDER_DETAIL_SUMMARY_REPORT':
      return {
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['SEC Insiders', 'All'],
        insiderName: get(queryData, 'insiderName', []),
      };
    case 'INSIDER_DAILY_REPORT':
      return {
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['SEC Insiders', 'Latest'],
        insiderName: get(queryData, 'insiderName', []),
        showDates: true,
      };
    case 'INSIDER_ALL_CUMULATIVE_REPORT':
      return {
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['SEC Insiders', 'All Cumulative'],
        insiderName: get(queryData, 'insiderName', []),
      };
    case 'INSIDER_SYMBOL_SUMMARY':
      return {
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['SEC Insiders', 'By Symbol'],
        insiderName: get(queryData, 'insiderName', []),
      };
    case 'OPTION_ALL_INSTITUTION':
      return {
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Options', 'All Institution'],
      };
    case 'OPTION_BY_SYMBOL':
      return {
        symbol: get(queryData, 'symbols', []),
        breadcrumb: ['Options', 'By Symbol'],
      };
    case 'OPTION_BY_INSTITUTION':
      return {
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Options', 'By Institution'],
      };
    case 'TOP_GAINERS':
      return {
        breadcrumb: ['Top Gainers'],
      };
    case 'WEEKLY_GAINS_REPORT':
      return {
        breadcrumb: ['Weekly Gains Report'],
      };
    case 'TELEGRAM_OPTIONS':
      return {
        breadcrumb: ['Telegram Options'],
      };
    case 'WEEKLY_YTD':
      return {
        breadcrumb: ['Weekly YTD'],
      };

    case 'DOW_HIGH_LEVEL_REPORT':
      return {
        breadcrumb: ['Dow High Level Report'],
      };
    case 'DOW_DOWN_TREND_REPORT':
      return {
        breadcrumb: ['Dow Down Trend Report'],
      };
    case 'BUILD_YOUR_OWN':
      return {
        title: 'Build Your Own',
        symbols: get(queryData, 'symbols', []),
        breadcrumb: ['Build Your Own'],
        institutionNames: get(queryData, 'institutionsName', []),
        criterion: get(queryData, 'criterion', ''),
        sector: get(queryData, 'sector', ''),
        maxPrice: get(queryData, 'maxPrice', ''),
        minPrice: get(queryData, 'minPrice', ''),
        // showQuarterDropDown: true,
        // showShareQuarter: true,
        // showCountQuarter: true,
      };
    default:
      break;
  }
};

const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase(),
  );

export const getSearchReportTitle = (
  type,
  institutionsName,
  fundName,
  symbols,
  startDate,
  lastUpdatedTime,
  criterion,
  endDate,
  returnedReport,
  reportType,
  topGainersType,
  fundSymbols,
  reportTitle?,
  status,
  multiStatus,
  total,
) => {
  console.log('end', endDate);
  switch (type) {
    case 'INDIVIDUAL_STATISTICAL_REPORT':
      return 'Individual Statistical Report';
    case 'INDIVIDUAL_STATUS_REPORT':
      return total
        ? `Top ${total < 50 ? total : 50} ${
            multiStatus === 'DECREASED' ? 'REDUCED' : titleCase(multiStatus)
          } Institutions ${
            total < 50 ? '' : `(out of ${formatNumber2(total)})`
          } For ${symbols}`
        : `${titleCase(multiStatus)} INSTITUTIONS For ${symbols}`;
    case 'COMPARE_SYMBOLS':
      return 'Compare Symbols';
    case 'TOP_TWENTY_FIVE_SYMBOLS':
      return 'Quarterly Institutional Buys & Sells';
    case 'VERIFY_SYMBOLS_VS_INSTITUTIONS':
      return 'Verify Symbols vs Institutions';
    case 'MOVING_AVERAGES':
      return 'Moving Averages';
    case 'DOW_THEORY_UPTREND':
      return 'TS Weekly Uptrend';
    case 'TS_WEEKLY_REPORT':
      return `ThinkSabio WEEKLY GEMS as of ${moment(endDate).format(
        'MMMM DD, YYYY',
      )}`;
    case 'DOW_THEORY_DOWNTREND':
      return 'TS Weekly Downtrend';
    case 'DOW_DAILY_PRICE_UPTREND':
      return 'TS Daily Price Uptrend';
    case 'DOW_DAILY_PRICE_DOWNTREND':
      return 'TS Daily Price Downtrend';
    case 'DOW_DAILY_PRICE_VOLUME_UPTREND':
      return 'TS Daily Price & Volume Uptrend';
    case 'DOW_DAILY_PRICE_VOLUME_DOWNTREND':
      return 'TS Daily Price & Volume Downtrend';
    case 'PRICE_HISTORY':
      return 'Price History';
    case 'TRENDING_STOCK':
      return lastUpdatedTime
        ? `Trending Stocks Report as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY ( hh:mm:ss A)')}`
        : `Trending Stocks Report`;
    case 'ALL_SYMBOLS':
      return 'All Symbols';
    case 'INDIVIDUAL_INSTITUTION_REPORT':
      return returnedReport === 'SEC'
        ? `All SEC 13 D/G transactions for ${institutionsName}`
        : `Individual Institution Report for ${institutionsName}`;
    case 'INSTITUTION_PERFORMANCE_REPORT':
      return `Institutional Ranking Report`;
    case 'INSTITUTION_ALLOCATION_REPORT':
      return `Institution sector Allocation Report`;
    case 'COMPARE_INSTITUTIONS':
      return 'Compare Institutions';
    case 'VERIFY_INSTITUTIONS_VS_SYMBOLS':
      return 'Verify Institutions Vs Symbols';
    case 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS':
      return reportType === 'TOP_10_HEDGE'
        ? 'Top 10 Hedge Institutions count by Symbols'
        : 'All Institutions count by Symbols';
    case 'INCREASED':
      return `Consistently Increased by ${criterion}`;
    case 'NO_CHANGE':
      return `No Change by ${criterion}`;
    case 'DECREASED':
      return `Consistently Decreased by ${criterion}`;
    case 'NEWLY_BOUGHT':
      return `Newly Bought ${criterion}`;
    case 'SOLD_OFF':
      return `Sold Off ${criterion}`;
    case 'DAILY_REPORT':
      return lastUpdatedTime
        ? `Latest SC 13D/G transactions of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY')} as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('hh:mm A')} `
        : `Latest SC 13D/G transactions`;
    case 'DAILY_REPORT_MUTUAL_FUNDS':
      return lastUpdatedTime
        ? `Latest Mutual Fund transactions of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY')} as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('hh:mm A')} `
        : `Latest Mutual Fund transactions`;
    case 'BY_FUND_NAME':
      return `Mutual Fund Report For ${fundName}`;
    case 'SYMBOL_SUMMARY_MUTUAL_FUNDS':
      return `SYMBOL SUMMARY for ${symbols}`;
    case 'BY_FUND_SYMBOL':
      return `MUTUAL FUND SYMBOL SUMMARY for ${fundSymbols} ( ${
        reportTitle ? reportTitle : ''
      })`;
    case 'SYMBOL_SUMMARY':
      return lastUpdatedTime
        ? `All SYMBOL SUMMARY from ${moment(startDate)
            .tz('America/New_York')
            .format('MMMM DD, YYYY ( hh:mm A )')} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
              : moment(endDate)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
          } `
        : `All SYMBOL SUMMARY`;
    case 'DETAIL_SUMMARY_REPORT':
      return lastUpdatedTime
        ? `All SEC 13 D/G transactions from ${moment(startDate).format(
            'MMMM DD, YYYY ( hh:mm A )',
          )} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime).format('MMMM DD, YYYY ( hh:mm A )')
              : moment(endDate).format('MMMM DD, YYYY ( hh:mm A )')
          } `
        : `All SEC 13 D/G transactions `;
    case 'BY_APPROX_TXN_VAL':
      return `${
        status === 'INCREASED'
          ? 'Latest Top Institutional Buys'
          : 'Latest Top Institutional Sells'
      } `;
    case 'ALL_INSTITUTIONS':
      return 'All Institutions Report By Sector';
    case 'TOP_10_HEDGE':
      return 'Top 10 Hedge Report by Sector';
    case 'ALLOCATIONS':
      return 'Allocations By Sector';
    case 'SECTOR_TRENDING':
      return `Sector Trending from ${moment(startDate).format('MMMM DD, YYYY')}
       to ${moment(endDate).format('MMMM DD, YYYY')}`;
    case 'PRICE':
      return 'Price';
    case 'INSIDER_DAILY_REPORT':
      return lastUpdatedTime
        ? `Latest Insider transactions of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY')} as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('hh:mm A')} `
        : `Latest Insider transactions`;
    case 'INSIDER_ALL_CUMULATIVE_REPORT':
      return lastUpdatedTime || startDate || endDate
        ? `ALL Insider Cumulative transactions from ${moment(startDate).format(
            'MMMM DD, YYYY',
          )} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY')
              : moment(endDate).tz('America/New_York').format('MMMM DD, YYYY')
          } `
        : `All Insider Cumulative transactions `;
    case 'INSIDER_DETAIL_SUMMARY_REPORT':
      return lastUpdatedTime || startDate || endDate
        ? `ALL Insider transactions from ${moment(startDate).format(
            'MMMM DD, YYYY ( hh:mm A )',
          )} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
              : moment(endDate)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
          } `
        : `All Insider transactions `;
    case 'INSIDER_SYMBOL_SUMMARY':
      return lastUpdatedTime
        ? `All Insider SUMMARY from ${moment(startDate)
            .tz('America/New_York')
            .format('MMMM DD, YYYY ( hh:mm A )')} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
              : moment(endDate)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
          } `
        : `All insider SUMMARY`;
    case 'OPTION_ALL_INSTITUTION':
      return 'OPTIONS FOR ALL INSTITUTIONS';
    case 'OPTION_BY_SYMBOL':
      return '';
    case 'OPTION_BY_INSTITUTION':
      return `Options By Institution Report for ${institutionsName}`;
    case 'BUILD_YOUR_OWN':
      return 'Build Your Own';
    case 'TOP_GAINERS':
      return `Top ${
        topGainersType === 'DECLINERS' ? 'Decliners' : 'Gainers'
      } from ${moment(startDate).format('MMMM DD, YYYY')} to ${moment(
        endDate,
      ).format('MMMM DD, YYYY')}`;
    case 'DOW_HIGH_LEVEL_REPORT':
      return `Dow High Level Report`;
    case 'WEEKLY_GAINS_REPORT':
      return `Weekly Gains Report`;
    case 'TELEGRAM_OPTIONS':
      return `Telegram Options`;
    case 'WEEKLY_YTD':
      return `Weekly YTD`;
    case 'DOW_DOWN_TREND_REPORT':
      return `Dow Down Trend Report`;
    case 'EXIT_REPORT':
      return 'Mark Minervini Exit Stocks';
    case 'CRITERIA_MET':
      return 'Mark Minervini strategy';
    case 'MARK_MINERVINI_STRATEGY':
      return 'Mark Minervini strategy';
    case 'THINKSABIO_DIAMONDS':
      return 'Thinksabio Diamonds';
    case 'THINKSABIO_DIAMONDS_PV':
      return 'Thinksabio Diamonds';
    case 'THINKSABIO_DIAMONDS_PVCS':
      return 'Thinksabio Diamonds';
    default:
      return '';
  }
};

export const filterFields = (
  code,
  trendingStockType,
  filterOptions,
  queryData,
  selectedNoOfQtrs,
) => {
  let criterion = get(queryData, 'criterion', '');
  if (criterion) {
    criterion = criterion.toUpperCase();
  }
  const fields = [
    {
      name: 'symbols',
      placeholder: 'Enter Symbol',
      label: 'Enter Symbol',
      visible:
        code !== 'ALLOCATIONS' &&
        code !== 'ALL_INSTITUTIONS' &&
        code !== 'INSTITUTION_PERFORMANCE_REPORT' &&
        code !== 'INSTITUTION_ALLOCATION_REPORT' &&
        code !== 'PRICE_HISTORY' &&
        code !== 'SECTOR_TRENDING' &&
        code !== 'SYMBOL_SUMMARY_MUTUAL_FUNDS' &&
        code !== 'TOP_10_HEDGE',
      type: 'symbols',
      size: 24,
    },
    {
      name: 'institutions',
      placeholder: 'Enter Institution',
      label: 'Enter Institution',
      visible:
        code === 'DAILY_REPORT' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'INSTITUTION_PERFORMANCE_REPORT' ||
        code === 'INSTITUTION_ALLOCATION_REPORT' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS',
      type: 'ciks',
      size: 24,
    },
    {
      name: 'insiderName',
      placeholder: 'Enter Insider Name',
      label: 'Enter Insider Name',
      visible:
        code === 'INSIDER_DAILY_REPORT' ||
        code === 'INSIDER_ALL_CUMULATIVE_REPORT' ||
        code === 'INSIDER_DETAIL_SUMMARY_REPORT',
      type: 'insider',
      size: 24,
    },
    {
      name: 'insiderTitle',
      placeholder: 'Enter Insider Title',
      label: 'Enter Insider Title',
      visible:
        code === 'INSIDER_DAILY_REPORT' ||
        code === 'INSIDER_ALL_CUMULATIVE_REPORT' ||
        code === 'INSIDER_DETAIL_SUMMARY_REPORT',
      type: 'insiderTitle',
      size: 24,
    },
    {
      name: 'isTenPercent',
      placeholder: 'Is 10% Owner',
      label: 'Select Is 10% Owner',
      visible:
        code === 'INSIDER_DAILY_REPORT' ||
        code === 'INSIDER_ALL_CUMULATIVE_REPORT' ||
        code === 'INSIDER_DETAIL_SUMMARY_REPORT',
      type: 'isTenPercent',
      size: 24,
    },
    {
      name: 'multiSector',
      placeholder: 'Sector',
      size: 12,
      // visible: true, //  code !== 'ALL_INSTITUTIONS' && code !== 'TOP_10_HEDGE',
      type: 'multiSector',
      multiple: true,
      visible:
        code !== 'SECTOR_TRENDING' &&
        code !== 'MARK_MINERVINI_STRATEGY' &&
        code !== 'CRITERIA_MET' &&
        code !== 'PRICE_HISTORY' &&
        code !== 'EXIT_REPORT' &&
        code !== 'TOP_GAINERS' &&
        code !== 'SYMBOL_SUMMARY_MUTUAL_FUNDS' &&
        code !== 'INSTITUTION_PERFORMANCE_REPORT' &&
        code !== 'WEEKLY_GAINS_REPORT',
    },
    {
      name: 'minAllocatedPerc',
      placeholder: 'Min Allocated %',
      label: 'Min Allocated %',
      allowDecimal: false,
      visible: code === 'INSTITUTION_ALLOCATION_REPORT',

      size: 12,
      type: 'minAlloactedPerc',
    },
    // {
    //   name: 'maxAllocatedPerc',
    //   placeholder: 'Max Allocated %',
    //   label: 'Max Allocated %',
    //   allowDecimal: false,
    //   visible: code === 'INSTITUTION_ALLOCATION_REPORT',
    //
    //   size: 12,
    //   type: 'maxAlloactedPerc',
    // },
    {
      name: 'minCount',
      placeholder: 'Min count',
      label: 'Min count',
      allowDecimal: false,
      visible: code === 'INSTITUTION_ALLOCATION_REPORT',

      size: 12,
      type: 'minCount',
    },
    // {
    //   name: 'maxCount',
    //   placeholder: 'Max count',
    //   label: 'Max count',
    //   allowDecimal: false,
    //   visible: code === 'INSTITUTION_ALLOCATION_REPORT',
    //
    //   size: 12,
    //   type: 'maxCount',
    // },
    {
      name: 'excludeMultiSector',
      placeholder: 'Exclude Sector',
      size: 12,
      // visible: true, //  code !== 'ALL_INSTITUTIONS' && code !== 'TOP_10_HEDGE',
      type: 'excludeMultiSector',
      multiple: true,
      visible:
        code !== 'SECTOR_TRENDING' &&
        code !== 'MARK_MINERVINI_STRATEGY' &&
        code !== 'CRITERIA_MET' &&
        code !== 'EXIT_REPORT' &&
        code !== 'TOP_GAINERS' &&
        code !== 'PRICE_HISTORY' &&
        code !== 'SYMBOL_SUMMARY_MUTUAL_FUNDS' &&
        code !== 'INSTITUTION_PERFORMANCE_REPORT' &&
        code !== 'INSTITUTION_ALLOCATION_REPORT' &&
        code !== 'WEEKLY_GAINS_REPORT',
    },
    {
      name: 'minStartPrice',
      name2: 'maxStartPrice',
      label: 'Min Start Price',
      placeholder: 'Min Start Price',
      size: 12,
      visible: code === 'WEEKLY_GAINS_REPORT',
      type: 'weeklyMinInput',
      allowDecimal: true,
    },
    {
      name: 'maxStartPrice',
      name2: 'minStartPrice',
      label: 'Max Start Price',
      placeholder: 'Max Start Price',
      size: 12,
      visible: code === 'WEEKLY_GAINS_REPORT',
      type: 'weeklyMaxInput',
      allowDecimal: true,
    },
    {
      name: 'minEndPrice',
      name2: 'maxEndPrice',
      label: 'Min End Price',
      placeholder: 'Min End Price',
      size: 12,
      visible: code === 'WEEKLY_GAINS_REPORT',
      type: 'weeklyMinInput',
      allowDecimal: true,
    },
    {
      name: 'maxEndPrice',
      name2: 'minEndPrice',
      label: 'Max End Price',
      placeholder: 'Max End Price',
      size: 12,
      visible: code === 'WEEKLY_GAINS_REPORT',
      type: 'weeklyMaxInput',
      allowDecimal: true,
    },
    {
      name: 'multiIndustry',
      placeholder: 'Industry',
      size: 12,
      visible:
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        (code !== 'ALLOCATIONS' &&
          code !== 'SECTOR_TRENDING' &&
          code !== 'MARK_MINERVINI_STRATEGY' &&
          code !== 'INSIDER_DAILY_REPORT' &&
          code !== 'INSIDER_ALL_CUMULATIVE_REPORT' &&
          code !== 'INSIDER_DETAIL_SUMMARY_REPORT' &&
          code !== 'TS_WEEKLY_REPORT' &&
          code !== 'PRICE_HISTORY' &&
          code !== 'CRITERIA_MET' &&
          code !== 'EXIT_REPORT' &&
          code !== 'TOP_GAINERS' &&
          code !== 'INSTITUTION_PERFORMANCE_REPORT' &&
          code !== 'INSTITUTION_ALLOCATION_REPORT' &&
          code !== 'DAILY_REPORT_MUTUAL_FUNDS' &&
          code !== 'BY_FUND_NAME' &&
          code !== 'SYMBOL_SUMMARY_MUTUAL_FUNDS' &&
          code !== 'BY_APPROX_TXN_VAL' &&
          code !== 'PRICE_HISTORY' &&
          code !== 'BY_FUND_SYMBOL' &&
          code !== 'WEEKLY_GAINS_REPORT') ||
        code === 'TRENDING_STOCK',
      type: 'multiIndustry',
      multiple: true,
    },
    {
      name: 'minInstShares',
      placeholder: 'Min Inst Shares',
      label: 'Min Inst Shares',
      allowDecimal: false,
      visible:
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'MOVING_AVERAGES' ||
        code === 'INSTITUTION_ALLOCATION_REPORT' ||
        code === 'SYMBOL_SUMMARY',
      size: 12,
      type: 'minInstShares',
    },
    {
      name: 'maxInstShares',
      placeholder: 'Max Inst Shares',
      label: 'Max Inst Shares',
      allowDecimal: false,
      visible: code === 'BY_APPROX_TXN_VAL',
      size: 12,
      type: 'maxInstShares',
    },
    {
      name: 'minAproxTxnValue',
      placeholder: 'Min Approx. trxn value',
      label: 'Min Approx. trxn value',
      allowDecimal: false,
      visible:
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'BY_APPROX_TXN_VAL',
      size: 12,
      type: 'minAproxTxnValue',
    },
    {
      name: 'minAproxInvValue',
      placeholder: 'Min Approx. Inv value',
      label: 'Min Approx. Inv value',
      allowDecimal: false,
      visible:
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'THINKSABIO_DIAMONDS_PVCS',
      size: 12,
      type: 'minAproxInvValue',
    },
    {
      name: 'maxAproxTxnValue',
      placeholder: 'Max Approx. trxn value',
      label: 'Max Approx. trxn value',
      allowDecimal: false,
      visible: code === 'BY_APPROX_TXN_VAL',
      size: 12,
      id: 'fname',
      type: 'maxAproxTxnValue',
    },
    {
      name: 'country',
      // placeholder: 'Country',
      label: 'Country',
      allowDecimal: false,
      visible:
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS',
      size: 12,
      type: 'country',
    },
    {
      name: 'multiStatus',
      placeholder: 'Status',
      data:
        code === 'INDIVIDUAL_INSTITUTION_REPORT' && selectedNoOfQtrs > 2
          ? statusOptions2
          : code === 'MARK_MINERVINI_STRATEGY'
          ? statusOptions3
          : statusOptions,
      size: 12,
      type: 'selectObjectField',
      multiple: true,
      visible:
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'INDIVIDUAL_STATUS_REPORT' ||
        code === 'PRICE' ||
        // code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        // code === 'ALL_INSTITUTIONS' ||
        code === 'TRENDING_STOCK' ||
        code === 'DAILY_REPORT',
    },
    {
      name: 'multiMarketCap',
      placeholder: 'Market Cap',
      data: marketCapOptions,
      size: 12,
      type: 'selectObjectField',
      multiple: true,
      visible: code === 'TRENDING_STOCK' || code === 'BY_APPROX_TXN_VAL',
    },
    {
      name: 'multiStatus',
      placeholder: 'Status',
      data: statusOptionsDowHighLevelReport,
      size: 12,
      type: 'selectObjectField',
      multiple: true,
      visible:
        code === 'DOW_HIGH_LEVEL_REPORT' || code === 'DOW_DOWN_TREND_REPORT',
    },
    {
      name: 'minOwnership',
      placeholder: 'Min % Own',
      label: 'Min % Own',
      allowDecimal: true,
      visible: code === 'DAILY_REPORT' || code === 'DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'week',
      placeholder: 'Week',
      label: 'Week',
      allowDecimal: true,
      visible: code === 'WEEKLY_GAINS_REPORT',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'minCountChange',
      placeholder: 'Min % Count Change',
      label: 'Min % Count Change',
      allowDecimal: true,
      size: 12,
      type: 'minCountChange',
      visible:
        code === 'DAILY_REPORT' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        (code === 'INCREASED' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        (code === 'DECREASED' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        (code === 'NO_CHANGE' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        (code === 'NEWLY_BOUGHT' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        (code === 'SOLD_OFF' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        code === 'PRICE' ||
        code === 'ALL_SYMBOLS' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'BUILD_YOUR_OWN' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'THINKSABIO_DIAMONDS_PVCS' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'DETAIL_SUMMARY_REPORT',
    },

    {
      name: 'maxCountChange',
      placeholder: 'Max % Count Change',
      label: 'Max % Count Change',
      allowDecimal: true,
      size: 12,
      type: 'maxCountChange',
      visible:
        (code === 'INCREASED' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        (code === 'DECREASED' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        (code === 'NO_CHANGE' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        (code === 'NEWLY_BOUGHT' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        (code === 'SOLD_OFF' &&
          (criterion === 'COUNT' || criterion === 'BOTH')) ||
        code === 'PRICE' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'ALL_SYMBOLS',
    },
    {
      name: 'minPossibleGrowth',
      placeholder: 'Min % Possible Growth',
      label: 'Min % Possible Growth',
      allowDecimal: true,
      size: 12,
      type: 'minPossibleGrowth',
      visible:
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'BUILD_YOUR_OWN',
    },
    // {
    //   name: 'minRank',
    //   placeholder: 'Min Rank',
    //   label: 'Min Rank',
    //   allowDecimal: true,
    //   size: 12,
    //   type: 'minRank',
    //   visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
    // },
    // {
    //   name: 'maxRank',
    //   placeholder: 'Max Rank',
    //   label: 'Max Rank',
    //   allowDecimal: true,
    //   size: 12,
    //   type: 'maxRank',
    //   visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
    // },
    {
      name: 'filerName',
      placeholder: 'Fund Name',
      size: 12,
      type: 'fundName',
      multiple: true,
      visible:
        code === 'DAILY_REPORT_MUTUAL_FUNDS' ||
        code === 'SYMBOL_SUMMARY_MUTUAL_FUNDS',
    },
    {
      name: 'seriesName',
      placeholder: 'Series Name',
      size: 12,
      type: 'seriesName',
      multiple: true,
      visible:
        code === 'DAILY_REPORT_MUTUAL_FUNDS' ||
        code === 'SYMBOL_SUMMARY_MUTUAL_FUNDS',
    },
    {
      name: 'maxPossibleGrowth',
      placeholder: 'Max % Possible Growth',
      label: 'Max % Possible Growth',
      allowDecimal: true,
      size: 12,
      type: 'maxPossibleGrowth',
      visible:
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'BUILD_YOUR_OWN',
    },
    {
      name: 'minSharesChange',
      placeholder: 'Min % Shares Change',
      label: 'Min % Shares Change',
      size: 12,
      type: 'minSharesChange',
      allowDecimal: true,
      visible:
        code === 'DAILY_REPORT' ||
        code === 'TRENDING_STOCK' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        (code === 'INCREASED' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        (code === 'DECREASED' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        (code === 'NO_CHANGE' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        (code === 'NEWLY_BOUGHT' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        (code === 'SOLD_OFF' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        code === 'PRICE' ||
        code === 'BUILD_YOUR_OWN' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'THINKSABIO_DIAMONDS_PVCS' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'DETAIL_SUMMARY_REPORT',
    },
    {
      name: 'maxSharesChange',
      placeholder: 'Max % Shares Change',
      label: 'Max % Shares Change',
      size: 12,
      type: 'maxSharesChange',
      allowDecimal: true,
      visible:
        (code === 'INCREASED' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        (code === 'DECREASED' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        (code === 'NO_CHANGE' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        (code === 'NEWLY_BOUGHT' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        (code === 'SOLD_OFF' &&
          (criterion === 'SHARES' || criterion === 'BOTH')) ||
        code === 'PRICE' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT',
    },
    {
      name: 'minTotalSharesChange',
      placeholder: 'Min % Total Shares Change',
      label: 'Min % Total Shares Change',
      size: 12,
      type: 'minTotalSharesChange',
      allowDecimal: true,
      visible: code === 'INDIVIDUAL_INSTITUTION_REPORT',
    },
    {
      name: 'maxTotalSharesChange',
      placeholder: 'Max % Total Shares Change',
      label: 'Max % Total Shares Change',
      size: 12,
      type: 'maxTotalSharesChange',
      allowDecimal: true,
      visible: code === 'INDIVIDUAL_INSTITUTION_REPORT',
    },
    {
      name: 'minVolumeChange',
      placeholder: 'Min % Avg Volume Change',
      label: 'Min % Avg Volume Change',
      size: 12,
      type: 'minVolumeChange',
      allowDecimal: true,
      visible:
        code === 'THINKSABIO_DIAMONDS_PV' ||
        code === 'THINKSABIO_DIAMONDS_PVCS',
    },
    {
      name: 'maxVolumeChange',
      placeholder: 'Max % Avg Volume Change',
      label: 'Max % Avg Volume Change',
      size: 12,
      type: 'maxVolumeChange',
      allowDecimal: true,
      visible:
        code === 'THINKSABIO_DIAMONDS_PV' ||
        code === 'THINKSABIO_DIAMONDS_PVCS',
    },
    {
      name: 'minAvgChange',
      placeholder: 'Min % Avg Price Change',
      label: 'Min % Avg Price Change',
      size: 12,
      type: 'minAvgChange',
      allowDecimal: true,
      visible:
        code === 'THINKSABIO_DIAMONDS_PV' ||
        code === 'THINKSABIO_DIAMONDS_PVCS',
    },
    {
      name: 'maxAvgChange',
      placeholder: 'Max % Avg Price Change',
      label: 'Max % Avg Price Change',
      size: 12,
      type: 'maxAvgChange',
      allowDecimal: true,
      visible:
        code === 'THINKSABIO_DIAMONDS_PV' ||
        code === 'THINKSABIO_DIAMONDS_PVCS',
    },
    {
      name: 'minSharesChangePer',
      placeholder: 'Min Shares % Change',
      label: 'Min Shares % Change',
      size: 12,
      type: 'inputField',
      allowDecimal: true,
      visible: code === 'DAILY_REPORT' || code === 'DETAIL_SUMMARY_REPORT',
    },
    {
      name: 'minPerChange',
      placeholder: 'Min % Change',
      label: 'Min % Change',
      allowDecimal: true,
      size: 12,
      type: 'inputField',
      visible:
        code === 'DOW_HIGH_LEVEL_REPORT' ||
        code === 'DOW_DOWN_TREND_REPORT' ||
        code === 'TOP_GAINERS' ||
        code === 'WEEKLY_GAINS_REPORT',
    },

    {
      name: 'maxPerChange',
      placeholder: 'Max % Change',
      label: 'Max % Change',
      allowDecimal: true,
      size: 12,
      type: 'inputField',
      visible:
        code === 'DOW_HIGH_LEVEL_REPORT' ||
        code === 'TOP_GAINERS' ||
        code === 'DOW_DOWN_TREND_REPORT' ||
        code === 'WEEKLY_GAINS_REPORT',
    },

    {
      name: 'minPercentage',
      placeholder: 'Min % of Allocation',
      label: 'Min % of Allocation',
      allowDecimal: true,
      size: 12,
      type: 'minPercent',
      visible:
        code === 'DAILY_REPORT_MUTUAL_FUNDS' ||
        code === 'BY_FUND_NAME' ||
        code === 'BY_FUND_SYMBOL' ||
        code === 'SYMBOL_SUMMARY_MUTUAL_FUNDS',
    },

    {
      name: 'maxPercentage',
      placeholder: 'Max % of Allocation',
      label: 'Max % of Allocation',
      allowDecimal: true,
      size: 12,
      type: 'maxPercent',
      visible:
        code === 'DAILY_REPORT_MUTUAL_FUNDS' ||
        code === 'BY_FUND_NAME' ||
        code === 'BY_FUND_SYMBOL' ||
        code === 'SYMBOL_SUMMARY_MUTUAL_FUNDS',
    },

    {
      name: 'minGrowth',
      placeholder: 'Min Growth %',
      label: 'Min Growth %',
      allowDecimal: true,
      size: 12,
      type: 'inputField',
      visible: false,
    },
    {
      name: 'minYTD',
      placeholder: 'Min YTD',
      label: 'Min YTD',
      allowDecimal: true,
      visible:
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'DAILY_REPORT' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'MOVING_AVERAGES' ||
        code === 'PRICE' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'BY_FUND_SYMBOL' ||
        code === 'BY_FUND_NAME' ||
        code === 'INCREASED' ||
        code === 'DECREASED' ||
        code === 'NO_CHANGE' ||
        code === 'BUILD_YOUR_OWN' ||
        code === 'NEWLY_BOUGHT' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'SOLD_OFF',
      size: 12,
      type: 'inputField',
    },
    {
      name: 'min1Yr',
      placeholder: 'Min 1Yr %',
      label: 'Min 1Yr %',
      allowDecimal: true,
      visible:
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'DAILY_REPORT' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'PRICE' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'BY_FUND_SYMBOL' ||
        code === 'BY_FUND_NAME' ||
        code === 'MOVING_AVERAGES' ||
        code === 'INCREASED' ||
        code === 'DECREASED' ||
        code === 'NO_CHANGE' ||
        code === 'BUILD_YOUR_OWN' ||
        code === 'NEWLY_BOUGHT' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'SOLD_OFF',
      size: 12,
      type: 'inputField',
    },
    {
      name: 'min5Yr',
      placeholder: 'Min 5Yr %',
      label: 'Min 5Yr %',
      allowDecimal: true,
      visible:
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'DAILY_REPORT' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'PRICE' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'MOVING_AVERAGES' ||
        code === 'INCREASED' ||
        code === 'DECREASED' ||
        code === 'NO_CHANGE' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'BY_FUND_SYMBOL' ||
        code === 'BY_FUND_NAME' ||
        code === 'BUILD_YOUR_OWN' ||
        code === 'NEWLY_BOUGHT' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'SOLD_OFF',
      size: 12,
      type: 'inputField',
    },
    {
      name: 'minPrice',
      placeholder: 'Min Price',
      label: 'Min Price',
      allowDecimal: true,
      visible:
        code === 'TRENDING_STOCK' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'DAILY_REPORT' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'MOVING_AVERAGES' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'BY_FUND_SYMBOL' ||
        code === 'PRICE' ||
        code === 'INSIDER_DAILY_REPORT' ||
        code === 'INSIDER_ALL_CUMULATIVE_REPORT' ||
        code === 'INCREASED' ||
        code === 'DECREASED' ||
        code === 'NO_CHANGE' ||
        code === 'NEWLY_BOUGHT' ||
        code === 'SOLD_OFF' ||
        code === 'BUILD_YOUR_OWN' ||
        code === 'TOP_GAINERS' ||
        code === 'DOW_HIGH_LEVEL_REPORT' ||
        code === 'DOW_DOWN_TREND_REPORT' ||
        code === 'BY_FUND_NAME' ||
        code === 'WEEKLY_YTD' ||
        code === 'CRITERIA_MET' ||
        code === 'EXIT_REPORT' ||
        code === 'THINKSABIO_DIAMONDS_PVCS' ||
        code === 'INSIDER_DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'minPrice',
    },
    {
      name: 'maxPrice',
      placeholder: 'Max Price',
      label: 'Max Price',
      allowDecimal: true,
      visible:
        code === 'TRENDING_STOCK' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'DAILY_REPORT' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'PRICE' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'THINKSABIO_DIAMONDS_PVCS' ||
        code === 'MOVING_AVERAGES' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'BY_FUND_SYMBOL' ||
        code === 'INSIDER_ALL_CUMULATIVE_REPORT' ||
        code === 'INCREASED' ||
        code === 'DECREASED' ||
        code === 'NO_CHANGE' ||
        code === 'NEWLY_BOUGHT' ||
        code === 'SOLD_OFF' ||
        code === 'BUILD_YOUR_OWN' ||
        code === 'TOP_GAINERS' ||
        code === 'DOW_HIGH_LEVEL_REPORT' ||
        code === 'DOW_DOWN_TREND_REPORT' ||
        code === 'BY_FUND_NAME' ||
        code === 'WEEKLY_YTD' ||
        code === 'CRITERIA_MET' ||
        code === 'EXIT_REPORT' ||
        code === 'INSIDER_DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'maxPrice',
    },
    {
      name: 'minEntryPrice',
      placeholder: 'Min Entry Price',
      label: 'Min Entry Price',
      allowDecimal: true,
      visible: code === 'TOP_GAINERS',
      size: 12,
      type: 'minEntryPrice',
    },
    {
      name: 'maxEntryPrice',
      placeholder: 'Max Entry Price',
      label: 'Max Entry Price',
      allowDecimal: true,
      visible: code === 'TOP_GAINERS',
      size: 12,
      type: 'maxEntryPrice',
    },
    {
      name: 'minInstCount',
      placeholder: 'Min Inst count',
      label: 'Min Inst count',
      allowDecimal: false,
      visible:
        code === 'TRENDING_STOCK' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'DAILY_REPORT' ||
        code === 'SYMBOL_SUMMARY' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'BY_APPROX_TXN_VAL' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'MOVING_AVERAGES' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        code === 'PRICE' ||
        // code === 'INSIDER_DAILY_REPORT' ||
        code === 'INCREASED' ||
        code === 'DECREASED' ||
        code === 'NO_CHANGE' ||
        code === 'NEWLY_BOUGHT' ||
        code === 'SOLD_OFF' ||
        code === 'INSIDER_SYMBOL_SUMMARY' ||
        code === 'DOW_HIGH_LEVEL_REPORT' ||
        code === 'DOW_DOWN_TREND_REPORT' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'BUILD_YOUR_OWN',

      size: 12,
      type: 'minInstCount',
    },
    {
      name: 'maxInstCount',
      placeholder: 'Max Inst count',
      label: 'Max Inst count',
      allowDecimal: false,
      visible:
        code === 'TRENDING_STOCK' ||
        code === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
        code === 'COMPARE_INSTITUTIONS' ||
        code === 'VERIFY_INSTITUTIONS_VS_SYMBOLS' ||
        code === 'PRICE' ||
        code === 'ALL_SYMBOLS' ||
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS' ||
        code === 'MOVING_AVERAGES' ||
        code === 'DOW_THEORY_UPTREND' ||
        code === 'DOW_THEORY_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_UPTREND' ||
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'ALL_INSTITUTIONS' ||
        code === 'TOP_10_HEDGE' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'BY_APPROX_TXN_VAL' ||
        // code === 'INSIDER_DAILY_REPORT' ||
        code === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        code === 'INCREASED' ||
        code === 'DECREASED' ||
        code === 'NO_CHANGE' ||
        code === 'NEWLY_BOUGHT' ||
        code === 'SOLD_OFF' ||
        code === 'DOW_HIGH_LEVEL_REPORT' ||
        code === 'DOW_DOWN_TREND_REPORT' ||
        // code === 'MARK_MINERVINI_STRATEGY' ||
        code === 'BUILD_YOUR_OWN',

      size: 12,
      type: 'maxInstCount',
    },

    {
      name: 'minPrevQInstCount',
      placeholder: 'Min Prev Q Inst count',
      label: 'Min Prev Q Inst count',
      allowDecimal: false,
      visible: code === 'DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'minPrevInstCount',
    },
    {
      name: 'maxPrevQInstCount',
      placeholder: 'Max Prev Q Inst count',
      label: 'Max Prev Q Inst count',
      allowDecimal: false,
      visible: code === 'DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'maxPrevInstCount',
    },
    {
      name: 'minTotalCount',
      placeholder: 'Min Total Count',
      label: 'Min Total Count',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'minTotalCount',
    },
    {
      name: 'maxTotalCount',
      placeholder: 'Max Total Count',
      label: 'Max Total Count',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'maxTotalCount',
    },
    {
      name: 'minPosReturnsStockCount',
      placeholder: 'Min +ve Returns Stock Count',
      label: 'Min +ve Returns Stock Count',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'minPosReturnsStockCount',
    },
    {
      name: 'maxPosReturnsStockCount',
      placeholder: 'Max +ve Returns Stock Count',
      label: 'Max +ve Returns Stock Count',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'maxPosReturnsStockCount',
    },
    {
      name: 'minNegReturnsStockCount',
      placeholder: 'Min -ve Returns Stock Count',
      label: 'Min -ve Returns Stock Count',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'minNegReturnsStockCount',
    },
    {
      name: 'maxNegReturnsStockCount',
      placeholder: 'Max -ve Returns Stock Count',
      label: 'Max -ve Returns Stock Count',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'maxNegReturnsStockCount',
    },
    {
      name: 'minNegReturnsPerc',
      placeholder: 'Min -ve Returns %',
      label: 'Min -ve Returns %',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'minNegReturnsPerc',
    },
    {
      name: 'maxNegReturnsPerc',
      placeholder: 'Max -ve Returns %',
      label: 'Max -ve Returns %',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'maxNegReturnsPerc',
    },
    {
      name: 'minPosReturnsPerc',
      placeholder: 'Min +ve Returns %',
      label: 'Min +ve Returns %',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'minPosReturnsPerc',
    },
    {
      name: 'maxPosReturnsPerc',
      placeholder: 'Max +ve Returns %',
      label: 'Max +ve Returns %',
      allowDecimal: false,
      visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
      size: 12,
      type: 'maxPosReturnsPerc',
    },

    {
      name: 'minAum',
      placeholder: 'Min AUM',
      label: 'Min AUM',
      allowDecimal: false,
      visible: code === 'DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'minAum',
    },
    {
      name: 'maxAum',
      placeholder: 'Max AUM',
      label: 'Max AUM',
      allowDecimal: false,
      visible: code === 'DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'maxAum',
    },
    // {
    //   name: 'minPrevAum',
    //   placeholder: 'Min Prev Qtr AUM',
    //   label: 'Min Prev Qtr AUM',
    //   allowDecimal: false,
    //   visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
    //   size: 12,
    //   type: 'minPrevAum',
    // },
    // {
    //   name: 'maxPrevAum',
    //   placeholder: 'Max Prev Qtr AUM',
    //   label: 'Max Prev Qtr AUM',
    //   allowDecimal: false,
    //   visible: code === 'INSTITUTION_PERFORMANCE_REPORT',
    //   size: 12,
    //   type: 'maxPrevAum',
    // },
    {
      name: 'hedgeCount',
      placeholder: 'Hedge Count',
      label: 'Hedge Count',
      allowDecimal: false,
      visible: code === 'DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'hedgeCount',
    },
    // {
    //   name: 'minMarketCap',
    //   placeholder: 'Min MarketCap',
    //   label: 'Min MarketCap',
    //   allowDecimal: false,
    //   visible: code === 'DETAIL_SUMMARY_REPORT',
    //   size: 12,
    //   type: 'minMarketCap',
    // },
    // {
    //   name: 'maxMarketCap',
    //   placeholder: 'Max MarketCap',
    //   label: 'Max MarketCap',
    //   allowDecimal: false,
    //   visible: code === 'DETAIL_SUMMARY_REPORT',
    //   size: 12,
    //   type: 'maxMarketCap',
    // },
    {
      name: 'minSec13dCount',
      placeholder: 'Min SEC 13D/G Count',
      label: 'Min SEC 13D/G Count',
      allowDecimal: false,
      visible:
        code === 'TRENDING_STOCK' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'BY_APPROX_TXN_VAL',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'minPerReturn',
      placeholder: 'Min % Return',
      label: 'Min % Return',
      allowDecimal: true,
      visible: code === 'TRENDING_STOCK' || code === 'PRICE_HISTORY',
      size: 12,
      type: 'inputFieldPositive',
    },

    {
      name: 'minSecInstCount',
      placeholder: 'Min SEC Inst Count',
      label: 'Min SEC Inst Count',
      allowDecimal: false,
      visible: code === 'SYMBOL_SUMMARY',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'minSecInsiderCount',
      placeholder: 'Min Sec Insider count',
      label: 'Min Sec Insider count',
      allowDecimal: false,
      visible: code === 'SYMBOL_SUMMARY' || code === 'INSIDER_SYMBOL_SUMMARY',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'minOneDayChange',
      placeholder: 'Min 1 Day change',
      label: 'Min 1 Day change',
      allowDecimal: true,
      visible: code === 'MOVING_AVERAGES',
      size: 12,
      type: 'minOneDayChange',
    },
    {
      name: 'maxOneDayChange',
      placeholder: 'Max 1 Day change',
      label: 'Max 1 Day change',
      allowDecimal: true,
      visible: code === 'MOVING_AVERAGES',
      size: 12,
      type: 'maxOneDayChange',
    },
    // {
    //   name: 'minGain',
    //   placeholder:
    //     code === 'INSTITUTION_PERFORMANCE_REPORT'
    //       ? 'Min AUM Change %'
    //       : 'Min % Gain',
    //   label:
    //     code === 'INSTITUTION_PERFORMANCE_REPORT'
    //       ? 'Min AUM Change %'
    //       : 'Min % Gain',
    //   allowDecimal: true,
    //   visible:
    //     code === 'DOW_THEORY_UPTREND' ||
    //     code === 'INSTITUTION_PERFORMANCE_REPORT',
    //   size: 12,
    //   type: 'minGain',
    // },
    // {
    //   name: 'maxGain',
    //   placeholder:
    //     code === 'INSTITUTION_PERFORMANCE_REPORT'
    //       ? 'Max AUM Change %'
    //       : 'Max % Gain',
    //   label:
    //     code === 'INSTITUTION_PERFORMANCE_REPORT'
    //       ? 'Max AUM Change %'
    //       : 'Max % Gain',
    //   allowDecimal: true,
    //   visible:
    //     code === 'DOW_THEORY_UPTREND' ||
    //     code === 'INSTITUTION_PERFORMANCE_REPORT',
    //   size: 12,
    //   type: 'maxGain',
    // },
    {
      name: 'minLoss',
      placeholder: 'Min % Loss',
      label: 'Min % Loss',
      allowDecimal: true,
      visible: code === 'DOW_THEORY_DOWNTREND',
      size: 12,
      type: 'minLoss',
    },
    {
      name: 'maxLoss',
      placeholder: 'Max % Loss',
      label: 'Max % Loss',
      allowDecimal: true,
      visible: code === 'DOW_THEORY_DOWNTREND',
      size: 12,
      type: 'maxLoss',
    },
    {
      name: 'minDayGain',
      placeholder: 'Min % Gain',
      label: 'Min % Gain',
      allowDecimal: true,
      visible: code === 'DOW_DAILY_PRICE_UPTREND',
      size: 12,
      type: 'minDayGain',
    },
    {
      name: 'maxDayGain',
      placeholder: 'Max % Gain',
      label: 'Max % Gain',
      allowDecimal: true,
      visible: code === 'DOW_DAILY_PRICE_UPTREND',
      size: 12,
      type: 'maxDayGain',
    },
    {
      name: 'minDayLoss',
      placeholder: 'Min % Loss',
      label: 'Min % Loss',
      allowDecimal: true,
      visible:
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_UPTREND',
      size: 12,
      type: 'minDayLoss',
    },
    {
      name: 'maxDayLoss',
      placeholder: 'Max % Loss',
      label: 'Max % Loss',
      allowDecimal: true,
      visible:
        code === 'DOW_DAILY_PRICE_DOWNTREND' ||
        code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND',
      size: 12,
      type: 'maxDayLoss',
    },
    {
      name: 'minVolGain',
      placeholder: 'Min % Gain',
      label: 'Min vol % Gain',
      allowDecimal: true,
      visible: code === 'DOW_DAILY_PRICE_VOLUME_UPTREND',
      size: 12,
      type: 'minVolGain',
    },
    {
      name: 'minVolLoss',
      placeholder: 'Min % Loss',
      label: 'Min Vol % Loss',
      allowDecimal: true,
      visible: code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND',
      size: 12,
      type: 'minVolLoss',
    },
    {
      name: 'maxVolLoss',
      placeholder: 'Max % Loss',
      label: 'Max Vol % Loss',
      allowDecimal: true,
      visible: code === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND',
      size: 12,
      type: 'maxVolLoss',
    },
    {
      name: 'maxVolGain',
      placeholder: 'Max % Gain',
      label: 'Max Vol % Gain',
      allowDecimal: true,
      visible: code === 'DOW_DAILY_PRICE_VOLUME_UPTREND',
      size: 12,
      type: 'maxVolGain',
    },
    {
      name: 'minVolDiff',
      placeholder: 'Min Vol Diff',
      label: 'Min Vol Diff',
      visible: code === 'TRENDING_STOCK',
      size: 12,
      type: 'minVolDiff',
    },
    {
      name: 'maxVolDiff',
      placeholder: 'Max Vol Diff',
      label: 'Max Vol Diff',
      visible: code === 'TRENDING_STOCK',
      size: 12,
      type: 'maxVolDiff',
    },
    {
      name: 'minInsiderTransactionValue',
      placeholder: 'Min Transaction value',
      label: 'Min Transaction value',
      allowDecimal: false,
      visible:
        code === 'INSIDER_DAILY_REPORT' ||
        code === 'INSIDER_ALL_CUMULATIVE_REPORT' ||
        code === 'INSIDER_DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'minInsiderTransactionValue',
    },
    {
      name: 'maxInsiderTransactionValue',
      placeholder: 'Max Transaction value',
      label: 'Max Transaction value',
      allowDecimal: false,
      visible:
        code === 'INSIDER_DAILY_REPORT' ||
        code === 'INSIDER_ALL_CUMULATIVE_REPORT' ||
        code === 'INSIDER_DETAIL_SUMMARY_REPORT',
      size: 12,
      type: 'maxInsiderTransactionValue',
    },
    {
      name: 'fromDate',
      placeholder: 'From',
      label: 'From Date',
      visible: code === 'TRENDING_STOCK',
      size: 12,
      type: 'date',
    },

    {
      name: 'range',
      label: 'Date Range',
      visible:
        code === 'SYMBOL_SUMMARY' ||
        code === 'DETAIL_SUMMARY_REPORT' ||
        code === 'INSIDER_SYMBOL_SUMMARY' ||
        code === 'INSIDER_DETAIL_SUMMARY_REPORT' ||
        code === 'SECTOR_TRENDING' ||
        code === 'TOP_GAINERS' ||
        code === 'INSIDER_ALL_CUMULATIVE_REPORT',
      size: 24,
      type: 'dateRange',
    },
    {
      name: 'queryDate',
      placeholder: 'Date',
      label: 'Date',
      visible: false /*code === 'MARK_MINERVINI_STRATEGY'*/,
      size: 12,
      type: 'queryDate',
    },
    {
      name: 'entryDateRange',
      label: 'Entry Date Range',
      visible: code === 'TOP_GAINERS',
      size: 24,
      type: 'entryDateRange',
    },
    {
      name: 'weeklyDateRange',
      label: 'Weekly Date Range',
      visible: code === 'WEEKLY_GAINS_REPORT' || code === 'PRICE_HISTORY',
      size: 24,
      type: 'weeklyDateRange',
    },
    {
      name: 'minTop10HedgeFundsIn_NC_NB_CNT',
      placeholder: 'Min Top-10 HF NB/INCR/NC',
      label: 'Min Top-10 HF NB/INCR/NC',
      visible:
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS',
      size: 12,
      type: 'minTop10HedgeFundsIn_NC_NB_CNT',
    },
    {
      name: 'maxTop10HedgeFundsIn_NC_NB_CNT',
      placeholder: 'Max Top-10 HF NB/INCR/NC',
      label: 'Max Top-10 HF NB/INCR/NC',
      visible:
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS',
      size: 12,
      type: 'maxTop10HedgeFundsIn_NC_NB_CNT',
    },
    {
      name: 'minTop10InstIn_NC_NB_CNT',
      placeholder: 'Min Top-10 Inst NB/INCR/NC',
      label: 'Min Top-10 Inst NB/INCR/NC',
      visible:
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS',
      size: 12,
      type: 'minTop10InstIn_NC_NB_CNT',
    },
    {
      name: 'maxTop10InstIn_NC_NB_CNT',
      placeholder: 'Max Top-10 Inst NB/INCR/NC',
      label: 'Max Top-10 Inst NB/INCR/NC',
      visible:
        code === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS' ||
        code === 'THINKSABIO_DIAMONDS',
      size: 12,
      type: 'maxTop10InstIn_NC_NB_CNT',
    },
    {
      name: 'minLatestWeekPer',
      placeholder: 'Min Latest week Per Change',
      label: 'Min Latest week Per Change',
      allowDecimal: true,
      visible: code === 'WEEKLY_YTD',
      size: 12,
      type: 'minLatestWeekPer',
    },
    {
      name: 'maxLatestWeekPer',
      placeholder: 'Max Latest week Per Change',
      label: 'Max Latest week Per Change',
      allowDecimal: true,
      visible: code === 'WEEKLY_YTD',
      size: 12,
      type: 'maxLatestWeekPer',
    },
  ];
  return fields.filter((field) => field.visible);
};

export const MARK_MINERVINI_STRATEGY_CRITERIA = () => {
  return (
    <div className={styles.featuresSection}>
      <h3 className={styles.titleFeature}>
        Mark Minervini strategy stocks criteria:
      </h3>
      <ul>
        <li>1. Current Stock price is above both 150 DMA and 200 DMA</li>
        <li>2. 150 DMA is above 200 DMA</li>
        <li>3. 200 DMA is trending at at least 1 month(22 days)</li>
        <li>4. 50 DMA is above both 150 DMA and 200 DMA</li>
        <li>5. Current stock price is trading above 50 DMA</li>
        <li>6. Current stock price is 25% above 52 Week Low</li>
        <li>7. Current stock Price is within 25% of 52 Week High</li>
        <li>8. RSI Ratings more than 70</li>
      </ul>
    </div>
  );
};
