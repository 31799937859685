/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const uuuReport = /* GraphQL */ `
  query UuuReport($request: ReportRequest) {
    uuuReport(request: $request) {
      columns {
        symbol
        name
        sector
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        changeStatus
        marketValue
        lastSale
        c52WkLow
        c52WkHigh
        priceDiff
        possGrowth
        ytd
        oneYrGrowth
        fiveYrGrowth
        iFund
      }
      data {
        symbol
        name
        sector
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        changeStatus
        marketValue
        lastSale
        c52WkLow
        c52WkHigh
        priceDiff
        possGrowth
        ytd
        oneYrGrowth
        fiveYrGrowth
        iFund
      }
      total
    }
  }
`;
export const otherReport = /* GraphQL */ `
  query OtherReport($request: ReportRequest) {
    otherReport(request: $request) {
      columns {
        symbol
        name
        sector
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        changeStatus
        marketValue
        lastSale
        c52WkLow
        c52WkHigh
        priceDiff
        possGrowth
        ytd
        oneYrGrowth
        fiveYrGrowth
        iFund
      }
      data {
        symbol
        name
        sector
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        changeStatus
        marketValue
        lastSale
        c52WkLow
        c52WkHigh
        priceDiff
        possGrowth
        ytd
        oneYrGrowth
        fiveYrGrowth
        iFund
      }
      total
    }
  }
`;
export const individualReport = /* GraphQL */ `
  query IndividualReport($request: ReportRequest) {
    individualReport(request: $request) {
      columns {
        rank
        symbol
        name
        sector
        c52WkHigh
        lastSale
        possGrowth
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        sharesDiff
        lastQtrSharesChange
        status
        specialStatus
        lastQtr1Avg
        marketValue
        lastQtr1ApproxInvestedValue
        ytd
        oneYrGrowth
        fiveYrGrowth
        allocationPerc
        lastQtr1HedgeCount
        lastQtr1Count
      }
      data {
        rank
        symbol
        name
        sector
        c52WkHigh
        lastSale
        possGrowth
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        sharesDiff
        lastQtrSharesChange
        status
        specialStatus
        lastQtr1Avg
        marketValue
        lastQtr1ApproxInvestedValue
        ytd
        oneYrGrowth
        fiveYrGrowth
        allocationPerc
        lastQtr1HedgeCount
        lastQtr1Count
      }
      total
    }
  }
`;
export const sectorReport = /* GraphQL */ `
  query SectorReport($request: SectorReportRequest) {
    sectorReport(request: $request) {
      columns {
        category
        sector
        lastQ8Allocation
        lastQ7Allocation
        lastQ6Allocation
        lastQ5Allocation
        lastQ4Allocation
        lastQ3Allocation
        lastQ2Allocation
        lastQ1Allocation
      }
      data {
        category
        sector
        lastQ8Allocation
        lastQ7Allocation
        lastQ6Allocation
        lastQ5Allocation
        lastQ4Allocation
        lastQ3Allocation
        lastQ2Allocation
        lastQ1Allocation
      }
      total
      sectorAllocation {
        sector
        allocation
        total
        allocationPercentage
        count
      }
    }
  }
`;
export const dividendsReport = /* GraphQL */ `
  query DividendsReport($request: DividendsReportRequest) {
    dividendsReport(request: $request) {
      columns {
        symbol
        name
        sector
        lastSale
        totalPaidLast1Yr
        totalDivPercLast1Yr
        lastQ1Dividend
        lastQ1DivPerc
        totalPaidLast2Yr
        totalDivPercLast2Yr
        lastQ2Dividend
        lastQ2DivPerc
        lastQ3Dividend
        lastQ3DivPerc
        lastQ4Dividend
        lastQ4DivPerc
        lastQ5Dividend
        lastQ5DivPerc
        totalPaidLast3Yr
        totalDivPercLast3Yr
        lastQ6Dividend
        lastQ6DivPerc
        lastQ7Dividend
        lastQ7DivPerc
        lastQ8Dividend
        lastQ8DivPerc
        consistentlyIncrLastNQtrs
        consistentlyIncrLast2Qtrs
      }
      data {
        symbol
        name
        sector
        lastSale
        totalPaidLast1Yr
        totalDivPercLast1Yr
        lastQ1Dividend
        lastQ1DivPerc
        totalPaidLast2Yr
        totalDivPercLast2Yr
        lastQ2Dividend
        lastQ2DivPerc
        lastQ3Dividend
        lastQ3DivPerc
        lastQ4Dividend
        lastQ4DivPerc
        lastQ5Dividend
        lastQ5DivPerc
        totalPaidLast3Yr
        totalDivPercLast3Yr
        lastQ6Dividend
        lastQ6DivPerc
        lastQ7Dividend
        lastQ7DivPerc
        lastQ8Dividend
        lastQ8DivPerc
        consistentlyIncrLastNQtrs
        consistentlyIncrLast2Qtrs
      }
      total
    }
  }
`;
export const dailyReport = /* GraphQL */ `
  query DailyReport($request: ReportRequest) {
    dailyReport(request: $request) {
      columns {
        symbol
        name
        sector
        industry
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        changeCountStatus
        sec13dCount
        c52WkHigh
        lastSale
        priceDiff
        possGrowth
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        changeStatus
        marketValue
        lastQtr1ApproxInvestedValue
        lastQtr1HedgeCount
        lastQtr1HedgeMarketValue
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        top10HedgeFundsIn_NC_NB_CNT
        top10InstIn_NC_NB_CNT
        iFund
      }
      data {
        symbol
        name
        sector
        industry
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        changeCountStatus
        sec13dCount
        c52WkHigh
        lastSale
        priceDiff
        possGrowth
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        changeStatus
        marketValue
        lastQtr1ApproxInvestedValue
        lastQtr1HedgeCount
        lastQtr1HedgeMarketValue
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        top10HedgeFundsIn_NC_NB_CNT
        top10InstIn_NC_NB_CNT
        iFund
      }
      total
      lastUpdatedTime
    }
  }
`;
export const underDollarReport = /* GraphQL */ `
  query UnderDollarReport($request: ReportRequest) {
    underDollarReport(request: $request) {
      columns {
        symbol
        name
        sector
        yearBeginPrice
        lastSale
        ytdPriceChange
        ytd
        lastQtr1Count
        lastQtr1Shares
        lastQtr1HedgeCount
        lastQtr1HedgeShares
        lastQtr1HedgeHeroShares
        lastQtr1HedgeHeroStatus
      }
      data {
        symbol
        name
        sector
        yearBeginPrice
        lastSale
        ytdPriceChange
        ytd
        lastQtr1Count
        lastQtr1Shares
        lastQtr1HedgeCount
        lastQtr1HedgeShares
        lastQtr1HedgeHeroShares
        lastQtr1HedgeHeroStatus
      }
      total
    }
  }
`;
export const recentSecDailyReport = /* GraphQL */ `
  query RecentSecDailyReport($request: ReportRequest) {
    recentSECDailyReport(request: $request) {
      columns {
        institutionName
        symbol
        secDate
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        sec13dCount
        lastSale
        prevShares
        currentShares
        sharesChange
        sharesChangePer
        status
        approxInvestedValue
        companyName
        sector
        ownership
        marketCap
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        lastQtr1HedgeCount
        lastQtr1HedgeShares
        lastQtr1HedgeMarketValue
        aum
        high
        low
        filedDate
        secForm
      }
      data {
        institutionName
        symbol
        secDate
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        sec13dCount
        lastSale
        prevShares
        currentShares
        sharesChange
        sharesChangePer
        status
        approxInvestedValue
        companyName
        sector
        ownership
        marketCap
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        lastQtr1HedgeCount
        lastQtr1HedgeShares
        lastQtr1HedgeMarketValue
        aum
        high
        low
        filedDate
        secForm
      }
      total
      lastUpdatedTime
    }
  }
`;
export const recentMutualFundsDailyReport = /* GraphQL */ `
  query RecentMutualFundsDailyReport($request: ReportRequest) {
    recentMutualFundsDailyReport(request: $request) {
      columns {
        symbol
        company_name
        sector
        last_sale
        c52WkHigh
        filer_name
        series_name
        shares
        shares_value
        percentage
        report_date
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        company_name
        sector
        last_sale
        c52WkHigh
        filer_name
        series_name
        shares
        shares_value
        percentage
        report_date
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
      lastUpdatedTime
    }
  }
`;
export const userReferrals = /* GraphQL */ `
  query UserReferrals($request: ReportRequest) {
    userReferrals(request: $request) {
      columns {
        referral_to_name
        referral_to_email
        created_at
        subscription_status
      }
      data {
        referral_to_name
        referral_to_email
        created_at
        subscription_status
      }
      total
      lastUpdatedTime
    }
  }
`;

export const recentByMutualFundReport = /* GraphQL */ `
  query RecentByMutualFundReport($request: ReportRequest) {
    recentByMutualFundReport(request: $request) {
      columns {
        symbol
        sector
        last_sale
        c52WkHigh
        report_date
        series_name
        company_name
        shares
        shares_value
        percentage
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        sector
        last_sale
        c52WkHigh
        report_date
        series_name
        company_name
        shares
        shares_value
        percentage
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
      lastUpdatedTime
    }
  }
`;

export const recentMutualFundsSymbolReport = /* GraphQL */ `
  query RecentMutualFundsSymbolReport($request: ReportRequest) {
    recentMutualFundsSymbolReport(request: $request) {
      columns {
        filer_name
        shares
        percentage
        series_name
      }
      data {
        filer_name
        shares
        percentage
        series_name
      }
      total
      lastUpdatedTime
    }
  }
`;

export const recentSecDetailReport = /* GraphQL */ `
  query RecentSecDetailReport($request: ReportRequest) {
    recentSECDetailReport(request: $request) {
      columns {
        institutionName
        symbol
        secDate
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        sec13dCount
        lastSale
        prevShares
        currentShares
        sharesChange
        sharesChangePer
        status
        approxInvestedValue
        ownership
        companyName
        sector
        marketCap
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        lastQtr1HedgeCount
        lastQtr1HedgeShares
        lastQtr1HedgeMarketValue
        aum
        high
        low
        filedDate
        secForm
      }
      data {
        institutionName
        symbol
        secDate
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        sec13dCount
        lastSale
        prevShares
        currentShares
        sharesChange
        sharesChangePer
        status
        approxInvestedValue
        ownership
        companyName
        sector
        marketCap
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        lastQtr1HedgeCount
        lastQtr1HedgeShares
        lastQtr1HedgeMarketValue
        aum
        high
        low
        filedDate
        secForm
      }
      total
      lastUpdatedTime
    }
  }
`;

export const recentSECByTrxValReport = /* GraphQL */ `
  query RecentSECByTrxValReport($request: ReportRequest) {
    recentSECByTrxValReport(request: $request) {
      columns {
        symbol
        companyName
        sector
        lastSale
        approxInvestedValue
        sec13dCount
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        marketCap
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
      }
      data {
        symbol
        companyName
        sector
        lastSale
        approxInvestedValue
        sec13dCount
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        marketCap
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
      }
      total
      lastUpdatedTime
    }
  }
`;

export const getTopTwentyFiveSymbolsReport = /* GraphQL */ `
  query GetTopTwentyFiveSymbolsReport($request: ReportRequest) {
    getTopTwentyFiveSymbolsReport(request: $request) {
      columns {
        newlyBought
        increased
        decreased
        noChange
        soldOff
      }
      data {
        newlyBought
        increased
        decreased
        noChange
        soldOff
      }
      total
      lastUpdatedTime
    }
  }
`;

export const recentSecYearlyDetailReport = /* GraphQL */ `
  query RecentSecYearlyDetailReport($request: ReportRequest) {
    recentSECYearlyDetailReport(request: $request) {
      columns {
        institutionName
        symbol
        secDate
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastSale
        prevShares
        currentShares
        sharesChange
        sharesChangePer
        status
        ownership
        companyName
        sector
        marketCap
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        lastQtr1HedgeCount
        lastQtr1HedgeShares
        lastQtr1HedgeMarketValue
        aum
        high
        low
        filedDate
        secForm
      }
      data {
        institutionName
        symbol
        secDate
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastSale
        prevShares
        currentShares
        sharesChange
        sharesChangePer
        status
        ownership
        companyName
        sector
        marketCap
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        lastQtr1HedgeCount
        lastQtr1HedgeShares
        lastQtr1HedgeMarketValue
        aum
        high
        low
        filedDate
        secForm
      }
      total
      lastUpdatedTime
    }
  }
`;
export const recentSecSymbolReport = /* GraphQL */ `
  query RecentSecSymbolReport($request: ReportRequest) {
    recentSECSymbolReport(request: $request) {
      columns {
        symbol
        sector
        secInstCount
        lastQtr1Count
        lastQtr1Shares
        lastQtr1HedgeCount
      }
      data {
        symbol
        sector
        secInstCount
        lastQtr1Count
        lastQtr1Shares
        lastQtr1HedgeCount
      }
      total
      lastUpdatedTime
    }
  }
`;
export const recentSecYearlySymbolReport = /* GraphQL */ `
  query RecentSecYearlySymbolReport($request: ReportRequest) {
    recentSECYearlySymbolReport(request: $request) {
      columns {
        symbol
        sector
        secInstCount
        lastQtr1Count
        lastQtr1HedgeCount
      }
      data {
        symbol
        sector
        secInstCount
        lastQtr1Count
        lastQtr1HedgeCount
      }
      total
      lastUpdatedTime
    }
  }
`;
export const movingAvgReport = /* GraphQL */ `
  query MovingAvgReport($request: ReportRequest) {
    movingAvgReport(request: $request) {
      columns {
        symbol
        name
        sector
        lastSale
        price1DayAgo
        price1DayChange
        price5DaysAgo
        price5DayChange
        price10DaysAgo
        price10DayChange
        price20DaysAgo
        price20DayChange
        price50DaysAgo
        price50DayChange
        price100DaysAgo
        price100DayChange
        price150DaysAgo
        price150DayChange
        price200DaysAgo
        price200DayChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr1Count
        lastQtr1Shares
        lastQtr1HedgeCount
        lastQtr1HedgeHeroShares
        lastQtr1HedgeHeroStatus
      }
      data {
        symbol
        name
        sector
        lastSale
        price1DayAgo
        price1DayChange
        price5DaysAgo
        price5DayChange
        price10DaysAgo
        price10DayChange
        price20DaysAgo
        price20DayChange
        price50DaysAgo
        price50DayChange
        price100DaysAgo
        price100DayChange
        price150DaysAgo
        price150DayChange
        price200DaysAgo
        price200DayChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        lastQtr1Count
        lastQtr1Shares
        lastQtr1HedgeCount
        lastQtr1HedgeHeroShares
        lastQtr1HedgeHeroStatus
      }
      total
    }
  }
`;
export const increasedDowTheoryReport = /* GraphQL */ `
  query IncreasedDowTheoryReport($request: ReportRequest) {
    increasedDowTheoryReport(request: $request) {
      columns {
        symbol
        name
        sector
        industry
        lastWkAvg26
        lastWkAvg25
        lastWkAvg24
        lastWkAvg23
        lastWkAvg22
        lastWkAvg21
        lastWkAvg20
        lastWkAvg19
        lastWkAvg18
        lastWkAvg17
        lastWkAvg16
        lastWkAvg15
        lastWkAvg14
        lastWkAvg13
        lastWkAvg12
        lastWkAvg11
        lastWkAvg10
        lastWkAvg9
        lastWkAvg8
        lastWkAvg7
        lastWkAvg6
        lastWkAvg5
        lastWkAvg4
        lastWkAvg3
        lastWkAvg2
        lastWkAvg1
        gainInWeeks
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        name
        sector
        industry
        lastWkAvg26
        lastWkAvg25
        lastWkAvg24
        lastWkAvg23
        lastWkAvg22
        lastWkAvg21
        lastWkAvg20
        lastWkAvg19
        lastWkAvg18
        lastWkAvg17
        lastWkAvg16
        lastWkAvg15
        lastWkAvg14
        lastWkAvg13
        lastWkAvg12
        lastWkAvg11
        lastWkAvg10
        lastWkAvg9
        lastWkAvg8
        lastWkAvg7
        lastWkAvg6
        lastWkAvg5
        lastWkAvg4
        lastWkAvg3
        lastWkAvg2
        lastWkAvg1
        gainInWeeks
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
    }
  }
`;
export const decreasedDowTheoryReport = /* GraphQL */ `
  query DecreasedDowTheoryReport($request: ReportRequest) {
    decreasedDowTheoryReport(request: $request) {
      columns {
        symbol
        name
        sector
        industry
        lastWkAvg26
        lastWkAvg25
        lastWkAvg24
        lastWkAvg23
        lastWkAvg22
        lastWkAvg21
        lastWkAvg20
        lastWkAvg19
        lastWkAvg18
        lastWkAvg17
        lastWkAvg16
        lastWkAvg15
        lastWkAvg14
        lastWkAvg13
        lastWkAvg12
        lastWkAvg11
        lastWkAvg10
        lastWkAvg9
        lastWkAvg8
        lastWkAvg7
        lastWkAvg6
        lastWkAvg5
        lastWkAvg4
        lastWkAvg3
        lastWkAvg2
        lastWkAvg1
        lossInWeeks
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        name
        sector
        industry
        lastWkAvg26
        lastWkAvg25
        lastWkAvg24
        lastWkAvg23
        lastWkAvg22
        lastWkAvg21
        lastWkAvg20
        lastWkAvg19
        lastWkAvg18
        lastWkAvg17
        lastWkAvg16
        lastWkAvg15
        lastWkAvg14
        lastWkAvg13
        lastWkAvg12
        lastWkAvg11
        lastWkAvg10
        lastWkAvg9
        lastWkAvg8
        lastWkAvg7
        lastWkAvg6
        lastWkAvg5
        lastWkAvg4
        lastWkAvg3
        lastWkAvg2
        lastWkAvg1
        lossInWeeks
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
    }
  }
`;
export const insidersReport = /* GraphQL */ `
  query InsidersReport($request: ReportRequest) {
    insidersReport(request: $request) {
      columns {
        symbol
        insiderName
        name
        sector
        insiderSecDate
        prevShares
        currShares
        sharesChange
        changePerc
        sec13dCount
        status
        acquired_or_disposed
        transactionPrice
        marketValue
        transactionValue
        insiderRole
        tenPercOwner
        security
        lastSale
        transactionDate
      }
      data {
        symbol
        insiderName
        name
        sector
        insiderSecDate
        prevShares
        currShares
        sharesChange
        changePerc
        sec13dCount
        status
        acquired_or_disposed
        transactionPrice
        marketValue
        transactionValue
        insiderRole
        tenPercOwner
        security
        lastSale
        transactionDate
      }
      total
      lastUpdatedTime
    }
  }
`;
export const insidersSymbolSummaryReport = /* GraphQL */ `
  query InsidersSymbolSummaryReport($request: ReportRequest) {
    insidersSymbolSummaryReport(request: $request) {
      columns {
        symbol
        sector
        secInsiderCount
        secInsiderBuyCount
        secInsiderSellCount
        lastQtr1Count
        lastQtr1HedgeCount
      }
      data {
        symbol
        sector
        secInsiderCount
        secInsiderBuyCount
        secInsiderSellCount
        lastQtr1Count
        lastQtr1HedgeCount
      }
      total
      lastUpdatedTime
    }
  }
`;
export const dowTheoryReport2 = /* GraphQL */ `
  query DowTheoryReport2($request: SearchRequest) {
    dowTheoryReport2(request: $request) {
      columns {
        key
        value
        type
      }
      data {
        key
        value
      }
      total
      lastUpdatedTime
      reportType
      sectorAllocation {
        sector
        allocation
        total
        allocationPercentage
        count
      }
      compareSectorAllocation {
        sector
        ins {
          name
          allocation
          total
          percentage
        }
      }
    }
  }
`;
export const insidersReport2 = /* GraphQL */ `
  query InsidersReport2($request: SearchRequest) {
    insidersReport2(request: $request) {
      columns {
        key
        value
        type
      }
      data {
        key
        value
      }
      total
      lastUpdatedTime
      reportType
      sectorAllocation {
        sector
        allocation
        total
        allocationPercentage
        count
      }
      compareSectorAllocation {
        sector
        ins {
          name
          allocation
          total
          percentage
        }
      }
    }
  }
`;
export const symbolDetail = /* GraphQL */ `
  query SymbolDetail($symbol: String) {
    symbolDetail(symbol: $symbol) {
      company
      latestPrice
      startDate
      quarterlyCounts {
        columns {
          symbol
          lastQtr4Count
          lastQtr3Count
          lastQtr2Count
          lastQtr1Count
          sec13dCount
        }
        data {
          symbol
          lastQtr4Count
          lastQtr3Count
          lastQtr2Count
          lastQtr1Count
          sec13dCount
        }
        total
      }
    }
  }
`;
export const searchSymbol = /* GraphQL */ `
  query SearchSymbol($symbol: String) {
    searchSymbol(symbol: $symbol) {
      latestStatus {
        columns {
          lastQtr2Count
          lastQtr1Count
          sec13dNewCount
          companyInstCountStatus
          outstandingShares
          mCap
        }
        data {
          lastQtr2Count
          lastQtr1Count
          sec13dNewCount
          companyInstCountStatus
          outstandingShares
          mCap
        }
        total
      }
      instCountsByStatus {
        columns {
          type
          increased
          newlyBought
          noChange
          reduced
          total
        }
        data {
          type
          increased
          newlyBought
          noChange
          reduced
          total
        }
        total
      }
      forecastedPrices {
        columns {
          symbol
          prevVolume
          currVolume
          volDiff
          lastSale
          forecastedClosingPrices
          conclusion
        }
        data {
          symbol
          prevVolume
          currVolume
          volDiff
          lastSale
          forecastedClosingPrices
          conclusion
        }
        total
        note
      }
      symbolAnalysis {
        line1
        line2
        line3
        line4
        recentSECAnalysis
      }
      dividendsInfo {
        columns {
          lastQtr8Dividend
          lastQtr7Dividend
          lastQtr6Dividend
          lastQtr5Dividend
          lastQtr4Dividend
          lastQtr3Dividend
          lastQtr2Dividend
          lastQtr1Dividend
        }
        data {
          lastQtr8Dividend
          lastQtr7Dividend
          lastQtr6Dividend
          lastQtr5Dividend
          lastQtr4Dividend
          lastQtr3Dividend
          lastQtr2Dividend
          lastQtr1Dividend
        }
        total
      }
      priceHistory {
        columns {
          symbol
          sector
          industry
          possGrowth
          c52WkHigh
          lastSale
          yearBeginPrice
          c52WkLow
          c5YrLow
          c5YrLowDate
          c5YrHigh
          c5YrHighDate
          ytd
          oneYrGrowth
          fiveYrGrowth
        }
        data {
          symbol
          sector
          industry
          possGrowth
          c52WkHigh
          lastSale
          yearBeginPrice
          c52WkLow
          c5YrLow
          c5YrLowDate
          c5YrHigh
          c5YrHighDate
          ytd
          oneYrGrowth
          fiveYrGrowth
        }
        total
        note
      }
      movingAvgs10Weeks {
        columns {
          type
          lastWk10
          lastWk9
          lastWk8
          lastWk7
          lastWk6
          lastWk5
          lastWk4
          lastWk3
          lastWk2
          lastWk1
        }
        data {
          type
          lastWk10
          lastWk9
          lastWk8
          lastWk7
          lastWk6
          lastWk5
          lastWk4
          lastWk3
          lastWk2
          lastWk1
        }
        total
      }
      movingAvgs10Months {
        columns {
          type
          lastMonth10
          lastMonth9
          lastMonth8
          lastMonth7
          lastMonth6
          lastMonth5
          lastMonth4
          lastMonth3
          lastMonth2
          lastMonth1
        }
        data {
          type
          lastMonth10
          lastMonth9
          lastMonth8
          lastMonth7
          lastMonth6
          lastMonth5
          lastMonth4
          lastMonth3
          lastMonth2
          lastMonth1
        }
        total
      }
      quarterlyData {
        columns {
          type
          lastQtr9
          lastQtr8
          lastQtr7
          lastQtr6
          lastQtr5
          lastQtr4
          lastQtr3
          lastQtr2
          lastQtr1
          status
        }
        data {
          type
          lastQtr9
          lastQtr8
          lastQtr7
          lastQtr6
          lastQtr5
          lastQtr4
          lastQtr3
          lastQtr2
          lastQtr1
          status
        }
        total
      }
      financialData {
        columns {
          type
          lastQtr9
          lastQtr8
          lastQtr7
          lastQtr6
          lastQtr5
          lastQtr4
          lastQtr3
          lastQtr2
          lastQtr1
        }
        data {
          type
          lastQtr9
          lastQtr8
          lastQtr7
          lastQtr6
          lastQtr5
          lastQtr4
          lastQtr3
          lastQtr2
          lastQtr1
        }
        total
      }
      mutualFundQuarterlyShares {
        columns {
          mfName
          reportDate
          lastQtr1Shares
        }
        data {
          mfName
          reportDate
          lastQtr1Shares
        }
        total
      }
      statusCounts {
        columns {
          status
          lastQtr1Count
          lastQtr1Shares
          marketValue
          approxInvestedValue
          soldOutCount
          soldOutShares
          soldOutValue
        }
        data {
          status
          lastQtr1Count
          lastQtr1Shares
          marketValue
          approxInvestedValue
          soldOutCount
          soldOutShares
          soldOutValue
        }
        total
      }
      top10InstShares {
        columns {
          institutionName
          inst_cik
          lastQtr8Shares
          lastQtr7Shares
          lastQtr6Shares
          lastQtr5Shares
          lastQtr4Shares
          lastQtr3Shares
          lastQtr2Shares
          lastQtr1Shares
          status
          outstandingSharesPer
          tmv
          allocation
          aum
        }
        data {
          institutionName
          inst_cik
          lastQtr8Shares
          lastQtr7Shares
          lastQtr6Shares
          lastQtr5Shares
          lastQtr4Shares
          lastQtr3Shares
          lastQtr2Shares
          lastQtr1Shares
          status
          outstandingSharesPer
          tmv
          allocation
          aum
        }
        total
      }
      instShares {
        columns {
          institutionName
          inst_cik
          lastQtr8Shares
          lastQtr7Shares
          lastQtr6Shares
          lastQtr5Shares
          lastQtr4Shares
          lastQtr3Shares
          lastQtr2Shares
          lastQtr1Shares
          rishiPrevShares
          rishiCurrShares
          dateReported
          status
          outstandingSharesPer
          tmv
          allocation
          aum
        }
        data {
          institutionName
          inst_cik
          lastQtr8Shares
          lastQtr7Shares
          lastQtr6Shares
          lastQtr5Shares
          lastQtr4Shares
          lastQtr3Shares
          lastQtr2Shares
          lastQtr1Shares
          rishiPrevShares
          rishiCurrShares
          dateReported
          status
          outstandingSharesPer
          tmv
          allocation
          aum
        }
        total
      }
      recentSECShares {
        columns {
          institutionName
          inst_cik
          dateReported
          rishiPrevShares
          rishiCurrShares
          ownership
          approxInvestedValue
          status
          secForm
        }
        data {
          institutionName
          inst_cik
          dateReported
          rishiPrevShares
          rishiCurrShares
          ownership
          approxInvestedValue
          status
          secForm
        }
        total
      }
      insiderTransactions {
        columns {
          insiderName
          insiderRole
          insiderSecDate
          prevShares
          currShares
          sharesChange
          changePerc
          status
          acquired_or_disposed
          transactionPrice
          marketValue
          transactionValue
          tenPercOwner
          security
          transactionDate
        }
        data {
          insiderName
          insiderRole
          insiderSecDate
          prevShares
          currShares
          sharesChange
          changePerc
          status
          acquired_or_disposed
          transactionPrice
          marketValue
          transactionValue
          tenPercOwner
          security
          transactionDate
        }
        total
      }
      company
      companyProfile
      tickerNews {
        title
        url
        timestamp
        source
      }
      dailyPrices {
        columns {
          type
          lastDay10
          lastDay9
          lastDay8
          lastDay7
          lastDay6
          lastDay5
          lastDay4
          lastDay3
          lastDay2
          lastDay1
        }
        data {
          type
          lastDay10
          lastDay9
          lastDay8
          lastDay7
          lastDay6
          lastDay5
          lastDay4
          lastDay3
          lastDay2
          lastDay1
        }
        total
      }
      ownersInfo {
        columns {
          ownerName
          transactionDate
        }
        data {
          ownerName
          transactionDate
        }
        total
        source
      }
    }
  }
`;
export const searchSymbolCompare = /* GraphQL */ `
  query SearchSymbolCompare($symbols: [String]) {
    searchSymbolCompare(symbols: $symbols) {
      allQuarterlyData {
        columns {
          symbol
          name
          sector
          sec13dCount
          lastQtr8Avg
          lastQtr7Avg
          lastQtr6Avg
          lastQtr5Avg
          lastQtr4Avg
          lastQtr3Avg
          lastQtr2Avg
          lastQtr1Avg
          pctgain
          lastQtr8Count
          lastQtr7Count
          lastQtr6Count
          lastQtr5Count
          lastQtr4Count
          lastQtr3Count
          lastQtr2Count
          lastQtr1Count
          changeCountStatus
          lastQtr8HedgeCount
          lastQtr7HedgeCount
          lastQtr6HedgeCount
          lastQtr5HedgeCount
          lastQtr4HedgeCount
          lastQtr3HedgeCount
          lastQtr2HedgeCount
          lastQtr1HedgeCount
          changeHedgeCountStatus
          lastSale
          yearBeginPrice
          ytdPriceChange
          ytd
          oneYrGrowth
          fiveYrGrowth
          c52WkLow
          c52WkHigh
          priceDiff
          possGrowth
          lastQtr8Shares
          lastQtr7Shares
          lastQtr6Shares
          lastQtr5Shares
          lastQtr4Shares
          lastQtr3Shares
          lastQtr2Shares
          lastQtr1Shares
          changeSharesStatus
          lastQtr8HedgeShares
          lastQtr7HedgeShares
          lastQtr6HedgeShares
          lastQtr5HedgeShares
          lastQtr4HedgeShares
          lastQtr3HedgeShares
          lastQtr2HedgeShares
          lastQtr1HedgeShares
          changeHedgeSharesStatus
        }
        data {
          symbol
          name
          sector
          lastQtr8Count
          lastQtr7Count
          lastQtr6Count
          lastQtr5Count
          lastQtr4Count
          lastQtr3Count
          lastQtr2Count
          lastQtr1Count
          changeCountStatus
          lastQtr8HedgeCount
          lastQtr7HedgeCount
          lastQtr6HedgeCount
          lastQtr5HedgeCount
          lastQtr4HedgeCount
          lastQtr3HedgeCount
          lastQtr2HedgeCount
          lastQtr1HedgeCount
          changeHedgeCountStatus
          lastSale
          yearBeginPrice
          ytdPriceChange
          ytd
          oneYrGrowth
          fiveYrGrowth
          c52WkLow
          c52WkHigh
          priceDiff
          possGrowth
          lastQtr8Shares
          lastQtr7Shares
          lastQtr6Shares
          lastQtr5Shares
          lastQtr4Shares
          lastQtr3Shares
          lastQtr2Shares
          lastQtr1Shares
          lastQtr8Avg
          lastQtr7Avg
          lastQtr6Avg
          lastQtr5Avg
          lastQtr4Avg
          lastQtr3Avg
          lastQtr2Avg
          lastQtr1Avg
          pctgain
          sec13dCount
          changeSharesStatus
          lastQtr8HedgeShares
          lastQtr7HedgeShares
          lastQtr6HedgeShares
          lastQtr5HedgeShares
          lastQtr4HedgeShares
          lastQtr3HedgeShares
          lastQtr2HedgeShares
          lastQtr1HedgeShares
          changeHedgeSharesStatus
        }
        total
      }
      qtlyIndividualHedgeShrs {
        columns {
          institutionName
          lastQtr8Shares
          lastQtr7Shares
          lastQtr6Shares
          lastQtr5Shares
          lastQtr4Shares
          lastQtr3Shares
          lastQtr2Shares
          lastQtr1Shares
          changeSharesStatus
          marketValue
        }
        data {
          institutionName
          lastQtr8Shares
          lastQtr7Shares
          lastQtr6Shares
          lastQtr5Shares
          lastQtr4Shares
          lastQtr3Shares
          lastQtr2Shares
          lastQtr1Shares
          changeSharesStatus
          marketValue
        }
        total
        name
        symbol
      }
    }
  }
`;
export const searchOptionBySymbols = /* GraphQL */ `
  query SearchOptionBySymbols($symbols: [String]) {
    searchOptionBySymbols(symbols: $symbols) {
      top10Ins {
        columns {
          institutionName
          totalOptionContracts
          lastQtr1CallContracts
          lastQtr1TotalCallInstRatio
          lastQtr1PutContracts
          lastQtr1TotalPutInstRatio
        }
        data {
          institutionName
          totalOptionContracts
          lastQtr1CallContracts
          lastQtr1TotalCallInstRatio
          lastQtr1PutContracts
          lastQtr1TotalPutInstRatio
        }
      }
      countsVolume {
        columns {
          symbol
          totalOptionInst
          lastQtr1TotalCallInst
          lastQtr1CallInstRatio
          lastQtr1TotalPutInst
          lastQtr1PutInstRatio
          totalOptionsContracts
          lastQtr1InstCallContracts
          lastQtr1InstCallContractsRatio
          lastQtr1InstPutContracts
          lastQtr1InstPutContractsRatio
        }
        data {
          symbol
          totalOptionInst
          lastQtr1TotalCallInst
          lastQtr1CallInstRatio
          lastQtr1TotalPutInst
          lastQtr1PutInstRatio
          totalOptionsContracts
          lastQtr1InstCallContracts
          lastQtr1InstCallContractsRatio
          lastQtr1InstPutContracts
          lastQtr1InstPutContractsRatio
        }
        total
        name
        symbol
      }
    }
  }
`;
export const clearCache = /* GraphQL */ `
  query ClearCache {
    clearCache {
      status
    }
  }
`;
export const savedReportName = /* GraphQL */ `
  query SavedReportName($searchCriteria: String) {
    savedReportName(searchCriteria: $searchCriteria) {
      reportName
    }
  }
`;
export const searchInstitution = /* GraphQL */ `
  query SearchInstitution($request: ReportRequest) {
    searchInstitution(request: $request) {
      columns {
        symbol
        name
        sector
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        changeStatus
        marketValue
        lastSale
        c52WkLow
        c52WkHigh
        priceDiff
        possGrowth
        ytd
        oneYrGrowth
        fiveYrGrowth
        iFund
      }
      data {
        symbol
        name
        sector
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        changeStatus
        marketValue
        lastSale
        c52WkLow
        c52WkHigh
        priceDiff
        possGrowth
        ytd
        oneYrGrowth
        fiveYrGrowth
        iFund
      }
      total
    }
  }
`;
export const trendingStocksReport = /* GraphQL */ `
  query TrendingStocksReport($request: ReportRequest) {
    trendingStocksReport(request: $request) {
      columns {
        symbol
        name
        sector
        mCap
        yc_price
        lastSale
        priceDiff
        possGrowth
        lastQtr2Count
        lastQtr1Count
        ts_status
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        lastQtr1HedgeCount
        Avg200DayVol
        Avg90DayVol
        tsPrevVolume
        currVolume
        volDiff
        industry
      }
      data {
        symbol
        name
        sector
        mCap
        yc_price
        lastSale
        priceDiff
        possGrowth
        lastQtr2Count
        lastQtr1Count
        ts_status
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        lastQtr1HedgeCount
        Avg200DayVol
        Avg90DayVol
        tsPrevVolume
        currVolume
        volDiff
        industry
      }
      total
      lastUpdatedTime
    }
  }
`;
export const trendingStocksActivesReport = /* GraphQL */ `
  query TrendingStocksActivesReport($request: ReportRequest) {
    trendingStocksActivesReport(request: $request) {
      columns {
        symbol
        name
        sector
        Avg200DayVol
        Avg90DayVol
        tsPrevVolume
        currVolume
        volDiff
        yc_price
        lastSale
        priceDiff
        possGrowth
        lastQtr2Count
        lastQtr1Count
        ts_status
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        lastQtr1HedgeCount
        mCap
        industry
      }
      data {
        symbol
        name
        sector
        Avg200DayVol
        Avg90DayVol
        tsPrevVolume
        currVolume
        volDiff
        yc_price
        lastSale
        priceDiff
        possGrowth
        lastQtr2Count
        lastQtr1Count
        ts_status
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        lastQtr1HedgeCount
        mCap
        industry
      }
      total
      lastUpdatedTime
    }
  }
`;
export const search = /* GraphQL */ `
  query Search($request: SearchRequest) {
    search(request: $request) {
      columns {
        key
        value
        type
      }
      data {
        key
        value
      }
      total
      lastUpdatedTime
      reportType
      sectorAllocation {
        sector
        allocation
        total
        allocationPercentage
        count
      }
      cnstDays
      compareSectorAllocation {
        sector
        ins {
          name
          allocation
          total
          percentage
        }
      }
    }
  }
`;
export const quartersDropdown = /* GraphQL */ `
  query QuartersDropdown {
    quartersDropdown {
      name
      value
    }
  }
`;
export const iirQuartersDropdown = /* GraphQL */ `
  query IirQuartersDropdown($request: QuartersInput) {
    iirQuartersDropdown(request: $request) {
      name
      value
    }
  }
`;
export const sectorsDropdown = /* GraphQL */ `
  query SectorsDropdown {
    sectorsDropdown {
      name
      value
    }
  }
`;
export const heroDropdown = /* GraphQL */ `
  query HeroDropdown {
    heroDropdown {
      name
      value
    }
  }
`;
export const countryDropdown = /* GraphQL */ `
  query countryDropdown {
    countryDropdown {
      name
      value
    }
  }
`;

export const insiderTitlesDropdown = /* GraphQL */ `
  query insiderTitlesDropdown {
    insiderTitlesDropdown {
      name
      value
    }
  }
`;

export const fundNamesDropdown = /* GraphQL */ `
  query FundNamesDropdown($keyword: String) {
    fundNamesDropdown(keyword: $keyword) {
      name
      value
      __typename
    }
  }
`;
export const seriesNamesDropdown = /* GraphQL */ `
  query SeriesNamesDropdown($keyword: String) {
    seriesNamesDropdown(keyword: $keyword) {
      name
      value
    }
  }
`;

export const mutualFundSymbolsDropdown = /* GraphQL */ `
  query MutualFundSymbolsDropdown($keyword: String) {
    mutualFundSymbolsDropdown(keyword: $keyword) {
      name
      value
    }
  }
`;

export const mutualFundFundSymbolsDropdown = /* GraphQL */ `
  query mutualFundFundSymbolsDropdown {
    mutualFundFundSymbolsDropdown {
      name
      value
    }
  }
`;

export const industriesDropdown = /* GraphQL */ `
  query IndustriesDropdown {
    industriesDropdown {
      name
      value
    }
  }
`;
export const noOfWeeksDropdown = /* GraphQL */ `
  query NoOfWeeksDropdown {
    noOfWeeksDropdown {
      Increased {
        name
        value
      }
      Decreased {
        name
        value
      }
    }
  }
`;
export const getSymbolsBySector = /* GraphQL */ `
  query GetSymbolsBySector($request: SymbolsBySectorReportRequest) {
    getSymbolsBySector(request: $request) {
      columns {
        symbol
        yc_price
        lastSale
        priceDiff
        changePerc
        lastQtr2Shares
        lastQtr1Shares
        sec13dCount
      }
      data {
        symbol
        yc_price
        lastSale
        priceDiff
        changePerc
        lastQtr2Shares
        lastQtr1Shares
        sec13dCount
      }
      total
    }
  }
`;

export const getSymbolWeeklyGainsReport = /* GraphQL */ `
  query GetSymbolWeeklyGainsReport($request: ReportRequest) {
    getSymbolWeeklyGainsReport(request: $request) {
      columns {
        week_dtls
        start_date
        end_date
        start_price
        end_price
        changePerc
      }
      data {
        week_dtls
        start_date
        end_date
        start_price
        end_price
        changePerc
      }
      total
    }
  }
`;

export const getWeeklyGainsReport = /* GraphQL */ `
  query GetWeeklyGainsReport($request: ReportRequest) {
    getWeeklyGainsReport(request: $request) {
      columns {
        symbol
        last_sale
        no_of_positive_weeks
        no_of_negative_weeks
      }
      data {
        symbol
        last_sale
        no_of_positive_weeks
        no_of_negative_weeks
      }
      total
    }
  }
`;

export const pricesData = /* GraphQL */ `
  query PricesData($request: PricesDataRequest) {
    pricesData(request: $request) {
      data {
        fromPrice
        toPrice
      }
    }
  }
`;

export const getPopularCompaniesReport = /* GraphQL */ `
  query GetPopularCompaniesReport($request: PopularCompanyListRequest) {
    getPopularCompaniesReport(request: $request) {
      columns {
        symbol
        name
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        lastSale
        marketValue
      }
      data {
        symbol
        name
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        lastSale
        marketValue
      }
      total
    }
  }
`;
export const getStudentPerformanceStateReports = /* GraphQL */ `
  query GetStudentPerformanceStateReports(
    $request: StudentPerformanceReportRequest
  ) {
    getStudentPerformanceStateReports(request: $request) {
      columns {
        student_performance_rank
        school_state
        invested_value
        current_value
        percentage_returns
        student_count
      }
      data {
        student_performance_rank
        school_state
        invested_value
        current_value
        percentage_returns
        student_count
      }
      total
    }
  }
`;
export const getStudentPerformanceCountyReports = /* GraphQL */ `
  query GetStudentPerformanceCountyReports(
    $request: StudentPerformanceReportRequest
  ) {
    getStudentPerformanceCountyReports(request: $request) {
      columns {
        student_performance_rank
        school_county
        invested_value
        current_value
        percentage_returns
        student_count
      }
      data {
        student_performance_rank
        school_county
        invested_value
        current_value
        percentage_returns
        student_count
      }
      total
    }
  }
`;
export const getStudentPerformanceSchoolReports = /* GraphQL */ `
  query GetStudentPerformanceSchoolReports(
    $request: StudentPerformanceReportRequest
  ) {
    getStudentPerformanceSchoolReports(request: $request) {
      columns {
        student_performance_rank
        school_name
        invested_value
        current_value
        percentage_returns
        student_count
      }
      data {
        student_performance_rank
        school_name
        invested_value
        current_value
        percentage_returns
        student_count
      }
      total
    }
  }
`;
export const getStudentPerformanceGradeReports = /* GraphQL */ `
  query GetStudentPerformanceGradeReports(
    $request: StudentPerformanceReportRequest
  ) {
    getStudentPerformanceGradeReports(request: $request) {
      columns {
        student_performance_rank
        grade
        school_county
        school_name
        invested_value
        current_value
        percentage_returns
        student_count
      }
      data {
        student_performance_rank
        grade
        school_county
        school_name
        invested_value
        current_value
        percentage_returns
        student_count
      }
      total
    }
  }
`;
export const getStudentPerformanceStudentReports = /* GraphQL */ `
  query GetStudentPerformanceStudentReports(
    $request: StudentPerformanceReportRequest
  ) {
    getStudentPerformanceStudentReports(request: $request) {
      columns {
        student_performance_rank
        student_name
        invested_value
        invested_amount_gain
        invested_perc_returns
        balance
        current_capital
        overall_perc_returns
        grade
        school_name
        school_county
        school_state
      }
      data {
        student_performance_rank
        student_name
        email
        invested_value
        invested_amount_gain
        invested_perc_returns
        balance
        current_capital
        overall_perc_returns
        grade
        school_name
        school_county
        school_state
      }
      total
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser {
    getUser {
      first_name
      last_name
      email
      message
      type
      additionalInfo {
        id
        mobile
        gender
        marital_status
        country
        profession
        birth_year
        qualification
        race
        image_url
        telegram_id
      }
      studentInfo {
        id
        school_state
        school_name
        grade
        graduation_date
        birth_year
        student_id
        student_picture_id
        school_county
        school_email
      }
      alerts {
        all_symbols
        all_fav_symbols
        top_10_hedge
        all_fav_institutions
      }
      favCompanies {
        id
        company {
          cik
          industry
          ipo_year
          market_cap
          name
          sec_company_name
          sector
          symbol
        }
      }
      favInstitutions {
        id
        institution {
          cik
          id
          name
          nasuid
          rank
        }
      }
      financialInfo {
        expected_return_range
        experiences
        id
        income_range
        investment_exp
        investment_range
        lost_money
        has_portfolio
      }
      portfolio {
        avg_cost
        id
        no_of_shares
        company {
          cik
          industry
          ipo_year
          market_cap
          name
          sec_company_name
          sector
          symbol
        }
      }
      recentSearches {
        id
        search_criteria
        title
        displayText
        created_at
      }
      savedSearches {
        id
        search_criteria
        title
        displayText
        name
        created_at
      }
      stripeCustomer {
        stripe_customer_id
        stripe_subscription_id
        stripe_price_id
        stripe_payment_method_id
        interval
        subscription_status
        is_cancelled
        current_period_end
      }
      userBillingAddress {
        id
        address
        city
        state
        country
        zip
      }
      wallet {
        type
        name
        remainingBalance
        investedValue
        currentValue
        yd_value
      }
    }
  }
`;
export const getUserPaymentCard = /* GraphQL */ `
  query GetUserPaymentCard {
    getUserPaymentCard {
      name
      address
      city
      state
      country
      last4
      expiry_month
      expiry_year
    }
  }
`;
export const getUserWallet = /* GraphQL */ `
  query GetUserWallet($request: WalletRequest) {
    getUserWallet(request: $request) {
      type
      name
      remainingBalance
      investedValue
      currentValue
      yd_value
    }
  }
`;
export const userPortfolioList = /* GraphQL */ `
  query UserPortfolioList {
    userPortfolioList {
      name
      id
      type
    }
  }
`;
export const stockPrice = /* GraphQL */ `
  query StockPrice($request: StockPriceRequest) {
    stockPrice(request: $request) {
      status
      price
    }
  }
`;
export const addPortfolio = /* GraphQL */ `
  query AddPortfolio($request: AddPortfolioRequest) {
    addPortfolio(request: $request) {
      name
    }
  }
`;
export const resetPortfolio = /* GraphQL */ `
  query ResetPortfolio($request: ResetPortfolioRequest) {
    resetPortfolio(request: $request) {
      message
    }
  }
`;
export const deletePortfolio = /* GraphQL */ `
  query DeletePortfolio($request: DeletePortfolioRequest) {
    deletePortfolio(request: $request) {
      message
    }
  }
`;
export const portfolioSnapshot = /* GraphQL */ `
  query PortfolioSnapshot($request: PortfolioSnapshotRequest) {
    portfolioSnapshot(request: $request) {
      symbol {
        symbol
        currentValue
        percentage
      }
      sector {
        sector
        allocation
      }
    }
  }
`;
export const userPortfolio = /* GraphQL */ `
  query UserPortfolio($request: UserPortfolioRequest) {
    userPortfolio(request: $request) {
      columns {
        dateAdded
        symbol
        id
        no_of_shares
        portfolioName
        lastSale
        dollarChange
        equity
        avg_cost
        totalCost
        allocationPerc
        todayReturns
        todayReturnsPerc
        totalReturns
        totalReturnsPerc
        name
        note
      }
      data {
        dateAdded
        symbol
        id
        no_of_shares
        portfolioName
        lastSale
        dollarChange
        equity
        avg_cost
        totalCost
        allocationPerc
        totalReturns
        totalReturnsPerc
        todayReturns
        todayReturnsPerc
        name
        note
      }
      total
    }
  }
`;
export const movePortfolioItem = /* GraphQL */ `
  query MovePortfolioItem($request: [MovePortfolioItemRequest]) {
    movePortfolioItem(request: $request) {
      message
    }
  }
`;
export const transactionHistory = /* GraphQL */ `
  query TransactionHistory($request: TransactionHistoryFilter) {
    transactionHistory(request: $request) {
      columns {
        symbol
        name
        type
        quantity
        last_sale
        price
        transactionTime
      }
      data {
        symbol
        name
        type
        quantity
        last_sale
        price
        transactionTime
      }
    }
  }
`;
export const updatePortfolioStockNote = /* GraphQL */ `
  query UpdatePortfolioStockNote($request: UpdatePortfolioNoteRequest) {
    updatePortfolioStockNote(request: $request) {
      message
    }
  }
`;

export const updateTsWatchlistNote = /* GraphQL */ `
  mutation UpdateTsWatchlistNote($request: updateTsWatchlistNoteRequest) {
    updateTsWatchlistNote(request: $request) {
      noteAdded
    }
  }
`;
export const userPortfolioMovement = /* GraphQL */ `
  query UserPortfolioMovement($request: UserPortfolioMovementRequest) {
    userPortfolioMovement(request: $request) {
      time
      value
      market
    }
  }
`;
export const portfolioHealthCheckByInst = /* GraphQL */ `
  query PortfolioHealthCheckByInst(
    $request: PortfolioHealthCheckByInstRequest
  ) {
    portfolioHealthCheckByInst(request: $request) {
      columns {
        symbol
        conclusion
        sec13dCount
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        changeCountStatus
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        changeShareStatus
        lastSale
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        name
      }
      data {
        symbol
        conclusion
        sec13dCount
        lastQtr12Count
        lastQtr11Count
        lastQtr10Count
        lastQtr9Count
        lastQtr8Count
        lastQtr7Count
        lastQtr6Count
        lastQtr5Count
        lastQtr4Count
        lastQtr3Count
        lastQtr2Count
        lastQtr1Count
        changeCountStatus
        lastQtr12Shares
        lastQtr11Shares
        lastQtr10Shares
        lastQtr9Shares
        lastQtr8Shares
        lastQtr7Shares
        lastQtr6Shares
        lastQtr5Shares
        lastQtr4Shares
        lastQtr3Shares
        lastQtr2Shares
        lastQtr1Shares
        changeShareStatus
        lastSale
        yearBeginPrice
        ytdPriceChange
        ytd
        oneYrGrowth
        fiveYrGrowth
        name
      }
      total
    }
  }
`;
export const tsWatchList = /* GraphQL */ `
  query TSWatchList($request: TSWatchListRequest) {
    tsWatchList(request: $request) {
      columns {
        symbol
        dateAdded
        priceOnDateAdded
        lastSale
        todayReturns
        todayReturnsPerc
        totalReturns
        totalReturnsPerc
        lastQtr2Count
        lastQtr1Count
        changeCountsStatus
        lastQtr2Shares
        lastQtr1Shares
        changeShareStatus
        sec13dCount
        note
      }
      data {
        symbol
        dateAdded
        priceOnDateAdded
        lastSale
        todayReturns
        todayReturnsPerc
        totalReturns
        totalReturnsPerc
        lastQtr2Count
        lastQtr1Count
        changeCountsStatus
        lastQtr2Shares
        lastQtr1Shares
        changeShareStatus
        sec13dCount
        note
      }
      total
    }
  }
`;
export const portfolioHealthCheckBySector = /* GraphQL */ `
  query PortfolioHealthCheckBySector(
    $request: PortfolioHealthCheckBySectorRequest
  ) {
    portfolioHealthCheckBySector(request: $request) {
      columns {
        sector
        symbols
        allocation
        allInstAlloc
        topHedgeAlloc
      }
      data {
        sector
        symbols
        allocation
        allInstAlloc
        topHedgeAlloc
      }
      total
    }
  }
`;
export const portfolioHealthCheckByFavInst = /* GraphQL */ `
  query PortfolioHealthCheckByFavInst(
    $request: PortfolioHealthCheckByFavInstRequest
  ) {
    portfolioHealthCheckByFavInst(request: $request) {
      columns {
        key
        value
        type
      }
      data {
        key
        value
      }
      total
    }
  }
`;
export const getUploadAttachmentSignedUrl = /* GraphQL */ `
  query GetUploadAttachmentSignedUrl(
    $request: GetUploadAttachmentSignedUrlRequest
  ) {
    getUploadAttachmentSignedUrl(request: $request) {
      url
      expires
      uploadUrl
      accessUrl
    }
  }
`;
export const ourpicks = /* GraphQL */ `
  query Ourpicks($request: OurPicksRequest) {
    ourpicks(request: $request) {
      symbol
      name
      sector
      ytd
      oneYrGrowth
      fiveYrGrowth
      priceOnRecDate
      highestPriceSinceReco
      highestGrowthSinceRecommended
      lastSale
      priceDiff
      growthSinceRecommended
      date
      highestPriceDate
      id
      recoType
      type
      rank
      total
      reason
      price_on_entry
      lastUpdatedTime
      event_date
      upcoming_catalyst
      live_cash
      ts_conviction
      mCap
      c52WkHigh
      c52WkLow
      sec13dCount
      lastQtr1Count
      lastQtr2Count
      lastQtr1Shares
      lastQtr2Shares
      changeShareStatus
      changeCountsStatus
    }
  }
`;
export const ourpicksDetail = /* GraphQL */ `
  query OurpicksDetail($request: OurPicksDetailRequest) {
    ourpicksDetail(request: $request) {
      displayNames {
        symbol
        name
        description
        ytd
        oneYrGrowth
        fiveYrGrowth
        reason
        growthSinceRecommended
        highestGrowthSinceRecommended
        date
        lastQtr1Count
        lastQtr2Count
        lastQtr3Count
        lastQtr4Count
        lastQtr5Count
        lastQtr6Count
        lastQtr7Count
        lastQtr8Count
        lastQtr1Shares
        lastQtr2Shares
        lastQtr3Shares
        lastQtr4Shares
        lastQtr5Shares
        lastQtr6Shares
        lastQtr7Shares
        lastQtr8Shares
      }
      data {
        symbol
        name
        description
        ytd
        oneYrGrowth
        fiveYrGrowth
        reason
        growthSinceRecommended
        highestGrowthSinceRecommended
        date
        lastQtr1Count
        lastQtr2Count
        lastQtr3Count
        lastQtr4Count
        lastQtr5Count
        lastQtr6Count
        lastQtr7Count
        lastQtr8Count
        lastQtr1Shares
        lastQtr2Shares
        lastQtr3Shares
        lastQtr4Shares
        lastQtr5Shares
        lastQtr6Shares
        lastQtr7Shares
        lastQtr8Shares
      }
    }
  }
`;
export const searchCounts = /* GraphQL */ `
  query SearchCounts($request: SearchCountsRequest) {
    searchCounts(request: $request) {
      columns {
        key
        value
        type
      }
      data {
        key
        value
      }
      total
      lastUpdatedTime
      reportType
      sectorAllocation {
        sector
        allocation
        total
        allocationPercentage
        count
      }
      compareSectorAllocation {
        sector
        ins {
          name
          allocation
          total
          percentage
        }
      }
    }
  }
`;
export const prices = /* GraphQL */ `
  query Prices($request: PricesRequest) {
    prices(request: $request) {
      key
      prices {
        interval
        is_new
        price
        stripe_price_id
        stripe_product_id
      }
    }
  }
`;
export const ping = /* GraphQL */ `
  query Ping {
    ping {
      status
    }
  }
`;
export const createEsIndices = /* GraphQL */ `
  query CreateEsIndices {
    createESIndices {
      status
    }
  }
`;
export const syncCompanyData = /* GraphQL */ `
  query SyncCompanyData {
    syncCompanyData {
      status
    }
  }
`;
export const syncInstitutionData = /* GraphQL */ `
  query SyncInstitutionData {
    syncInstitutionData {
      status
    }
  }
`;
export const deleteIndex = /* GraphQL */ `
  query DeleteIndex($index: String) {
    deleteIndex(index: $index) {
      status
    }
  }
`;
export const createIndex = /* GraphQL */ `
  query CreateIndex($index: String) {
    createIndex(index: $index) {
      status
    }
  }
`;
export const esSearchCompany = /* GraphQL */ `
  query EsSearchCompany($keyword: String) {
    esSearchCompany(keyword: $keyword) {
      cik
      industry
      ipo_year
      market_cap
      name
      sec_company_name
      sector
      symbol
    }
  }
`;
export const esSearchActiveCompanies = /* GraphQL */ `
  query EsSearchActiveCompanies($keyword: String) {
    esSearchActiveCompanies(keyword: $keyword) {
      cik
      industry
      ipo_year
      market_cap
      name
      sec_company_name
      sector
      symbol
    }
  }
`;
export const esSearchInstitution = /* GraphQL */ `
  query EsSearchInstitution($keyword: String) {
    esSearchInstitution(keyword: $keyword) {
      cik
      id
      name
      nasuid
      rank
    }
  }
`;
export const esSearchInstitutionAll = /* GraphQL */ `
  query EsSearchInstitutionAll($keyword: String) {
    esSearchInstitutionAll(keyword: $keyword) {
      cik
      id
      name
      nasuid
      rank
    }
  }
`;
export const esSearchTop10Institution = /* GraphQL */ `
  query EsSearchTop10Institution($keyword: String) {
    esSearchTop10Institution(keyword: $keyword) {
      cik
      id
      name
      nasuid
      rank
    }
  }
`;
export const getUserAlerts = /* GraphQL */ `
  query GetUserAlerts {
    getUserAlerts {
      name
      type
      institutions {
        cik
        id
        name
        nasuid
        rank
      }
      symbols {
        cik
        industry
        ipo_year
        market_cap
        name
        sec_company_name
        sector
        symbol
      }
      status
      trxn_val_min
      created_at
      last_modified_at
    }
  }
`;
export const sendMessage = /* GraphQL */ `
  query SendMessage($request: SendMessageRequest) {
    sendMessage(request: $request) {
      id
    }
  }
`;
export const addCusip = /* GraphQL */ `
  query AddCusip($request: addCusipRequest) {
    addCusip(request: $request) {
      status
      message
    }
  }
`;
export const getSec13Dist = /* GraphQL */ `
  query GetSec13Dist($request: SEC13DListRequest) {
    getSEC13Dist(request: $request) {
      id
      symbol
      companyName
      institutionName
      instCIK
      cusip
      currentShares
      prevShares
      sharesChange
      sharesChangePer
      ownership
      filedDate
      sec_statement_date
      sec_statement_time
      secForm
      companyCIK
      hidden
      prevSourceRef
      prevSourceDate
      secDate
      created_at
      total
    }
  }
`;
export const getUsersCountForDashboard = /* GraphQL */ `
  query GetUsersCountForDashboard {
    getUsersCountForDashboard {
      alltimeTotal
      currentMonthTotal
      monthlyUserCountsData {
        paidUsers
        totalUsers
        month
        year
      }
    }
  }
`;
export const getCompanyList = /* GraphQL */ `
  query GetCompanyList($request: CompanyListRequest) {
    getCompanyList(request: $request) {
      id
      symbol
      name
      cusip
      ipo_year
      sector
      industry
      cik
      sec_company_name
      i_fund
      market_cap_abs
      description
      outstanding_shares
      market_type
      created_at
      c52WkHigh
      c52WkLow
      YTDBeginPrice
      total
    }
  }
`;
export const getCusipList = /* GraphQL */ `
  query GetCusipList($request: cusipDataRequest) {
    getCusipList(request: $request) {
      id
      cusip
      symbol
      start_date
      end_date
      active_flag
      total
    }
  }
`;
export const deleteCusip = /* GraphQL */ `
  query DeleteCusip($request: deleteCusipRequest) {
    deleteCusip(request: $request) {
      status
      message
    }
  }
`;
export const getInstitutionList = /* GraphQL */ `
  query GetInstitutionList($request: InstitutionListRequest) {
    getInstitutionList(request: $request) {
      id
      rank
      name
      cik
      nasuid
      type
      is_new
      total
    }
  }
`;
export const userListForAdmin = /* GraphQL */ `
  query UserListForAdmin($request: UserListForAdminRequest) {
    userListForAdmin(request: $request) {
      columns {
        serialNo
        email
        fullName
        joiningDate
        planEndDate
        plan
        status
        telegramId
        telegramName
        telegramGroupName
        telegramChatMember
      }
      data {
        serialNo
        email
        fullName
        joiningDate
        planEndDate
        plan
        status
        telegramId
        telegramName
        telegramGroupName
        telegramChatMember {
          status
          until_date
          can_send_messages
          can_send_media_messages
          can_send_polls
          can_send_other_messages
          can_add_web_page_previews
          can_change_info
          can_invite_users
          can_pin_messages
          is_member
        }
      }
      total
    }
  }
`;
export const getUnAssignedCusip = /* GraphQL */ `
  query GetUnAssignedCusip($request: UnAssignedCusipRequest) {
    getUnAssignedCusip(request: $request) {
      cusip
      cik
      link
      quarter
      filing_type
      created_at
      total
    }
  }
`;
export const runProcessQtrlDataJob = /* GraphQL */ `
  query RunProcessQtrlDataJob($request: runProcessQtrlDataJobRequest) {
    runProcessQtrlDataJob(request: $request) {
      status
      message
    }
  }
`;
export const getIndexCompanies = /* GraphQL */ `
  query GetIndexCompanies($request: IndexCompanyListRequest) {
    getIndexCompanies(request: $request) {
      id
      symbol
      name
      index
      created_at
      last_modified_at
      total
    }
  }
`;
export const updateCompanyIndex = /* GraphQL */ `
  query UpdateCompanyIndex($request: UpdateIndexCompanyRequest) {
    updateCompanyIndex(request: $request) {
      status
      message
    }
  }
`;
export const hiddenQ13TranList = /* GraphQL */ `
  query HiddenQ13TranList($request: HiddenQ13TranListRequest) {
    hiddenQ13TranList(request: $request) {
      id
      symbol
      quarter
      iName
      total
    }
  }
`;
export const getPopularCompanies = /* GraphQL */ `
  query GetPopularCompanies($request: PopularCompanyListRequest) {
    getPopularCompanies(request: $request) {
      id
      symbol
      name
      category
      created_at
      last_modified_at
      total
    }
  }
`;
export const updatePopularCompany = /* GraphQL */ `
  query UpdatePopularCompany($request: UpdatePopularCompanyRequest) {
    updatePopularCompany(request: $request) {
      status
      message
    }
  }
`;
export const getSuccessStories = /* GraphQL */ `
  query GetSuccessStories($request: SuccessStoryRequest) {
    getSuccessStories(request: $request) {
      id
      name
      url
      created_at
      last_modified_at
      total
    }
  }
`;
export const updateSuccessStories = /* GraphQL */ `
  query UpdateSuccessStories($request: UpdateSuccessStoryRequest) {
    updateSuccessStories(request: $request) {
      status
      message
    }
  }
`;
export const getUniversityUploadAttachmentSignedUrl = /* GraphQL */ `
  query GetUniversityUploadAttachmentSignedUrl(
    $request: GetUploadAttachmentSignedUrlRequest
  ) {
    getUniversityUploadAttachmentSignedUrl(request: $request) {
      url
      expires
      uploadUrl
      accessUrl
    }
  }
`;
export const getVideos = /* GraphQL */ `
  query GetVideos($request: VideosRequest) {
    getVideos(request: $request) {
      id
      name
      url
      video_type
      description
      video_sub_type
      owner
      created_at
      last_modified_at
      total
    }
  }
`;
export const updateVideos = /* GraphQL */ `
  query UpdateVideos($request: UpdateVideoRequest) {
    updateVideos(request: $request) {
      status
      message
    }
  }
`;
export const newlyBoughtReport = /* GraphQL */ `
  query UuuReport($request: ReportRequest) {
    uuuReport(request: $request) {
      columns {
        symbol
        name
        sector
        lastQtr1Count
        lastQtr1Shares
        lastSale
        marketValue
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        name
        sector
        lastQtr1Count
        lastQtr1Shares
        lastSale
        marketValue
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
    }
  }
`;
export const getOpenUploadAttachmentSignedUrl = /* GraphQL */ `
  query GetOpenUploadAttachmentSignedUrl(
    $request: GetOpenUploadAttachmentSignedUrlRequest
  ) {
    getOpenUploadAttachmentSignedUrl(request: $request) {
      url
      expires
      uploadUrl
      accessUrl
    }
  }
`;

export const noOfDowDaysDropdown = /* GraphQL */ `
  query NoOfDowDaysDropdown {
    noOfDowDaysDropdown {
      Increased {
        name
        value
      }
      Decreased {
        name
        value
      }
    }
  }
`;
export const increasedDowDailyTheoryReport = /* GraphQL */ `
  query IncreasedDowDailyTheoryReport($request: ReportRequest) {
    increasedDowDailyTheoryReport(request: $request) {
      columns {
        symbol
        name
        sector
        industry
        lastDayPrice30
        lastDayPrice29
        lastDayPrice28
        lastDayPrice27
        lastDayPrice26
        lastDayPrice25
        lastDayPrice24
        lastDayPrice23
        lastDayPrice22
        lastDayPrice21
        lastDayPrice20
        lastDayPrice19
        lastDayPrice18
        lastDayPrice17
        lastDayPrice16
        lastDayPrice15
        lastDayPrice14
        lastDayPrice13
        lastDayPrice12
        lastDayPrice11
        lastDayPrice10
        lastDayPrice9
        lastDayPrice8
        lastDayPrice7
        lastDayPrice6
        lastDayPrice5
        lastDayPrice4
        lastDayPrice3
        lastDayPrice2
        lastDayPrice1
        gainInDays
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        name
        sector
        industry
        lastDayPrice30
        lastDayPrice29
        lastDayPrice28
        lastDayPrice27
        lastDayPrice26
        lastDayPrice25
        lastDayPrice24
        lastDayPrice23
        lastDayPrice22
        lastDayPrice21
        lastDayPrice20
        lastDayPrice19
        lastDayPrice18
        lastDayPrice17
        lastDayPrice16
        lastDayPrice15
        lastDayPrice14
        lastDayPrice13
        lastDayPrice12
        lastDayPrice11
        lastDayPrice10
        lastDayPrice9
        lastDayPrice8
        lastDayPrice7
        lastDayPrice6
        lastDayPrice5
        lastDayPrice4
        lastDayPrice3
        lastDayPrice2
        lastDayPrice1
        lastDayPrice30
        gainInDays
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
    }
  }
`;
export const decreasedDowDailyTheoryReport = /* GraphQL */ `
  query DecreasedDowDailyTheoryReport($request: ReportRequest) {
    decreasedDowDailyTheoryReport(request: $request) {
      columns {
        symbol
        name
        sector
        industry
        lastDayPrice30
        lastDayPrice29
        lastDayPrice28
        lastDayPrice27
        lastDayPrice26
        lastDayPrice25
        lastDayPrice24
        lastDayPrice23
        lastDayPrice22
        lastDayPrice21
        lastDayPrice20
        lastDayPrice19
        lastDayPrice18
        lastDayPrice17
        lastDayPrice16
        lastDayPrice15
        lastDayPrice14
        lastDayPrice13
        lastDayPrice12
        lastDayPrice11
        lastDayPrice10
        lastDayPrice9
        lastDayPrice8
        lastDayPrice7
        lastDayPrice6
        lastDayPrice5
        lastDayPrice4
        lastDayPrice3
        lastDayPrice2
        lastDayPrice1
        lossInDays
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        name
        sector
        industry
        lastDayPrice30
        lastDayPrice29
        lastDayPrice28
        lastDayPrice27
        lastDayPrice26
        lastDayPrice25
        lastDayPrice24
        lastDayPrice23
        lastDayPrice22
        lastDayPrice21
        lastDayPrice20
        lastDayPrice19
        lastDayPrice18
        lastDayPrice17
        lastDayPrice16
        lastDayPrice15
        lastDayPrice14
        lastDayPrice13
        lastDayPrice12
        lastDayPrice11
        lastDayPrice10
        lastDayPrice9
        lastDayPrice8
        lastDayPrice7
        lastDayPrice6
        lastDayPrice5
        lastDayPrice4
        lastDayPrice3
        lastDayPrice2
        lastDayPrice1
        lastDayPrice30
        lossInDays
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
    }
  }
`;
export const increasedDowDailyPriceAndVolTheoryReport = /* GraphQL */ `
  query IncreasedDowDailyPriceAndVolTheoryReport($request: ReportRequest) {
    increasedDowDailyPriceAndVolTheoryReport(request: $request) {
      columns {
        symbol
        name
        sector
        industry
        lastDayPriceAndVol30
        lastDayPriceAndVol29
        lastDayPriceAndVol28
        lastDayPriceAndVol27
        lastDayPriceAndVol26
        lastDayPriceAndVol25
        lastDayPriceAndVol24
        lastDayPriceAndVol23
        lastDayPriceAndVol22
        lastDayPriceAndVol21
        lastDayPriceAndVol20
        lastDayPriceAndVol19
        lastDayPriceAndVol18
        lastDayPriceAndVol17
        lastDayPriceAndVol16
        lastDayPriceAndVol15
        lastDayPriceAndVol14
        lastDayPriceAndVol13
        lastDayPriceAndVol12
        lastDayPriceAndVol11
        lastDayPriceAndVol10
        lastDayPriceAndVol9
        lastDayPriceAndVol8
        lastDayPriceAndVol7
        lastDayPriceAndVol6
        lastDayPriceAndVol5
        lastDayPriceAndVol4
        lastDayPriceAndVol3
        lastDayPriceAndVol2
        lastDayPriceAndVol1
        totalGainInDays
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        name
        sector
        industry
        lastDayPriceAndVol30
        lastDayPriceAndVol29
        lastDayPriceAndVol28
        lastDayPriceAndVol27
        lastDayPriceAndVol26
        lastDayPriceAndVol25
        lastDayPriceAndVol24
        lastDayPriceAndVol23
        lastDayPriceAndVol22
        lastDayPriceAndVol21
        lastDayPriceAndVol20
        lastDayPriceAndVol19
        lastDayPriceAndVol18
        lastDayPriceAndVol17
        lastDayPriceAndVol16
        lastDayPriceAndVol15
        lastDayPriceAndVol14
        lastDayPriceAndVol13
        lastDayPriceAndVol12
        lastDayPriceAndVol11
        lastDayPriceAndVol10
        lastDayPriceAndVol9
        lastDayPriceAndVol8
        lastDayPriceAndVol7
        lastDayPriceAndVol6
        lastDayPriceAndVol5
        lastDayPriceAndVol4
        lastDayPriceAndVol3
        lastDayPriceAndVol2
        lastDayPriceAndVol1
        totalGainInDays
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
    }
  }
`;
export const decreasedDowDailyPriceAndVolTheoryReport = /* GraphQL */ `
  query DecreasedDowDailyPriceAndVolTheoryReport($request: ReportRequest) {
    decreasedDowDailyPriceAndVolTheoryReport(request: $request) {
      columns {
        symbol
        name
        sector
        industry
        lastDayPriceAndVol30
        lastDayPriceAndVol29
        lastDayPriceAndVol28
        lastDayPriceAndVol27
        lastDayPriceAndVol26
        lastDayPriceAndVol25
        lastDayPriceAndVol24
        lastDayPriceAndVol23
        lastDayPriceAndVol22
        lastDayPriceAndVol21
        lastDayPriceAndVol20
        lastDayPriceAndVol19
        lastDayPriceAndVol18
        lastDayPriceAndVol17
        lastDayPriceAndVol16
        lastDayPriceAndVol15
        lastDayPriceAndVol14
        lastDayPriceAndVol13
        lastDayPriceAndVol12
        lastDayPriceAndVol11
        lastDayPriceAndVol10
        lastDayPriceAndVol9
        lastDayPriceAndVol8
        lastDayPriceAndVol7
        lastDayPriceAndVol6
        lastDayPriceAndVol5
        lastDayPriceAndVol4
        lastDayPriceAndVol3
        lastDayPriceAndVol2
        lastDayPriceAndVol1
        totalLossInDays
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      data {
        symbol
        name
        sector
        industry
        lastDayPriceAndVol30
        lastDayPriceAndVol29
        lastDayPriceAndVol28
        lastDayPriceAndVol27
        lastDayPriceAndVol26
        lastDayPriceAndVol25
        lastDayPriceAndVol24
        lastDayPriceAndVol23
        lastDayPriceAndVol22
        lastDayPriceAndVol21
        lastDayPriceAndVol20
        lastDayPriceAndVol19
        lastDayPriceAndVol18
        lastDayPriceAndVol17
        lastDayPriceAndVol16
        lastDayPriceAndVol15
        lastDayPriceAndVol14
        lastDayPriceAndVol13
        lastDayPriceAndVol12
        lastDayPriceAndVol11
        lastDayPriceAndVol10
        lastDayPriceAndVol9
        lastDayPriceAndVol8
        lastDayPriceAndVol7
        lastDayPriceAndVol6
        lastDayPriceAndVol5
        lastDayPriceAndVol4
        lastDayPriceAndVol3
        lastDayPriceAndVol2
        lastDayPriceAndVol1
        totalLossInDays
        lastSale
        c52WkLow
        c52WkHigh
        lastQtr2Count
        lastQtr1Count
        lastQtrCountChange
        lastQtr2Shares
        lastQtr1Shares
        lastQtrSharesChange
        sec13dCount
        ytd
        oneYrGrowth
        fiveYrGrowth
      }
      total
    }
  }
`;
export const noOfDowDaysForPriceAndVolDropdown = /* GraphQL */ `
  query NoOfDowDaysForPriceAndVolDropdown {
    noOfDowDaysForPriceAndVolDropdown {
      Increased {
        name
        value
      }
      Decreased {
        name
        value
      }
    }
  }
`;
export const noOfWeeksForWeeklyYTDDropdown = /* GraphQL */ `
  query NoOfWeeksForWeeklyYTDDropdown {
    noOfWeeksForWeeklyYTDDropdown {
      Increased {
        name
        value
      }
      Decreased {
        name
        value
      }
    }
  }
`;
