import React, { useEffect } from 'react';

import { Layout as AntLayout } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';

import { Footer, Header } from '../components';
import { getStudentInfo } from '../graphql';
import { setStudentInfo } from '../redux/reducers/user';
import { setLocationTitle } from '../utils/LocationHelper';
import StudentForm from '../views/StudentForm';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  component: any;
  authState: string | undefined;
  user: Object;
  showFooter?: boolean;
  path: string;
}

const DefaultLayout: React.FC<Props> = ({
  component: Component,
  authState,
  location,
  user,
  history,
  showFooter = true,
  title,
  ...props
}) => {
  const dispatch = useDispatch();
  const { stripeCustomer, loggedInUser, studentInfo, personalData } =
    useSelector(
      ({ user }) => ({
        stripeCustomer: get(user, 'profile.stripeCustomer'),
        loggedInUser: get(user, 'loggedInUser', {}),
        studentInfo: get(user, 'studentInfo', null),
        personalData: get(user, 'personalData', {}),
      }),
      shallowEqual,
    );

  const path = get(location, 'pathname');

  const stripeCustomerId = get(stripeCustomer, 'stripe_customer_id');
  const subscriptionStatus = get(stripeCustomer, 'subscription_status', null);
  const userGroups = get(loggedInUser, 'groups', []);
  const userType = personalData['type'];
  const isStudent = userType === 'student';
  let showStudentForm =
    isStudent && studentInfo && !studentInfo['school_name'] ? true : false;
  useEffect(() => {
    setLocationTitle(location);
  }, [location]);

  useEffect(() => {
    const promises = [getStudentInfo()];
    Promise.all(promises).then((res) => {
      dispatch(setStudentInfo(res[0]['studentInfo']));
    });
  }, [dispatch]);

  return (
    <Route
      {...props}
      render={(matchProps) =>
        authState === 'signedIn' && stripeCustomerId !== undefined ? (
          subscriptionStatus === 'ACTIVE' ||
          subscriptionStatus === 'TRIAL' ||
          isStudent ||
          userGroups.includes('internal_team') ? (
            <AntLayout className={styles.defaultContainer}>
              <Header
                {...matchProps}
                {...props}
                title={title}
                nonSubscribed={false}
                authState={authState}
              />
              {showStudentForm ? <StudentForm /> : ''}
              <Component user={user} {...matchProps} {...props} />
              {showFooter && <Footer />}
            </AntLayout>
          ) : (
            <Redirect to="/membership" />
          )
        ) : (
          // <Redirect to="/dashboard" />
          <Redirect to={authState === 'signIn' ? '/' : path ? path : '/'} />
        )
      }
    />
  );
};

export default DefaultLayout;
