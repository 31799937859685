import React from 'react';

// import { SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
// import { SyncOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

import { formatNumber2, currencyFormat } from '../../utils';
import styles from './style.module.scss';

interface Props {
  filterOptions: Object;
  handleReset: Function;
  handleClick: Function;
}

const FilteredValues: React.FC<Props> = ({
  filterOptions,
  handleReset,
  handleClick,
}) => {
  const {
    maxPrice,
    min1Yr,
    min5Yr,
    minInstCount,
    minCount,
    maxCount,
    minAllocatedPerc,
    maxAllocatedPerc,
    minPrice,
    minYTD,
    sector,
    symbols,
    liveCash,
    // eventDate,
    tsConviction,
    minSec13dCount,
    maxInstShares,
    minInstShares,
    minAproxTxnValue,
    minAproxInvValue,
    maxAproxTxnValue,
    maxInstCount,
    multiMarketCap,
    maxLiveCash,
    minLiveCash,
  } = filterOptions;
  const data = [
    {
      label: 'Sector',
      value: sector,
      type: 'multi',
      name: 'sector',
    },
    {
      label: 'Market Cap',
      value: multiMarketCap,
      type: 'multi',
      name: 'multiMarketCap',
    },
    {
      label: 'Min Price',
      value: minPrice,
      type: 'currency',
      name: 'minPrice',
    },
    {
      label: 'Max Price',
      value: maxPrice,
      type: 'currency',
      name: 'maxPrice',
    },
    {
      label: 'Min YTD',
      value: minYTD,
      type: 'percent',
      name: 'minYTD',
    },
    {
      label: 'Min 1yr Growth',
      value: min1Yr,
      type: 'percent',
      name: 'min1Yr',
    },

    {
      label: 'Min 5yr Growth',
      value: min5Yr,
      type: 'percent',
      name: 'min5Yr',
    },
    {
      label: 'Min Inst count',
      value: minInstCount,
      type: 'string',
      name: 'minInstCount',
    },
    {
      label: 'Min count',
      value: minCount,
      type: 'string',
      name: 'minCount',
    },
    {
      label: 'Max count',
      value: maxCount,
      type: 'string',
      name: 'maxCount',
    },
    {
      label: 'Min Allocated %',
      value: minAllocatedPerc,
      type: 'string',
      name: 'minAllocatedPerc',
    },
    {
      label: 'Max Allocated %',
      value: maxAllocatedPerc,
      type: 'string',
      name: 'maxAllocatedPerc',
    },
    {
      label: 'Min Live Cash',
      value: minLiveCash,
      type: 'currency',
      name: 'minLiveCash',
    },
    {
      label: 'Max Live Cash',
      value: maxLiveCash,
      type: 'currency',
      name: 'maxLiveCash',
    },
    {
      label: 'Live Cash',
      value: liveCash,
      type: 'currency',
      name: 'liveCash',
    },
    // {
    //   label: 'Event Date',
    //   value: eventDate,
    //   type: 'string',
    //   name: 'eventDate',
    // },
    {
      label: 'TS Conviction',
      value: tsConviction,
      type: 'string',
      name: 'tsConviction',
    },
    {
      label: 'Min SEC 13D/G Count',
      value: minSec13dCount,
      type: 'string',
      name: 'minSec13dCount',
    },
    {
      label: 'Max Inst count',
      value: maxInstCount,
      type: 'string',
      name: 'maxInstCount',
    },
    {
      label: 'Min Inst Shares',
      value: minInstShares
        ? currencyFormat(minInstShares, false, false, 2, false, true)
        : minInstShares,
      type: 'compressed',
      name: 'minInstShares',
    },
    {
      label: 'Max Inst Shares',
      value: maxInstShares
        ? currencyFormat(maxInstShares, false, false, 2, false, true)
        : maxInstShares,
      type: 'compressed',
      name: 'maxInstShares',
    },
    {
      label: 'Min Approx. trxn value',
      value: minAproxTxnValue
        ? currencyFormat(minAproxTxnValue, false, false, 2, false, true)
        : minAproxTxnValue,
      type: 'compressed',
      name: 'minAproxTxnValue',
    },
    {
      label: 'Min Approx. Inv value',
      value: minAproxInvValue
        ? currencyFormat(minAproxInvValue, false, false, 2, false, true)
        : minAproxInvValue,
      type: 'compressed',
      name: 'minAproxInvValue',
    },
    {
      label: 'Max Approx. trxn value',
      value: maxAproxTxnValue
        ? currencyFormat(maxAproxTxnValue, false, false, 2, false, true)
        : maxAproxTxnValue,
      type: 'compressed',
      name: 'maxAproxTxnValue',
    },
  ];
  return (
    <>
      FILTERS :
      <Tag
        onClick={handleReset}
        style={{ margin: 5, cursor: 'pointer' }}
        className={styles.vClearAllNew}
      >
        CLEAR ALL
      </Tag>
      {symbols &&
        (Array.isArray(symbols) ? (
          symbols.map((symbol) => (
            <Tag
              key={symbol}
              closable
              onClose={() => handleClick('symbols', symbol)}
              // icon={
              //   isLoading ? (
              //     <SyncOutlined spin />
              //   ) : (
              //     <CloseCircleOutlined
              //       onClick={() => handleClick('symbol', symbol)}
              //     />
              //   )
              // }
              color={'success'}
            >
              {symbol}
            </Tag>
          ))
        ) : (
          <Tag
            key={symbols}
            // icon={isLoading ? <SyncOutlined spin /> : <CheckCircleOutlined />}
            color={'success'}
          >
            {symbols}
          </Tag>
        ))}
      {/* eslint-disable-next-line */}
      {data.map((item) => {
        const { name, value, type, label } = item;
        if (value || value === 0) {
          switch (type) {
            case 'currency':
              return (
                <Tag
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${currencyFormat(
                  value,
                  false,
                  false,
                  2,
                  false,
                  true,
                )}`}</Tag>
              );
            case 'percent':
              return (
                <Tag
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${formatNumber2(value)}%`}</Tag>
              );
            case 'bool':
              return (
                <Tag
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${value ? 'Yes' : 'No'}`}</Tag>
              );
            case 'string':
              return (
                <Tag
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${value}`}</Tag>
              );
            case 'compressed':
              return (
                <Tag
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${formatNumber2(value, 0, true)}`}</Tag>
              );
            case 'multi':
              return Array.isArray(value) ? (
                value.map((item) => (
                  <Tag
                    key={item}
                    closable
                    onClose={() => handleClick(name, item)}
                    color={'success'}
                  >
                    {name === 'multiStatus' && item === 'DECREASED'
                      ? 'REDUCED'
                      : name === 'multiStatus' && item === 'NO_CHANGE'
                      ? 'NO CHANGE'
                      : name === 'multiStatus' && item === 'NEWLY_BOUGHT'
                      ? 'NEWLY BOUGHT'
                      : name === 'multiStatus' && item === 'SOLD_OFF'
                      ? 'SOLD OFF'
                      : name === 'sector' && item === ''
                      ? 'ALL'
                      : item}
                  </Tag>
                ))
              ) : (
                <Tag
                  key={value}
                  color={'success'}
                  closable
                  onClose={() => handleClick(name, value)}
                >
                  {value}
                </Tag>
              );
          }
        }
      })}
    </>
  );
};

export default FilteredValues;
