import React from 'react';

import { Menu, Switch } from 'antd';
import { NavLink } from 'react-router-dom';

import { isStudentUrl } from '../../utils';
import styles from './style.module.scss';
// const { SubMenu } = Menu;

const isStudent = isStudentUrl();

export const links1 = (isStudent: boolean) => {
  let links = [
    {
      name: 'Search',
      path: '/search',
      text: '/search',
      id: 'search',
      isNew: false,
    },
    {
      name: 'Reports',
      path: '/reports/filters=&isTop10=false&page=1&reportCode=TS&reportType=daily&searchText=&size=20',
      text: '/reports/:reportQueryData?',
      id: 'reports',
      isNew: false,
    },
    // {
    //   name: 'Our Picks',
    //   path: '/our/picks/all',
    //   text: '/our/picks/:type',
    //   id: 'ourPicks',
    //   isNew: false,
    // },
  ];

  if (!isStudent) {
    links.push(
      {
        name: 'Our Picks',
        path: '/our/picks/all',
        text: '/our/picks/:type',
        id: 'ourPicks',
        isNew: false,
      },
      {
        name: 'Community',
        path: '/community',
        text: '/community',
        id: 'community',
      },
    );
  }
  return links;
};

export const sidebarLinks = (isStudent: boolean) => {
  let links = [
    {
      name: 'Portfolio',
      path: isStudent
        ? '/portfolio/Portfolio/virtual'
        : '/portfolio/Portfolio/real',
      text: isStudent
        ? '/portfolio/Portfolio/virtual'
        : '/portfolio/Portfolio/real',
      id: 'portfolio',
    },

    {
      name: 'Search',
      path: '/search',
      text: '/search',
      id: 'search',
    },
    {
      name: 'Reports',
      path: '/reports/filters=&isTop10=false&page=1&reportCode=TS&reportType=daily&searchText=&size=20',
      text: '/reports/:reportQueryData?',
      id: 'reports',
    },
    // {
    //   name: 'Our Picks',
    //   path: '/our/picks/all',
    //   text: '/our/picks/:type',
    //   id: 'ourPicks',
    // },
    // {
    //   name: 'Community',
    //   path: '/community',
    //   text: '/community',
    //   id: 'community',
    // },
    {
      name: 'Alerts',
      path: '/alerts',
      text: '/alerts',
      id: 'alerts',
    },
    {
      name: `ThinkSabien's Activity`,
      path: '/trending/symbol/daily',
      text: '/trending/symbol/daily',
      id: 'trending',
    },
    {
      name: `TS-Youth Heroes`,
      path: '/Gamification',
      text: '/Gamification',
      id: 'Gamification',
    },
    {
      name: `Founder's Story`,
      path: '/founder-story',
      text: '/founder-story',
      id: 'founder-story',
    },
    {
      name: `TS-University`,
      path: '/university',
      text: '/university',
      id: 'university',
    },
    {
      name: `Donate`,
      path: '/donate',
      text: '/donate',
      id: 'donate',
    },
    {
      name: `FAQ`,
      path: '/faq',
      text: '/faq',
      id: 'faq',
    },
    {
      name: `Contact Us`,
      path: '/contact-us',
      text: '/contact-us',
      id: 'contact-us',
    },
    {
      name: `Profile & Settings`,
      path: '/setting',
      text: '/setting',
      id: 'setting',
    },
  ];

  if (!isStudent) {
    links.splice(3, 0, {
      name: 'Our Picks',
      path: '/our/picks/all',
      text: '/our/picks/:type',
      id: 'ourPicks',
    });
    links.splice(4, 0, {
      name: 'Community',
      path: '/community',
      text: '/community',
      id: 'community',
    });
  }
  return links;
};

// export const links2 = [
//   {
//     name: 'Reports',
//     path:
//       '/reports/filters=&isTop10=false&page=1&reportCode=AID&reportType=daily&searchText=&size=20',
//     text: '/reports/:reportQueryData?',
//     id: 'reports',
//   },
// ];

export const thinksabioPageDropDownMenu = (signOut) => (
  <Menu className={styles.dashboardMenuListBox}>
    <Menu.Item>
      <NavLink to="/university" activeClassName={styles.navActiveClass}>
        <span>{`TS-University`}</span>
      </NavLink>
    </Menu.Item>
    {/*<Menu.Item>*/}
    {/*  <NavLink to="/donate" activeClassName={styles.navActiveClass}>*/}
    {/*    <span>{`Donate`}</span>*/}
    {/*  </NavLink>*/}
    {/*</Menu.Item>*/}
    <Menu.Item>
      <NavLink to="/founder-story" activeClassName={styles.navActiveClass}>
        <span>{`Founder's Story`}</span>
      </NavLink>
    </Menu.Item>

    <Menu.Item>
      <NavLink to="/faq" activeClassName={styles.navActiveClass}>
        <span>{`FAQ`}</span>
      </NavLink>
    </Menu.Item>

    <Menu.Item>
      <NavLink to="/contact-us" activeClassName={styles.navActiveClass}>
        <span>Contact Us</span>
      </NavLink>
    </Menu.Item>
  </Menu>
);

export const userActionDropDown = (
  signOut,
  onThemeChange,
  theme,
  showSetting,
) => (
  <Menu className={styles.dashboardMenuListBox}>
    {showSetting && (
      <Menu.Item>
        <NavLink to="/setting" activeClassName={styles.navActiveClass}>
          <span>{`Profile & Settings`}</span>
        </NavLink>
      </Menu.Item>
    )}

    <Menu.Item>
      <span>{`Dark Mode`}</span>
      <Switch defaultChecked={theme === 'dark'} onChange={onThemeChange} />
    </Menu.Item>

    <Menu.Item onClick={signOut}>
      <span>Sign Out</span>
    </Menu.Item>
  </Menu>
);

export const myUniverseDropDownMenu = (
  <Menu className={styles.dashboardMenuListBox}>
    {isStudent ? (
      <>
        <Menu.Item>
          <NavLink
            to="/GamificationByState"
            activeClassName={styles.navActiveClass}
          >
            <span>{`TS-Youth Heroes`}</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink
            to="/portfolio/Portfolio/virtual"
            activeClassName={styles.navActiveClass}
          >
            <span>Portfolio</span>
          </NavLink>
        </Menu.Item>
      </>
    ) : (
      <Menu.Item>
        <NavLink
          to="/portfolio/Portfolio/real"
          activeClassName={styles.navActiveClass}
        >
          <span>Portfolio</span>
        </NavLink>
      </Menu.Item>
    )}
    <Menu.Item>
      <NavLink to="/alerts" activeClassName={styles.navActiveClass}>
        <span>Alerts</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink
        to="/trending/symbol/daily"
        activeClassName={styles.navActiveClass}
      >
        <span>{`ThinkSabien's Activity`}</span>
      </NavLink>
    </Menu.Item>
  </Menu>
);
export const myUniverseDropDownMenuForPaid = (
  <Menu className={styles.dashboardMenuListBox}>
    {isStudent ? (
      <Menu.Item>
        <NavLink
          to="/portfolio/Portfolio/virtual"
          activeClassName={styles.navActiveClass}
        >
          <span>Portfolio</span>
        </NavLink>
      </Menu.Item>
    ) : (
      <Menu.Item>
        <NavLink
          to="/portfolio/Portfolio/real"
          activeClassName={styles.navActiveClass}
        >
          <span>Portfolio</span>
        </NavLink>
      </Menu.Item>
    )}

    <Menu.Item>
      <NavLink to="/alerts" activeClassName={styles.navActiveClass}>
        <span>Alerts</span>
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink
        to="/trending/symbol/daily"
        activeClassName={styles.navActiveClass}
      >
        <span>{`ThinkSabien's Activity`}</span>
      </NavLink>
    </Menu.Item>
  </Menu>
);
// export const ourPickDropDownMenu = (
//   <Menu className={styles.dashboardMenuListBox}>
//     <Menu.Item>
//       <NavLink to="/our/picks/all" activeClassName={styles.navActiveClass}>
//         <span>{`Today's Pick`}</span>
//       </NavLink>
//     </Menu.Item>
//     <Menu.Item>
//       <NavLink to="/our/picks/all" activeClassName={styles.navActiveClass}>
//         <span>{`All Picks`}</span>
//       </NavLink>
//     </Menu.Item>

//     <Menu.Item>
//       <NavLink
//         to="/our/picks/quarterly"
//         activeClassName={styles.navActiveClass}
//       >
//         <span>{`Quarterly Picks`}</span>
//       </NavLink>
//     </Menu.Item>
//   </Menu>
// );
// export const reportsDropdown = (
//   <Menu className={styles.dashboardMenuListBox}>
//     <SubMenu title="Daily">
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=AID&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`All Institutions`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=SDY&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Sec 13D/G / Todays Report`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=SSL&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Sec 13D/G / By Symbol`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=SDL&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Sec 13D/G / Detail`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=SSLC&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Sec 13D/G / All Symbol (Cumulative)`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=SDLC&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Sec 13D/G / Detail (Cumulative)`}</span>
//         </NavLink>
//       </Menu.Item>

//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=TS&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Trending Stock`}</span>
//         </NavLink>
//       </Menu.Item>

//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=ADMA&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`ADHOC / Moving Avg`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=ADUD&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`ADHOC / Under a Dollar`}</span>
//         </NavLink>
//       </Menu.Item>

//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=DI&reportType=daily&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Insider`}</span>
//         </NavLink>
//       </Menu.Item>
//     </SubMenu>
//     <SubMenu title="Weekly">
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&reportCode=DT&reportType=weekly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Dow Theory`}</span>
//         </NavLink>
//       </Menu.Item>
//     </SubMenu>
//     <SubMenu title="Quarterly">
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=AINB&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`All Institution / Newly Bought`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=AIU&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`All Institution / Ultimate Report`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=AIO&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`All Institution / Other Report`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/cik=1037389&filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=ITT&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Individual Report / Top 10 Hedge`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/cik=1601086&filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=IS&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Individual Report / Special Reports`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=DR&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Dividends Summary`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=SR&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Sector Wise Allocation`}</span>
//         </NavLink>
//       </Menu.Item>
//       {/* <SubMenu title="Top 10 Hedge Funds"> */}
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=TTNB&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Top 10 Hedge Funds / Newly Bought`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=TTU&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Top 10 Hedge Funds / Ultimate Report`}</span>
//         </NavLink>
//       </Menu.Item>
//       <Menu.Item>
//         <NavLink
//           to={`/reports/filters=&isTop10=false&page=1&quarter=2020_Q4&reportCode=TTO&reportType=quarterly&searchText=&selectedDate=0&size=20`}
//           activeClassName={styles.navActiveClass}
//         >
//           <span>{`Top 10 Hedge Funds / Other Report`}</span>
//         </NavLink>
//       </Menu.Item>
//       {/* </SubMenu> */}
//     </SubMenu>
//   </Menu>
// );
