export const TelegramGroupsData = {
  stocks: {
    title: 'Stock Telegram Groups',
    groups: [
      {
        number: '01',
        name: 'THINKSABIO - STOCKS',
        description: 'Add Group Description',
      },
      {
        number: '02',
        name: 'THINKSABIO - STOCK ALERTS',
        description: 'Add Group Description',
      },
      {
        number: '03',
        name: 'THINKSABIO LATEST NEWS',
        description: 'Add Group Description',
      },
    ],
    image_url: 'https://thinksabio-asset.s3.amazonaws.com/stocks-telegram.jpeg',
  },
  options: {
    title: 'Option Telegram Groups',
    groups: [
      {
        number: '01',
        name: 'THINKSABIO - OPTIONS',
        description: 'Add Group Description',
      },
      {
        number: '02',
        name: 'THINKSABIO -  OPTION - ALERTS',
        description: 'Add Group Description',
      },
    ],
    image_url:
      'https://thinksabio-asset.s3.amazonaws.com/options-telegram.jpeg',
  },
  crypto: {
    title: 'Crypto Telegram Group',
    groups: [
      {
        number: '01',
        name: 'THINKSABIO - CRYPTO',
        description: 'Add Group Description',
      },
      {
        number: '02',
        name: 'THINKSABIO - CRYPTO - ALERTS',
        description: 'Add Group Description',
      },
    ],
    image_url:
      'https://thinksabio-asset.s3.amazonaws.com/cryotoi-telegram.jpeg',
  },
};

export const ExpertData = {
  stocks: {
    title: 'Chalk Talk by Experts',
    experts: ['ALL', 'DHANUNJAYA', 'DEVIATE', 'SAIRAM', 'HASTI'],
    videos: [
      {
        video_id: 'tvvvnr2g5DI',
        owner: 'DHANUNJAYA',
      },
      {
        video_id: 'hLQEdeLphtA',
        owner: 'DHANUNJAYA',
      },
      {
        video_id: 'CPRQKl6yv5g',
        owner: 'DEVIATE',
      },
      {
        video_id: 'Qiy2OuBeaRQ',
        owner: 'SAIRAM',
      },
      {
        video_id: '5G4wNXtcunk',
        owner: 'SAIRAM',
      },
      {
        video_id: 'ZB4KxlYL3NU',
        owner: 'SAIRAM',
      },
      {
        video_id: '_qzUHiG0_78',
        owner: 'HASTI',
      },
      {
        video_id: 'IqZPpsgBXgw',
        owner: 'HASTI',
      },
      {
        video_id: '1Hx0FSkIar0',
        owner: 'SAIRAM',
      },
      {
        video_id: 'g8471a-eYkc',
        owner: 'DEVIATE',
      },
    ],
  },
  options: {
    title: 'Chalk Talk by Experts',
    experts: ['ALL', 'DEVIATE', 'HASTI'],
    videos: [
      {
        video_id: 'CPRQKl6yv5g',
        owner: 'DEVIATE',
      },
      {
        video_id: 'IqZPpsgBXgw',
        owner: 'HASTI',
      },
      {
        video_id: '0BDJQHC_Km0',
        owner: 'DEVIATE',
      },

      {
        video_id: '_qzUHiG0_78',
        owner: 'HASTI',
      },
    ],
  },
  crypto: {
    title: 'Chalk Talk by Experts',

    experts: ['ALL', 'RAJESH'],
    videos: [
      {
        video_id: 'CekrRpiunfU',
        owner: 'RAJESH',
      },
      {
        video_id: 'dnm8uIvctAw',
        owner: 'RAJESH',
      },
      {
        video_id: 'mc9kL-gKGKQ',
        owner: 'RAJESH',
      },
    ],
  },
};

export const VideosData = {
  stocks: {
    title: 'Videos on Reports',
    reportType: ['ALL', 'INSIDERS', 'SEC-13DG', 'INSTITUTION(IIR)'],
    videos: [
      {
        video_id: 'a7cViA1EWwk',
        owner: 'INSIDERS',
      },
      {
        video_id: 'jrbJwcqCim8',
        owner: 'SEC-13DG',
      },
      {
        video_id: 'eW2CO5AkCNU',
        owner: 'INSTITUTION(IIR)',
      },
      {
        video_id: 'LioyU21c0y4',
        owner: 'INSTITUTION(IIR)',
      },
      {
        video_id: 'vPhLWuaAXOE',
        owner: 'INSTITUTION(IIR)',
      },
      {
        video_id: 'LioyU21c0y4',
        owner: 'INSTITUTION(IIR)',
      },
      {
        video_id: '_pxSDOQT-J64',
        owner: 'INSTITUTION(IIR)',
      },
      {
        video_id: '167yTTU-hu0',
        owner: 'INSTITUTION(IIR)',
      },
      {
        video_id: 'UtK0IvB1CEM',
        owner: 'SEC-13DG',
      },
    ],
  },
  options: {
    title: 'Videos on Reports',
    reportType: [],
    videos: [],
  },
  crypto: {
    title: 'Videos on Reports',

    reportType: [],
    videos: [],
  },
};

// export const SuccessStoryData = {
//   stocks: {
//     title: 'Success Stories',
//     images: [],
//   },
//   options: {
//     title: 'Success Stories',
//     images: [
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/0eb659fc-732b-4255-8838-42252c09a663-1-273x320.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/3b9d5347-4b89-4cdc-9cd8-d8cefca18c2c-1-289x320.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/8ddc0dfc-98e4-4d47-9dd0-31a8fe411e1c-1-269x320.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/45bdf743-c904-42b6-bbd6-fc403ad31935-269x271.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/40cdeb89-de62-49c8-ad7e-df3e77bc6e17-1-219x320.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/697d9204-4d6a-4de7-85a4-39fba3f26756-219x261.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/708eedff-8c1d-4bf2-b106-db2d23eb4435-219x214.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/af74372f-6095-41bd-a427-743263ac738e-219x220.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/b4cc887c-a875-47b2-b4ac-8e6592a93ffb-219x237.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/ab62cf9a-8955-4ed1-a58b-d5ece4df2cad-1-204x320.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/be86bd1e-83b3-4479-b842-c1bde3f4bf9d-204x115.jpg',
//       'https://ts-university-demo-pages.s3.amazonaws.com/assets/images/11543153-a731-4009-8d8a-62d1f0f742fb-204x149.jpg',
//     ],
//   },
//   crypto: {
//     title: 'Success Stories',
//     images: [],
//   },
// };

export const PopularStockData = {
  stocks: {
    title: 'Popular Stocks',
    categories: [
      {
        key: 'ENERGY',
        value: 'ENERGY',
      },
      {
        key: 'FINANCIAL_SERVICES',
        value: 'FINANCIAL SERVICES',
      },
      {
        key: 'TECHNOLOGY',
        value: 'TECHNOLOGY',
      },

      {
        key: 'SOLAR',
        value: 'SOLAR',
      },
      {
        key: 'SEMICONDUCTOR',
        value: 'SEMICONDUCTOR',
      },
      {
        key: 'EV',
        value: 'EV',
      },
      {
        key: '3-D',
        value: '3-D',
      },
      {
        key: 'AI',
        value: 'AI',
      },
      {
        key: 'QUANTUM',
        value: 'QUANTUM',
      },
      {
        key: 'BEAUTY',
        value: 'BEAUTY',
      },
      {
        key: 'BITCOIN',
        value: 'BITCOIN',
      },
      {
        key: 'IMMUNOTHERAPY',
        value: 'IMMUNOTHERAPY',
      },
    ],
  },
  options: {
    title: 'Popular Stocks',
    categories: [],
  },
  crypto: {
    title: 'Popular Stocks',
    categories: [],
  },
};
