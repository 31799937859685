interface ColumnWidthsTypes {
  rank: number;
  student_performance_rank: number;
  balance: number;
  current_capital: number;
  overall_perc_returns: number;
  invested_amount_gain: number;
  invested_perc_returns: number;
  school_state: number;
  school_county: number;
  school_name: number;
  student_name: number;
  invested_value: number;
  current_value: number;
  grade: number;
  percentage_returns: number;
  student_count: number;
  symbol: number;
  growthSinceRecommended: number;
  reason: number;
  date: number;
  priceOnRecDate: number;
  detail: number;
  symbols: number;
  sharesChange: number;
  sector: number;
  industry: number;
  secForm: number;
  secInstCount: number;
  currentShares: number;
  prevShares: number;
  lastQtr1Count: number;
  lastQtr2Count: number;
  lastQtr3Count: number;
  lastQtr4Count: number;
  lastQtr5Count: number;
  lastQtr6Count: number;
  lastQtr7Count: number;
  lastQtr8Count: number;
  totalLastQtr1Shares: number;
  totalLastQtr2Shares: number;
  totalSharesDiff: number;
  lastQtr1Shares: number;
  lastQtr2Shares: number;
  lastQtr3Shares: number;
  lastQtr4Shares: number;
  lastQtr5Shares: number;
  lastQtr6Shares: number;
  lastQtr7Shares: number;
  lastQtr8Shares: number;
  lastQtr9Shares: number;
  lastQtr10Shares: number;
  lastQtr11Shares: number;
  lastQtr12Shares: number;
  lastQtr1HedgeShares: number;
  lastQtr1HedgeHeroShares: number;
  sharesDiff: number;
  lastQtr1HedgeCount: number;
  lastQtr2HedgeCount: number;
  lastQtr3HedgeCount: number;
  lastQtr4HedgeCount: number;
  lastQtr5HedgeCount: number;
  lastQtr6HedgeCount: number;
  lastQtr7HedgeCount: number;
  lastQtr8HedgeCount: number;
  sec13dCount: number;
  totalInstMfShares: number;
  lastQtr2HedgeShares: number;
  lastQtr3HedgeShares: number;
  lastQtr4HedgeShares: number;
  lastQtr5HedgeShares: number;
  lastQtr6HedgeShares: number;
  lastQtr7HedgeShares: number;
  lastQtr8HedgeShares: number;
  rishiPrevShares: number;
  rishiCurrShares: number;
  shares: number;
  count: number;
  searchCount: number;
  soldOutCount: number;
  soldOutShares: number;
  ytd: number;
  oneYrGrowth: number;
  fiveYrGrowth: number;
  priceChangePerc: number;
  lastQ1Allocation: number;
  lastQ2Allocation: number;
  lastQ3Allocation: number;
  lastQ4Allocation: number;
  lastQ5Allocation: number;
  lastQ6Allocation: number;
  lastQ7Allocation: number;
  lastQ8Allocation: number;
  lastQ1DivPerc: number;
  lastQ2DivPerc: number;
  lastQ3DivPerc: number;
  lastQ4DivPerc: number;
  lastQ5DivPerc: number;
  lastQ6DivPerc: number;
  lastQ7DivPerc: number;
  lastQ8DivPerc: number;
  totalDivPercLast1Yr: number;
  totalDivPercLast2Yr: number;
  totalDivPercLast3Yr: number;
  totalDivPercLast4Yr: number;
  sharesChangePer: number;
  ownership: number;
  price200DayChange: number;
  price150DayChange: number;
  price20DayChange: number;
  price10DayChange: number;
  price50DayChange: number;
  price1DayChange: number;
  price5DayChange: number;
  price100DayChange: number;
  insidersPerc: number;
  instPerc: number;
  changePerc: number;
  allocationPerc: number;
  allInstAlloc: number;
  topHedgeAlloc: number;
  instCountChange: number;
  instSharesChange: number;
  gainInWeeks: number;
  lossInWeeks: number;
  lastQtrCountChange: number;
  lastQtrSharesChange: number;
  changeHedgeCountStatus: number;
  changeHedgeSharesStatus: number;
  changeSharesStatus: number;
  totalPaidLast1Yr: number;
  totalPaidLast2Yr: number;
  lastQtr1InvestedValue: number;
  lastQtr1ApproxInvestedValue: number;
  lastSale: number;
  c52WkLow: number;
  c52WkHigh: number;
  lastQtr1HedgeInvestedValue: number;
  lastQtr1HedgeInvested: number;
  yearBeginPrice: number;
  price1DayAgo: number;
  price5DaysAgo: number;
  price10DaysAgo: number;
  price20DaysAgo: number;
  price50DaysAgo: number;
  price100DaysAgo: number;
  price200DaysAgo: number;
  c5YrLow: number;
  c5YrHigh: number;
  priceDiff: number;
  ytdPriceChange: number;
  tmv: number;
  beginPrice: number;
  endPrice: number;
  priceChange: number;
  lastQ2Dividend: number;
  lastQ1Dividend: number;
  lastQ3Dividend: number;
  lastQ4Dividend: number;
  lastQ5Dividend: number;
  lastQ6Dividend: number;
  lastQ7Dividend: number;
  lastQ8Dividend: number;
  totalPaidLast3Yr: number;
  totalPaidLast4Yr: number;
  lastQtr1Dividend: number;
  lastQtr2Dividend: number;
  lastQtr3Dividend: number;
  lastQtr4Dividend: number;
  lastQtr5Dividend: number;
  lastQtr6Dividend: number;
  lastQtr7Dividend: number;
  lastQtr8Dividend: number;
  lastQtr1Avg: number;
  lastQtr2Avg: number;
  lastQtr3Avg: number;
  lastQtr4Avg: number;
  lastQtr5Avg: number;
  lastQtr6Avg: number;
  lastQtr7Avg: number;
  lastQtr8Avg: number;
  totalInstMfShareValue: number;
  sharesValue: number;
  lastWkAvg1: number;
  lastWkAvg2: number;
  lastWkAvg3: number;
  lastWkAvg4: number;
  lastWkAvg5: number;
  lastWkAvg6: number;
  lastWkAvg7: number;
  lastWkAvg8: number;
  lastWkAvg9: number;
  lastWkAvg10: number;
  lastWkAvg11: number;
  lastWkAvg12: number;
  lastWkAvg13: number;
  lastWkAvg14: number;
  lastWkAvg15: number;
  lastWkAvg16: number;
  lastWkAvg17: number;
  lastWkAvg18: number;
  lastWkAvg19: number;
  lastWkAvg20: number;
  lastWkAvg21: number;
  lastWkAvg22: number;
  lastWkAvg23: number;
  lastWkAvg24: number;
  lastWkAvg25: number;
  lastWkAvg26: number;
  lastWkAvg27: number;
  lastWkAvg28: number;
  lastWkAvg29: number;
  lastWkAvg30: number;
  lastWkAvg31: number;
  lastWkAvg32: number;
  lastWkAvg33: number;
  lastWkAvg34: number;
  lastWkAvg35: number;
  lastWkAvg36: number;
  lastWkAvg37: number;
  lastWkAvg38: number;
  lastWkAvg39: number;
  lastWkAvg40: number;
  lastWkAvg41: number;
  lastWkAvg42: number;
  lastWkAvg43: number;
  lastWkAvg44: number;
  lastWkAvg45: number;
  lastWkAvg46: number;
  lastWkAvg47: number;
  lastWkAvg48: number;
  lastWkAvg49: number;
  lastWkAvg50: number;
  lastWkAvg51: number;
  lastWkAvg52: number;
  transactionPrice: number;
  investedValue: number;
  aum: number;
  name: number;
  companyName: number;
  institutionName: number;
  allocation: number;
  change: number;
  possGrowth: number;
  forecastedClosingPrices: number;
  status: number;
  specialStatus: number;
  changeStatus: number;
  secDate: number;
  insiderSecDate: number;
  lastQtr1HedgeHeroStatus: number;
  price15minAgo: number;
  conclusion: number;
  changeCountStatus: number;
  insiderName: number;
  type: number;
  yc_price: number;
  lastQtr9: number;
  lastQtr8: number;
  lastQtr7: number;
  lastQtr6: number;
  lastQtr5: number;
  lastQtr4: number;
  lastQtr3: number;
  lastQtr2: number;
  lastQtr1: number;
  dateReported: number;
  highestPriceSinceReco: number;
  highestGrowthSinceRecommended: number;
  highestPriceDate: number;
  price_on_entry: number;
  filedDate: number;
  security: number;
  insiderRole: number;
  created_at: number;
  last_modified_at: number;
  secInsiderCount: number;
  secInsiderBuyCount: number;
  secInsiderSellCount: number;
  soldOutValue: number;
  equity: number;
  todayReturns: number;
  totalCost: number;
  totalReturns: number;
  totalReturnsPerc: number;
  todayReturnsPerc: number;
  sec13dNewCount: number;
  companyInstCountStatus: number;
  dollarChange: number;
  dollarChangePerc: number;
  totalOptionContracts: number;
  lastQtr1CallContracts: number;
  lastQtr1CallContractsRatio: number;
  lastQtr1PutContracts: number;
  lastQtr1ContractsRatio: number;
  lastQtr1CallCounts: number;
  lastQtr1CallCountsRatio: number;
  lastQtr1PutCounts: number;
  lastQtr1PutCountsRatio: number;
  lastQtr1PutContractsRatio: number;
  lastQtr1CallShrs: number;
  lastQtr1CallRatio: number;
  lastQtr1PutShrs: number;
  lastQtr1PutRatio: number;
  lastQtr1CallInstContracts: number;
  lastQtr1CallInstCounts: number;
  lastQtr1CallInstContractsRatio: number;
  lastQtr1PutInstContracts: number;
  lastQtr1PutInstCounts: number;
  lastQtr1PutInstContractsRatio: number;
  invoiceId: number;
  description: number;
  payment_card: number;
  amount: number;
  note: number;
  marketValue: number;
  Avg90DayVol: number;
  Avg200DayVol: number;
  tsPrevVolumevolume: number;
  currVolume: number;
  volDiff: number;
  outstandingShares: number;
  outstandingSharesPer: number;
  ownerName: number;
  transactionDate: number;
  tsPrevVolume: number;
  filer_name: number;
  series_name: number;
  shares_value: number;
  subscription_status: number;
  mfName: number;
  newlyBought: number;
  increased: number;
  decreased: number;
  soldOff: number;
  noChange: number;
  non_active_comp_per_inst: number;
  active_comp_per_inst: number;
  non_active_incr_comp_per_inst: number;
  active_incr_comp_per_inst: number;
  non_active_decr_comp_per_inst: number;
  active_decr_comp_per_inst: number;
  pos_ret_perc: number;
  neg_ret_perc: number;
  healthCare: number;
  basicMaterials: number;
  communicationServices: number;
  consumerCyclical: number;
  consumerDefensive: number;
  energy: number;
  financialServices: number;
  industrials: number;
  others: number;
  realEstate: number;
  technology: number;
  utilities: number;
  totalStocks: number;
  totalShares: number;
  num_of_weeks: number;
}
export const ColumnWidths: ColumnWidthsTypes = {
  num_of_weeks: 80,
  rank: 85,
  healthCare: 170,
  totalStocks: 80,
  totalShares: 100,
  basicMaterials: 170,
  communicationServices: 170,
  consumerCyclical: 170,
  consumerDefensive: 170,
  energy: 170,
  financialServices: 170,
  industrials: 170,
  others: 170,
  realEstate: 170,
  technology: 170,
  utilities: 170,
  non_active_comp_per_inst: 50,
  active_comp_per_inst: 55,
  non_active_incr_comp_per_inst: 55,
  active_incr_comp_per_inst: 55,
  non_active_decr_comp_per_inst: 55,
  active_decr_comp_per_inst: 55,
  pos_ret_perc: 55,
  neg_ret_perc: 55,
  balance: 85,
  newlyBought: 80,
  increased: 80,
  decreased: 80,
  soldOff: 80,
  noChange: 80,
  student_performance_rank: 82,
  subscription_status: 120,
  shares_value: 100,
  filer_name: 120,
  series_name: 120,
  school_state: 100,
  school_county: 200,
  school_name: 200,
  student_name: 150,
  invested_value: 110,
  invested_perc_returns: 100,
  invested_amount_gain: 100,
  overall_perc_returns: 100,
  current_value: 90,
  grade: 100,
  percentage_returns: 130,
  student_count: 120,
  symbol: 90,
  growthSinceRecommended: 110,
  reason: 250,
  date: 115,
  priceOnRecDate: 110,
  detail: 80,
  symbols: 120,
  sharesChange: 110,
  sector: 120,
  industry: 120,
  secForm: 80,
  secInstCount: 80,
  currentShares: 110,
  prevShares: 100,
  lastQtr1Count: 80,
  lastQtr2Count: 80,
  lastQtr3Count: 80,
  lastQtr4Count: 80,
  lastQtr5Count: 80,
  lastQtr6Count: 80,
  lastQtr7Count: 80,
  lastQtr8Count: 80,
  totalLastQtr1Shares: 100,
  totalLastQtr2Shares: 100,
  lastQtr1Shares: 100,
  lastQtr2Shares: 100,
  lastQtr3Shares: 100,
  lastQtr4Shares: 100,
  lastQtr5Shares: 100,
  lastQtr6Shares: 100,
  lastQtr7Shares: 100,
  lastQtr8Shares: 100,
  lastQtr9Shares: 100,
  lastQtr10Shares: 100,
  lastQtr11Shares: 100,
  lastQtr12Shares: 100,
  lastQtr1HedgeShares: 80,
  lastQtr1HedgeHeroShares: 80,
  sharesDiff: 80,
  totalSharesDiff: 80,
  lastQtr1HedgeCount: 80,
  lastQtr2HedgeCount: 80,
  lastQtr3HedgeCount: 80,
  lastQtr4HedgeCount: 80,
  lastQtr5HedgeCount: 80,
  lastQtr6HedgeCount: 80,
  lastQtr7HedgeCount: 80,
  lastQtr8HedgeCount: 80,
  sec13dCount: 60,
  totalInstMfShares: 80,
  lastQtr2HedgeShares: 80,
  lastQtr3HedgeShares: 80,
  lastQtr4HedgeShares: 80,
  lastQtr5HedgeShares: 80,
  lastQtr6HedgeShares: 80,
  lastQtr7HedgeShares: 80,
  lastQtr8HedgeShares: 80,
  rishiPrevShares: 100,
  rishiCurrShares: 100,
  shares: 80,
  count: 80,
  searchCount: 80,
  soldOutCount: 80,
  soldOutShares: 80,
  ytd: 80,
  oneYrGrowth: 75,
  fiveYrGrowth: 75,
  priceChangePerc: 80,
  lastQ1Allocation: 80,
  lastQ2Allocation: 80,
  lastQ3Allocation: 80,
  lastQ4Allocation: 80,
  lastQ5Allocation: 80,
  lastQ6Allocation: 80,
  lastQ7Allocation: 80,
  lastQ8Allocation: 80,
  lastQ1DivPerc: 80,
  lastQ2DivPerc: 80,
  lastQ3DivPerc: 80,
  lastQ4DivPerc: 80,
  lastQ5DivPerc: 80,
  lastQ6DivPerc: 80,
  lastQ7DivPerc: 80,
  lastQ8DivPerc: 80,
  totalDivPercLast1Yr: 80,
  totalDivPercLast2Yr: 80,
  totalDivPercLast3Yr: 80,
  totalDivPercLast4Yr: 80,
  sharesChangePer: 130,
  ownership: 80,
  price200DayChange: 80,
  price150DayChange: 80,
  price20DayChange: 80,
  price10DayChange: 80,
  price50DayChange: 80,
  price1DayChange: 80,
  price5DayChange: 80,
  price100DayChange: 80,
  insidersPerc: 80,
  instPerc: 80,
  changePerc: 100,
  allocationPerc: 90,
  allInstAlloc: 80,
  topHedgeAlloc: 80,
  instCountChange: 80,
  instSharesChange: 80,
  gainInWeeks: 80,
  lossInWeeks: 80,
  lastQtrCountChange: 80,
  lastQtrSharesChange: 80,
  changeHedgeCountStatus: 80,
  changeHedgeSharesStatus: 80,
  changeSharesStatus: 80,
  totalPaidLast1Yr: 80,
  totalPaidLast2Yr: 80,
  lastQtr1InvestedValue: 100,
  lastQtr1ApproxInvestedValue: 100,
  lastSale: 80,
  c52WkLow: 80,
  c52WkHigh: 80,
  lastQtr1HedgeInvestedValue: 100,
  lastQtr1HedgeInvested: 100,
  yearBeginPrice: 80,
  price1DayAgo: 80,
  price5DaysAgo: 80,
  price10DaysAgo: 80,
  price20DaysAgo: 80,
  price50DaysAgo: 80,
  price100DaysAgo: 80,
  price200DaysAgo: 80,
  c5YrLow: 80,
  c5YrHigh: 80,
  priceDiff: 80,
  ytdPriceChange: 80,
  tmv: 100,
  beginPrice: 80,
  endPrice: 80,
  priceChange: 80,
  lastQ1Dividend: 80,
  lastQ2Dividend: 80,
  lastQ3Dividend: 80,
  lastQ4Dividend: 80,
  lastQ5Dividend: 80,
  lastQ6Dividend: 80,
  lastQ7Dividend: 80,
  lastQ8Dividend: 80,
  totalPaidLast3Yr: 80,
  totalPaidLast4Yr: 80,
  lastQtr1Dividend: 80,
  lastQtr2Dividend: 80,
  lastQtr3Dividend: 80,
  lastQtr4Dividend: 80,
  lastQtr5Dividend: 80,
  lastQtr6Dividend: 80,
  lastQtr7Dividend: 80,
  lastQtr8Dividend: 80,
  lastQtr1Avg: 80,
  lastQtr2Avg: 80,
  lastQtr3Avg: 80,
  lastQtr4Avg: 80,
  lastQtr5Avg: 80,
  lastQtr6Avg: 80,
  lastQtr7Avg: 80,
  lastQtr8Avg: 80,
  totalInstMfShareValue: 80,
  sharesValue: 80,
  lastWkAvg1: 80,
  lastWkAvg2: 80,
  lastWkAvg3: 80,
  lastWkAvg4: 80,
  lastWkAvg5: 80,
  lastWkAvg6: 80,
  lastWkAvg7: 80,
  lastWkAvg8: 80,
  lastWkAvg9: 80,
  lastWkAvg10: 80,
  lastWkAvg11: 80,
  lastWkAvg12: 80,
  lastWkAvg13: 80,
  lastWkAvg14: 80,
  lastWkAvg15: 80,
  lastWkAvg16: 80,
  lastWkAvg17: 80,
  lastWkAvg18: 80,
  lastWkAvg19: 80,
  lastWkAvg20: 80,
  lastWkAvg21: 80,
  lastWkAvg22: 80,
  lastWkAvg23: 80,
  lastWkAvg24: 80,
  lastWkAvg25: 80,
  lastWkAvg26: 80,
  lastWkAvg27: 80,
  lastWkAvg28: 80,
  lastWkAvg29: 80,
  lastWkAvg30: 80,
  lastWkAvg31: 80,
  lastWkAvg32: 80,
  lastWkAvg33: 80,
  lastWkAvg34: 80,
  lastWkAvg35: 80,
  lastWkAvg36: 80,
  lastWkAvg37: 80,
  lastWkAvg38: 80,
  lastWkAvg39: 80,
  lastWkAvg40: 80,
  lastWkAvg41: 80,
  lastWkAvg42: 80,
  lastWkAvg43: 80,
  lastWkAvg44: 80,
  lastWkAvg45: 80,
  lastWkAvg46: 80,
  lastWkAvg47: 80,
  lastWkAvg48: 80,
  lastWkAvg49: 80,
  lastWkAvg50: 80,
  lastWkAvg51: 80,
  lastWkAvg52: 80,
  transactionPrice: 80,
  investedValue: 100,
  aum: 100,
  name: 140,
  companyName: 150,
  institutionName: 130,
  allocation: 80,
  change: 90,
  possGrowth: 80,
  forecastedClosingPrices: 400,
  status: 100,
  specialStatus: 80,
  changeStatus: 120,
  secDate: 90,
  insiderSecDate: 90,
  lastQtr1HedgeHeroStatus: 90,
  price15minAgo: 80,
  conclusion: 150,
  changeCountStatus: 120,
  insiderName: 130,
  type: 150,
  yc_price: 80,
  lastQtr9: 100,
  lastQtr8: 100,
  lastQtr7: 100,
  lastQtr6: 100,
  lastQtr5: 100,
  lastQtr4: 100,
  lastQtr3: 100,
  lastQtr2: 100,
  lastQtr1: 100,
  dateReported: 80,
  highestPriceSinceReco: 110,
  highestGrowthSinceRecommended: 110,
  highestPriceDate: 80,
  price_on_entry: 115,
  filedDate: 90,
  security: 100,
  insiderRole: 150,
  created_at: 80,
  last_modified_at: 80,
  secInsiderCount: 80,
  secInsiderBuyCount: 80,
  secInsiderSellCount: 80,
  soldOutValue: 100,
  todayReturns: 130,
  equity: 130,
  totalCost: 120,
  totalReturns: 130,
  totalReturnsPerc: 100,
  todayReturnsPerc: 84,
  sec13dNewCount: 150,
  companyInstCountStatus: 120,
  dollarChange: 80,
  dollarChangePerc: 80,
  totalOptionContracts: 80,
  lastQtr1CallContracts: 80,
  lastQtr1CallContractsRatio: 80,
  lastQtr1PutContracts: 80,
  lastQtr1ContractsRatio: 80,
  lastQtr1CallCounts: 80,
  lastQtr1CallCountsRatio: 80,
  lastQtr1PutCounts: 80,
  lastQtr1PutCountsRatio: 80,
  lastQtr1PutContractsRatio: 80,
  lastQtr1CallShrs: 80,
  lastQtr1PutShrs: 80,
  lastQtr1PutRatio: 80,
  lastQtr1CallRatio: 80,
  lastQtr1CallInstContracts: 80,
  lastQtr1CallInstCounts: 80,
  lastQtr1CallInstContractsRatio: 80,
  lastQtr1PutInstContracts: 80,
  lastQtr1PutInstCounts: 80,
  lastQtr1PutInstContractsRatio: 80,
  invoiceId: 150,
  description: 120,
  payment_card: 100,
  amount: 80,
  note: 50,
  marketValue: 100,
  Avg90DayVol: 80,
  Avg200DayVol: 80,
  tsPrevVolume: 80,
  currVolume: 80,
  volDiff: 100,
  outstandingShares: 100,
  outstandingSharesPer: 100,
  ownerName: 350,
  transactionDate: 120,
  mfName: 120,
};
