import React, { useState, useEffect, useContext } from 'react';

import BounceLoader from 'react-spinners/BounceLoader';

import { Table, Pagination } from '../../components';
import { useTheme } from '../../ThemeContext';
import {
  green,
  red,
  formatColumn,
  formatTableData,
  formatNumber2,
} from '../../utils';
import { ReportContext } from './ReportContext';
import ReportFilter from './ReportFilter';
import ReportHeader from './ReportHeader';
import styles from './style.module.scss';

const ReportsPage = () => {
  const {
    tableData = {},
    isLoading,

    changePageNo,
    changePageSize,
    pageSize,
    pageNo,

    onSort,
    tableColumns,

    reportCode,

    history,
    filterOptions,

    sortDetail,

    error,
    trendingStockType,
    selectedQuarter,
  } = useContext(ReportContext);
  const { theme } = useTheme();
  const { data = [], total, lastUpdatedTime } = tableData;
  const [pageHeight, setPageHeight] = useState<number>();

  useEffect(() => {
    setPageHeight(window.innerHeight);
    window.addEventListener('resize', (e) => {
      setTimeout(() => {
        setPageHeight(window.innerHeight);
      }, 300);
    });
  }, []);

  const onPaginationChange = (page: number, pageSize: number) => {
    changePageSize(pageSize);
    changePageNo(page);
  };

  const handleSort = (
    sortBy: string,
    sortOrder: string | null,
    multi: boolean,
  ) => {
    onSort(sortBy, sortOrder, multi);
  };
  const handleTableOnChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };
  const y = pageHeight - 290;
  return (
    <div className={styles.reportContainer} id="Reports">
      <div className={styles.reportContainerContent}>
        <ReportHeader />
        <ReportFilter lastUpdatedTime={lastUpdatedTime} />
        <div
          style={{
            minHeight: 350,
            width: reportCode === 'QTBS' ? '65%' : '',
            margin: reportCode === 'QTBS' ? 'auto' : '',
          }}
          id={reportCode}
        >
          {error && error !== 'inActive' && (
            <p style={{ color: red }}>{error}</p>
          )}
          {(!isLoading && error !== 'inActive') || (data || []).length > 0 ? (
            <Table
              loading={isLoading}
              columns={formatColumn(
                tableColumns.filter((col) => col.visible === true && col.value),
                reportCode === 'QTBS' ? false : true,
                sortDetail,
                history,
                false,
                reportCode === 'SDL'
                  ? 'SDL'
                  : reportCode === 'DIA'
                  ? 'DIA'
                  : reportCode === 'SAV'
                  ? 'SAV'
                  : '',
                reportCode === 'TS' && trendingStockType === 'ACTIVES'
                  ? 'TS_ACTIVES_REPORT'
                  : 'report',
                null,
                null,
                theme,
                null,
                null,
                filterOptions,
                selectedQuarter,
              )}
              scroll={{ y: y }}
              dataSource={formatTableData(data)}
              pagination={false}
              size="small"
              bordered={true}
              onChange={handleTableOnChange}
              rowClassName={styles.tableHeader}
              className={styles.TabularData}
              showSorterTooltip={false}
              id="allPicksId"
            />
          ) : error === 'inActive' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '20px',
              }}
            >
              {error && (
                <p style={{ color: red }}>
                  The Trending report will be available from 04.00 AM onwards
                </p>
              )}
            </div>
          ) : (
            <div className={styles.loaderStyle}>
              <BounceLoader color={green} loading={isLoading} size={150} />
            </div>
          )}
        </div>
        {reportCode !== 'QTBS' && (
          <Pagination
            pageSize={pageSize}
            total={total}
            pageNo={pageNo}
            onChange={onPaginationChange}
            loading={isLoading}
            showPagination={true}
            reportId={reportCode}
          />
        )}
        {reportCode === 'QTBS' && total > 0 && (
          <p style={{ marginLeft: 290, marginBottom: 15, marginTop: 15 }}>
            {`Showing 1 to ${total} of ${formatNumber2(total)} entries`}{' '}
          </p>
        )}
      </div>
    </div>
  );
};

export default ReportsPage;
