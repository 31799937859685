/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateUserAddlInfo = /* GraphQL */ `
  mutation UpdateUserAddlInfo($email: String, $data: UserAddlInfoInput) {
    updateUserAddlInfo(email: $email, data: $data) {
      id
      mobile
      gender
      marital_status
      country
      profession
      birth_year
      qualification
      race
      image_url
      telegram_id
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      first_name
      last_name
      email
      message
      type
      additionalInfo {
        id
        mobile
        gender
        marital_status
        country
        profession
        birth_year
        qualification
        race
        image_url
        telegram_id
      }
      studentInfo {
        id
        school_state
        school_name
        grade
        graduation_date
        birth_year
        student_id
        student_picture_id
        school_county
        school_email
      }
      alerts {
        all_symbols
        all_fav_symbols
        top_10_hedge
        all_fav_institutions
      }
      favCompanies {
        id
        company {
          cik
          industry
          ipo_year
          market_cap
          name
          sec_company_name
          sector
          symbol
        }
      }
      favInstitutions {
        id
        institution {
          cik
          id
          name
          nasuid
          rank
        }
      }
      financialInfo {
        expected_return_range
        experiences
        id
        income_range
        investment_exp
        investment_range
        lost_money
        has_portfolio
      }
      portfolio {
        avg_cost
        id
        no_of_shares
        company {
          cik
          industry
          ipo_year
          market_cap
          name
          sec_company_name
          sector
          symbol
        }
      }
      recentSearches {
        id
        search_criteria
        title
        displayText
        created_at
      }
      savedSearches {
        id
        search_criteria
        title
        displayText
        name
        created_at
      }
      stripeCustomer {
        stripe_customer_id
        stripe_subscription_id
        stripe_price_id
        stripe_payment_method_id
        interval
        subscription_status
        is_cancelled
        current_period_end
      }
      userBillingAddress {
        id
        address
        city
        state
        country
        zip
      }
      wallet {
        type
        name
        remainingBalance
        investedValue
        currentValue
        yd_value
      }
    }
  }
`;
export const addOurPicks = /* GraphQL */ `
  mutation AddOurPicks($request: [OurPicksCreateRequest]) {
    addOurPicks(request: $request) {
      id
    }
  }
`;
export const makeTransaction = /* GraphQL */ `
  mutation MakeTransaction($request: TransactionRequest) {
    makeTransaction(request: $request) {
      status
    }
  }
`;
export const addMonthlyPicks = /* GraphQL */ `
  mutation AddMonthlyPicks($request: [MonthlyPicksRequest]) {
    addMonthlyPicks(request: $request) {
      status
    }
  }
`;
export const post = /* GraphQL */ `
  mutation Post($request: TSAlertInput) {
    post(request: $request) {
      title
      body
      createdAt
      read
      email
      type
    }
  }
`;
export const chatCustomMessage = /* GraphQL */ `
  mutation ChatCustomMessage($request: CustomAlertInput) {
    chatCustomMessage(request: $request) {
      title
      body
      createdAt
    }
  }
`;
export const markRead = /* GraphQL */ `
  mutation MarkRead($request: MarkReadOptions) {
    markRead(request: $request) {
      status
    }
  }
`;
export const postGlobalAlerts = /* GraphQL */ `
  mutation PostGlobalAlerts($request: [TSAlertInput]) {
    postGlobalAlerts(request: $request) {
      status
    }
  }
`;
export const createAlert = /* GraphQL */ `
  mutation CreateAlert($request: CreateAlertRequest) {
    createAlert(request: $request) {
      status
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert($request: CreateAlertRequest) {
    updateAlert(request: $request) {
      status
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert($request: DeleteAlertRequest) {
    deleteAlert(request: $request) {
      status
    }
  }
`;
export const deleteOurPicks = /* GraphQL */ `
  mutation DeleteOurPicks($request: [OurPicksDeleteRequest]) {
    deleteOurPicks(request: $request) {
      cik
      industry
      ipo_year
      market_cap
      name
      sec_company_name
      sector
      symbol
    }
  }
`;
export const editOurPicks = /* GraphQL */ `
  mutation EditOurPicks($request: [OurPicksEditRequest]) {
    editOurPicks(request: $request) {
      id
    }
  }
`;
export const updateSsmParameter = /* GraphQL */ `
  mutation UpdateSsmParameter($request: [SSMParameterCreateRequest]) {
    updateSSMParameter(request: $request)
  }
`;
export const handleStockSplit = /* GraphQL */ `
  mutation HandleStockSplit($request: StockSplit) {
    handleStockSplit(request: $request) {
      symbol
      success
      message
    }
  }
`;
export const changeSymbol = /* GraphQL */ `
  mutation ChangeSymbol($request: ChangeSymbolRequest) {
    changeSymbol(request: $request) {
      success
      message
    }
  }
`;
export const addCompany = /* GraphQL */ `
  mutation AddCompany($request: AddCompanyRequest) {
    addCompany(request: $request) {
      cik
      industry
      ipo_year
      market_cap
      name
      sec_company_name
      sector
      symbol
    }
  }
`;
export const getCompany = /* GraphQL */ `
  mutation GetCompany($request: GetCompanyRequest) {
    getCompany(request: $request) {
      cik
      cusip
      industry
      ipo_year
      market_cap
      name
      sec_company_name
      sector
      symbol
      id
      description
    }
  }
`;
export const editCompany = /* GraphQL */ `
  mutation EditCompany($request: EditCompanyRequest) {
    editCompany(request: $request) {
      cik
      industry
      ipo_year
      market_cap
      name
      sec_company_name
      sector
      symbol
    }
  }
`;
export const deleteCompanyFromEs = /* GraphQL */ `
  mutation DeleteCompanyFromEs($request: EditCompanyRequest) {
    deleteCompanyFromES(request: $request) {
      cik
      industry
      ipo_year
      market_cap
      name
      sec_company_name
      sector
      symbol
    }
  }
`;
export const addCompanyToEs = /* GraphQL */ `
  mutation AddCompanyToEs($request: EditCompanyRequest) {
    addCompanyToES(request: $request) {
      cik
      industry
      ipo_year
      market_cap
      name
      sec_company_name
      sector
      symbol
    }
  }
`;
export const addInstitution = /* GraphQL */ `
  mutation AddInstitution($request: AddInstitutionRequest) {
    addInstitution(request: $request) {
      cik
      id
      name
      nasuid
      rank
    }
  }
`;
export const getInstitution = /* GraphQL */ `
  mutation GetInstitution($request: GetInstitutionRequest) {
    getInstitution(request: $request) {
      cik
      id
      name
      nasuid
      rank
    }
  }
`;
export const deleteInstitutionFromEs = /* GraphQL */ `
  mutation DeleteInstitutionFromEs($request: EditInstitutionRequest) {
    deleteInstitutionFromES(request: $request) {
      cik
      id
      name
      nasuid
      rank
    }
  }
`;
export const addInstitutionToEs = /* GraphQL */ `
  mutation AddInstitutionToEs($request: EditInstitutionRequest) {
    addInstitutionToES(request: $request) {
      cik
      id
      name
      nasuid
      rank
    }
  }
`;
export const editInstitution = /* GraphQL */ `
  mutation EditInstitution($request: EditInstitutionRequest) {
    editInstitution(request: $request) {
      cik
      id
      name
      nasuid
      rank
    }
  }
`;
export const addSc13Dg = /* GraphQL */ `
  mutation AddSc13Dg($request: AddSec13DGRequest) {
    addSC13DG(request: $request) {
      cik_code
      accepted_date
      form
      cusip
      new_shares
      previous_shares
      ownership_percentage
      date_filed
      sec_filing_date
      sec_statement_date
      sec_statement_time
      institution_name
      company_cik
      company_name
      link
      sec_reported_date
      sec_reported_time
      id
    }
  }
`;
export const getSc13Dg = /* GraphQL */ `
  mutation GetSc13Dg($request: GetSec13DGRequest) {
    getSC13DG(request: $request) {
      cik_code
      accepted_date
      form
      cusip
      new_shares
      previous_shares
      ownership_percentage
      date_filed
      sec_filing_date
      sec_statement_date
      sec_statement_time
      institution_name
      company_cik
      company_name
      link
      sec_reported_date
      sec_reported_time
      id
    }
  }
`;
export const editSc13Dg = /* GraphQL */ `
  mutation EditSc13Dg($request: EditSec13DGRequest) {
    editSC13DG(request: $request) {
      success
      message
    }
  }
`;
export const deleteSc13Dg = /* GraphQL */ `
  mutation DeleteSc13Dg($request: DeleteSec13DGRequest) {
    deleteSC13DG(request: $request) {
      success
      message
    }
  }
`;
export const addQuarterly13F = /* GraphQL */ `
  mutation AddQuarterly13F($request: AddQuarterly13FRequest) {
    addQuarterly13F(request: $request) {
      status
      message
    }
  }
`;
export const syncCompanyDataInEs = /* GraphQL */ `
  mutation SyncCompanyDataInEs {
    syncCompanyDataInES
  }
`;
export const syncInstitutionDataInEs = /* GraphQL */ `
  mutation SyncInstitutionDataInEs {
    syncInstitutionDataInES
  }
`;
export const get52WkHighLow = /* GraphQL */ `
  mutation Get52WkHighLow($request: Get52WkHighLowRequest) {
    get52WkHighLow(request: $request) {
      id
      high
      low
      ytd_begin_price
      ytd_begin_date
    }
  }
`;
export const edit52WkHighLow = /* GraphQL */ `
  mutation Edit52WkHighLow($request: EditFiftyTwoWkHighLowRequest) {
    edit52WkHighLow(request: $request) {
      id
      high
      low
      ytd_begin_price
      ytd_begin_date
    }
  }
`;
export const updateDailyPriceForSymbol = /* GraphQL */ `
  mutation UpdateDailyPriceForSymbol($request: EditDailyPricesRequest) {
    updateDailyPriceForSymbol(request: $request) {
      message
    }
  }
`;

export const unsubscribeMails = /* GraphQL */ `
  mutation UnsubscribeMails($request: UnsubscribeMailsRequest) {
    unsubscribeMails(request: $request) {
      unSubscribed
    }
  }
`;
export const unsubscribePreference = /* GraphQL */ `
  mutation UnsubscribePreference($request: UnsubscribePreferenceRequest) {
    unsubscribePreference(request: $request) {
      ts_subscribed
      sec_subscribed
      ia_subscribed
      pa_subscribed
      wg_subscribed
      mail_subscribed
      inst_subscribed
      mc_subscribed
      __typename
    }
  }
`;

export const addTsWatchlistSymbol = /* GraphQL */ `
  mutation AddTsWatchlistSymbol($request: addTsWatchlistSymbolRequest) {
    addTsWatchlistSymbol(request: $request) {
      symbolAdded
    }
  }
`;

export const isReferral = /* GraphQL */ `
  mutation IsReferral($request: IsReferralRequest) {
    isReferral(request: $request) {
      referral_to_claimed
      creditBalance
    }
  }
`;

export const isPromo = /* GraphQL */ `
  mutation IsPromo($request: IsPromoRequest) {
    isPromo(request: $request) {
      isPromo
      __typename
    }
  }
`;
export const monthlyStripeTransaction = /* GraphQL */ `
  mutation MonthlyStripeTransaction($request: MonthlyStripeTransactionRequest) {
    monthlyStripeTransaction(request: $request) {
      txnSuccess
    }
  }
`;
