import React from 'react';

// import { SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
// import { SyncOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';

import { formatNumber2, currencyFormat } from '../../utils';
import styles from './styles.module.scss';

interface Props {
  filterOptions: Object;
  handleReset: Function;
  handleClick: Function;
}

const FilteredValues: React.FC<Props> = ({
  filterOptions,
  handleReset,
  handleClick,
}) => {
  const {
    maxPrice,
    min1Yr,
    min5Yr,
    minInstCount,
    minPrice,
    minYTD,
    sector,
    symbols,
    minSec13dCount,
    minTotalStocks,
    minPositiveStocks,
    minNegativeStocks,
    minInvestedAmt,
    maxInvestedAmt,
    minInvestedAmtReturns,
    minInvestedReturnsPerc,
    minTotalPFvalue,
    minTotalPFReturnsPerc,
    maxInvestedAmtReturns,
    maxInvestedReturnsPerc,
    maxTotalPFvalue,
    maxTotalPFReturnsPerc,
    institutions,
  } = filterOptions;

  const data = [
    {
      label: 'Min Invst Amt',
      value: minInvestedAmt,
      type: 'string',
      name: 'minInvestedAmt',
    },
    {
      label: 'Max Invst Amt',
      value: maxInvestedAmt,
      type: 'string',
      name: 'maxInvestedAmt',
    },
    {
      label: 'Min Invested Amt Returns',
      value: minInvestedAmtReturns,
      type: 'string',
      name: 'minInvestedAmtReturns',
    },
    {
      label: 'Min Invested Returns Perc',
      value: minInvestedReturnsPerc,
      type: 'string',
      name: 'minInvestedReturnsPerc',
    },
    {
      label: 'Min Total PF value',
      value: minTotalPFvalue,
      type: 'string',
      name: 'minTotalPFvalue',
    },
    {
      label: 'Min Total PF Returns Perc',
      value: minTotalPFReturnsPerc,
      type: 'string',
      name: 'minTotalPFReturnsPerc',
    },
    {
      label: 'Max Invested Amt Returns',
      value: maxInvestedAmtReturns,
      type: 'string',
      name: 'maxInvestedAmtReturns',
    },
    {
      label: 'Max Invested Returns Perc',
      value: maxInvestedReturnsPerc,
      type: 'string',
      name: 'maxInvestedReturnsPerc',
    },
    {
      label: 'Max Total PF value',
      value: maxTotalPFvalue,
      type: 'string',
      name: 'maxTotalPFvalue',
    },
    {
      label: 'Max Total PF Returns Perc',
      value: maxTotalPFReturnsPerc,
      type: 'string',
      name: 'maxTotalPFReturnsPerc',
    },
    {
      label: 'Sector',
      value: sector,
      type: 'string',
      name: 'sector',
    },
    {
      label: 'Min Price',
      value: minPrice,
      type: 'currency',
      name: 'minPrice',
    },
    {
      label: 'Max Price',
      value: maxPrice,
      type: 'currency',
      name: 'maxPrice',
    },
    {
      label: 'Min YTD',
      value: minYTD,
      type: 'percent',
      name: 'minYTD',
    },
    {
      label: 'Min 1yr Growth',
      value: min1Yr,
      type: 'percent',
      name: 'min1Yr',
    },

    {
      label: 'Min 5yr Growth',
      value: min5Yr,
      type: 'percent',
      name: 'min5Yr',
    },
    {
      label: 'Min Inst count',
      value: minInstCount,
      type: 'string',
      name: 'minInstCount',
    },
    {
      label: 'Min SEC 13D/G Count',
      value: minSec13dCount,
      type: 'string',
      name: 'minSec13dCount',
    },
    {
      label: 'Min Total Stocks',
      value: minTotalStocks,
      type: 'string',
      name: 'minTotalStocks',
    },
    {
      label: 'Min Positive Stocks',
      value: minPositiveStocks,
      type: 'string',
      name: 'minPositiveStocks',
    },
    {
      label: 'Min Negative Stocks',
      value: minNegativeStocks,
      type: 'string',
      name: 'minNegativeStocks',
    },
  ];
  return (
    <>
      FILTERS:
      <Tag
        onClick={handleReset}
        color="red"
        style={{ margin: 5 }}
        className={styles.vClearAllNew}
      >
        CLEAR ALL
      </Tag>
      {symbols &&
        (Array.isArray(symbols) ? (
          symbols.map((symbol) => (
            <Tag
              key={symbol}
              closable
              onClose={() => handleClick('symbol', symbol)}
              // icon={
              //   isLoading ? (
              //     <SyncOutlined spin />
              //   ) : (
              //     <CloseCircleOutlined
              //       onClick={() => handleClick('symbol', symbol)}
              //     />
              //   )
              // }
              color={'success'}
            >
              {symbol}
            </Tag>
          ))
        ) : (
          <Tag
            key={symbols}
            // icon={isLoading ? <SyncOutlined spin /> : <CheckCircleOutlined />}
            color={'success'}
          >
            {symbols}
          </Tag>
        ))}
      {institutions &&
        (Array.isArray(institutions) ? (
          institutions.map((ins) => (
            <Tag
              key={ins}
              closable
              onClose={() => handleClick('institutions', ins)}
              color={'success'}
            >
              {ins}
            </Tag>
          ))
        ) : (
          <Tag
            key={institutions}
            color={'success'}
            closable
            onClose={() => handleClick('institutions', institutions)}
          >
            {institutions}
          </Tag>
        ))}
      {/* eslint-disable-next-line */}
      {data.map((item) => {
        const { name, value, type, label } = item;
        if (value || value === 0) {
          switch (type) {
            case 'currency':
              return (
                <Tag
                  // icon={
                  //   isLoading ? (
                  //     <SyncOutlined spin />
                  //   ) : (
                  //     <CloseCircleOutlined
                  //       onClick={() => handleClick(name, value)}
                  //     />
                  //   )
                  // }
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${currencyFormat(value, false, false)}`}</Tag>
              );
            case 'percent':
              return (
                <Tag
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${formatNumber2(value)}%`}</Tag>
              );
            case 'bool':
              return (
                <Tag
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${value ? 'Yes' : 'No'}`}</Tag>
              );
            case 'string':
              return (
                <Tag
                  closable
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${value}`}</Tag>
              );
          }
        }
      })}
    </>
  );
};

export default FilteredValues;
