import React, { useContext } from 'react';

import { Tag } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { formatNumber2, currencyFormat } from '../../utils';
import { NonClickAbleFilter, filtersNotToShow } from './SearchReport.constants';
import { SearchReportContext } from './SearchReportContext';
import styles from './style.module.scss';
const CommonHeader = ({
  handleClick,

  handleReset,
}) => {
  const { filterOptions, reportType, reportSubType } =
    useContext(SearchReportContext);
  const {
    minPrice,
    maxPrice,
    minEntryPrice,
    maxEntryPrice,
    minStartPrice,
    maxStartPrice,
    minEndPrice,
    maxEndPrice,
    sector,
    symbols,
    ytd,
    oneYrGrowth,
    fiveYrGrowth,
    instCountChange,
    instCount,
    minCount,
    maxCount,
    minAllocatedPerc,
    maxAllocatedPerc,
    instSharesChange,
    ownership,
    minPerReturn,
    minSec13dCount,
    multiSector,
    excludeMultiSector,
    multiIndustry,
    insiderName,
    insiderTitle,
    isTenPercent,
    multiStatus,
    multiMarketCap,
    minOneDayChange,
    maxOneDayChange,
    minGain,
    maxGain,
    minLoss,
    maxLoss,
    minDayGain,
    maxDayGain,
    minDayLoss,
    maxDayLoss,
    week,
    minSecInstCount,
    minSecInsiderCount,
    date,
    minSharesChange,
    minTotalSharesChange,
    maxTotalSharesChange,
    minVolumeChange,
    maxVolumeChange,
    minAvgChange,
    maxAvgChange,
    minInstSharesChange,
    minPerChange,
    maxPerChange,
    minPercentage,
    maxPercentage,
    maxSharesChange,
    minSharesChangePer,
    startDate,
    fromDate,
    minVolDiff,
    maxVolDiff,
    minGrowth,
    maxCountChange,
    minCountChange,
    minInstCountChange,
    maxPossibleGrowth,
    minPossibleGrowth,
    minRank,
    maxRank,
    filerName,
    seriesName,
    // type,
    endDate,
    minOwnership,
    country,
    minPrevQInstCount,
    maxPrevQInstCount,
    minAum,
    maxAum,
    minPosReturnsPerc,
    maxPosReturnsPerc,
    minNegReturnsPerc,
    maxNegReturnsPerc,
    minNegReturnsStockCount,
    maxNegReturnsStockCount,
    minPosReturnsStockCount,
    maxPosReturnsStockCount,
    minTotalCount,
    maxTotalCount,
    minInstShares,
    maxInstShares,
    minAproxTxnValue,
    minAproxInvValue,
    maxAproxTxnValue,
    minPrevAum,
    maxPrevAum,
    hedgeCount,
    minMarketCap,
    maxMarketCap,
    institutions,
    minInsiderTransactionValue,
    maxInsiderTransactionValue,
    entryStartDate,
    entryEndDate,
    minTop10HedgeFundsIn_NC_NB_CNT,
    maxTop10HedgeFundsIn_NC_NB_CNT,
    minTop10InstIn_NC_NB_CNT,
    maxTop10InstIn_NC_NB_CNT,
    noOfWeeklyYTDWeeks,
    minLatestWeekPer,
    maxLatestWeekPer,
    queryDate,
  } = filterOptions;

  const data = [
    {
      label: 'Min Ownership',
      name: 'minOwnership',
      value: get(ownership, 'min', null),
      type: 'percent',
    },
    // {
    //   name: 'Status',
    //   value: status,
    //   type: 'string',
    // },
    {
      label: 'Status',
      value: multiStatus,
      type: 'multi',
      name: 'multiStatus',
    },
    {
      label: 'Country',
      value: country,
      type: 'multi',
      name: 'country',
    },

    {
      label: 'Market Cap',
      value: multiMarketCap,
      type: 'multi',
      name: 'multiMarketCap',
    },

    // {
    //   name: 'Criterion',
    //   value: criterion,
    //   type: 'string',
    // },
    // {
    //   name: 'No of Quarters',
    //   value: noOfQuarters,
    //   type: 'string',
    // },
    // {
    //   name: 'Min Market Cap',
    //   value: minMarketCap,
    //   type: 'string',
    // },
    // {
    //   name: 'Min Volume',
    //   value: minVolume,
    //   type: 'string',
    // },
    // {
    //   name: 'Min Possible Growth',
    //   value: minPossibleGrowth,
    //   type: 'string',
    // },
    {
      label: 'Sector',
      value: sector,
      type: 'string',
      name: 'sector',
    },
    {
      label: 'Insider',
      value: insiderName,
      type: 'string',
      name: 'insiderName',
    },
    {
      label: 'Insider Title',
      value: insiderTitle,
      type: 'string',
      name: 'insiderTitle',
    },
    {
      label: 'Is 10% Owner',
      value: isTenPercent,
      type: 'string',
      name: 'isTenPercent',
    },
    {
      label: 'Sector',
      value: multiSector,
      type: 'multi',
      name: 'multiSector',
    },
    {
      label: 'Fund Name',
      value: filerName,
      type: 'multi',
      name: 'filerName',
    },
    {
      label: 'Series Name',
      value: seriesName,
      type: 'multi',
      name: 'seriesName',
    },
    {
      label: 'Exclude Sector',
      value: excludeMultiSector,
      type: 'multi',
      name: 'excludeMultiSector',
    },
    {
      label: 'Industry',
      value: multiIndustry,
      type: 'multi',
      name: 'multiIndustry',
    },
    {
      label: 'Min 1 Day Change',
      value: minOneDayChange,
      type: 'percent',
      name: 'minOneDayChange',
    },
    {
      label: 'Max 1 Day Change',
      value: maxOneDayChange,
      type: 'percent',
      name: 'maxOneDayChange',
    },
    {
      label:
        reportSubType === 'INSTITUTION_PERFORMANCE_REPORT'
          ? 'Min AUM Change %'
          : 'Min % Gain',
      value: minGain,
      type: 'percent',
      name: 'minGain',
    },
    {
      label:
        reportSubType === 'INSTITUTION_PERFORMANCE_REPORT'
          ? 'Max AUM Change %'
          : 'Max % Gain',
      value: maxGain,
      type: 'percent',
      name: 'maxGain',
    },
    {
      label: 'Min % Loss',
      value: minLoss,
      type: 'percent',
      name: 'minLoss',
    },
    {
      label: 'Max % Loss',
      value: maxLoss,
      type: 'percent',
      name: 'maxLoss',
    },
    {
      label: 'Min % Gain',
      value: minDayGain,
      type: 'percent',
      name: 'minDayGain',
    },
    {
      label: 'Max % Gain',
      value: maxDayGain,
      type: 'percent',
      name: 'maxDayGain',
    },
    {
      label: 'Min % Loss',
      value: minDayLoss,
      type: 'percent',
      name: 'minDayLoss',
    },
    {
      label: 'Max % Loss',
      value: maxDayLoss,
      type: 'percent',
      name: 'maxDayLoss',
    },
    {
      label: 'Min % Return',
      value: minPerReturn,
      type: 'percent',
      name: 'minPerReturn',
    },
    {
      label: 'Week',
      value: week,
      type: 'string',
      name: 'week',
    },
    {
      label: 'Min -ve Returns Stock Count',
      value: minNegReturnsStockCount,
      type: 'string',
      name: 'minNegReturnsStockCount',
    },
    {
      label: 'Max -ve Returns Stock Count',
      value: maxNegReturnsStockCount,
      type: 'string',
      name: 'maxNegReturnsStockCount',
    },
    {
      label: 'Min Total Count',
      value: minTotalCount,
      type: 'string',
      name: 'minTotalCount',
    },
    {
      label: 'Max Total Count',
      value: maxTotalCount,
      type: 'string',
      name: 'maxTotalCount',
    },
    {
      label: 'Min -ve Returns %',
      value: minNegReturnsPerc,
      type: 'percent',
      name: 'minNegReturnsPerc',
    },
    {
      label: 'Max -ve Returns %',
      value: maxNegReturnsPerc,
      type: 'percent',
      name: 'maxNegReturnsPerc',
    },
    {
      label: 'Min +ve Returns %',
      value: minPosReturnsPerc,
      type: 'percent',
      name: 'minPosReturnsPerc',
    },
    {
      label: 'Max +ve Returns %',
      value: maxPosReturnsPerc,
      type: 'percent',
      name: 'maxPosReturnsPerc',
    },
    {
      label: 'Min +ve Returns Stock Count',
      value: minPosReturnsStockCount,
      type: 'string',
      name: 'minPosReturnsStockCount',
    },
    {
      label: 'Max +ve Returns Stock Count',
      value: maxPosReturnsStockCount,
      type: 'string',
      name: 'maxPosReturnsStockCount',
    },
    {
      label: 'Min Sec Ins Count',
      value: minSecInstCount,
      type: 'string',
      name: 'minSecInstCount',
    },
    {
      label: 'Min Sec Insider Count',
      value: minSecInsiderCount,
      type: 'string',
      name: 'minSecInsiderCount',
    },

    {
      label: 'Min SEC 13D/G Count',
      value: minSec13dCount,
      type: 'string',
      name: 'minSec13dCount',
    },
    {
      label: 'Min Price',
      value: minPrice,
      type: 'currency',
      name: 'minPrice',
    },
    {
      label: 'Max Price',
      value: maxPrice,
      type: 'currency',
      name: 'maxPrice',
    },
    {
      label: 'Min Entry Price',
      value: minEntryPrice,
      type: 'currency',
      name: 'minEntryPrice',
    },
    {
      label: 'Max Entry Price',
      value: maxEntryPrice,
      type: 'currency',
      name: 'maxEntryPrice',
    },

    {
      label: 'Date',
      value: date,
      type: 'date',
      name: 'date',
    },
    {
      label: 'Min % Own',
      value: minOwnership,
      type: 'percent',
      name: 'minOwnership',
    },
    {
      label: 'Min YTD',
      value: get(ytd, 'min', null),
      type: 'percent',
      name: 'minYTD',
    },
    {
      label: 'Min 1yr Growth',
      value: get(oneYrGrowth, 'min', null),
      type: 'percent',
      name: 'min1Yr',
    },

    {
      label: 'Min 5yr Growth',
      value: get(fiveYrGrowth, 'min', null),
      type: 'percent',
      name: 'min5Yr',
    },
    {
      label: 'Min % count Change',
      value: get(instCountChange, 'min', null),
      type: 'percent',
      name: 'minCountChange',
    },
    {
      label: 'Max % count Change',
      value: get(instCountChange, 'max', null),
      type: 'percent',
      name: 'maxCountChange',
    },
    {
      label: 'Min Inst count',
      value: get(instCount, 'min', null),
      type: 'string',
      name: 'minInstCount',
    },
    {
      label: 'Max Inst Count',
      value: get(instCount, 'max', null),
      type: 'string',
      name: 'maxInstCount',
    },
    {
      label: 'Min count',
      value: minCount,
      type: 'string',
      name: 'minCount',
    },
    {
      label: 'Max Count',
      value: maxCount,
      type: 'string',
      name: 'maxCount',
    },
    {
      label: 'Min Allocated %',
      value: minAllocatedPerc,
      type: 'string',
      name: 'minAllocatedPerc',
    },
    {
      label: 'Max Allocated %',
      value: maxAllocatedPerc,
      type: 'string',
      name: 'maxAllocatedPerc',
    },
    {
      label: 'Min Prev Q Inst count',
      value: minPrevQInstCount,
      type: 'string',
      name: 'minPrevQInstCount',
    },
    {
      label: 'Max Prev Q Inst Count',
      value: maxPrevQInstCount,
      type: 'string',
      name: 'maxPrevQInstCount',
    },
    {
      label: 'Min Inst Shares',
      value: minInstShares
        ? currencyFormat(minInstShares, false, false, 2, false, true)
        : minInstShares,
      type: 'string',
      name: 'minInstShares',
    },
    {
      label: 'Max Inst Shares',
      value: maxInstShares
        ? currencyFormat(maxInstShares, false, false, 2, false, true)
        : maxInstShares,
      type: 'string',
      name: 'maxInstShares',
    },
    {
      label: 'Min Approx. trxn value',
      value: minAproxTxnValue
        ? currencyFormat(minAproxTxnValue, false, false, 2, false, true)
        : minAproxTxnValue,
      type: 'string',
      name: 'minAproxTxnValue',
    },
    {
      label: 'Min Approx. Inv value',
      value: minAproxInvValue
        ? currencyFormat(minAproxInvValue, false, false, 2, false, true)
        : minAproxInvValue,
      type: 'string',
      name: 'minAproxInvValue',
    },
    {
      label: 'Max Approx. trxn value',
      value: maxAproxTxnValue
        ? currencyFormat(maxAproxTxnValue, false, false, 2, false, true)
        : maxAproxTxnValue,
      type: 'string',
      name: 'maxAproxTxnValue',
    },
    {
      label: 'Min AUM',
      value: minAum
        ? currencyFormat(minAum, false, false, 2, false, true)
        : minAum,
      type: 'string',
      name: 'minAum',
    },
    {
      label: 'Max AUM',
      value: maxAum
        ? currencyFormat(maxAum, false, false, 2, false, true)
        : maxAum,
      type: 'string',
      name: 'maxAum',
    },
    {
      label: 'Min Prev Qtr AUM',
      value: minPrevAum
        ? currencyFormat(minPrevAum, false, false, 2, false, true)
        : minPrevAum,
      type: 'string',
      name: 'minPrevAum',
    },
    {
      label: 'Max Prev Qtr AUM',
      value: maxPrevAum
        ? currencyFormat(maxPrevAum, false, false, 2, false, true)
        : maxPrevAum,
      type: 'string',
      name: 'maxPrevAum',
    },
    {
      label: 'Hedge Count',
      value: hedgeCount,
      type: 'string',
      name: 'hedgeCount',
    },
    {
      label: 'Min Rank',
      value: minRank,
      type: 'string',
      name: 'minRank',
    },
    {
      label: 'Max Rank',
      value: maxRank,
      type: 'string',
      name: 'maxRank',
    },
    {
      label: 'Min MarketCap',
      value: minMarketCap,
      type: 'string',
      name: 'minMarketCap',
    },
    {
      label: 'Max MarketCap',
      value: maxMarketCap,
      type: 'string',
      name: 'maxMarketCap',
    },
    // {
    //   label: '#Qtrs',
    //   value: noOfQuarters,
    //   type: 'string',
    //   name: 'noOfQuarters',
    // },
    // {
    //   label: 'Criterion',
    //   value: criterion,
    //   type: 'string',
    //   name: 'criterion',
    // },
    // {
    //   label: 'Status',
    //   value: status,
    //   type: 'string',
    //   name: 'status',
    // },
    // {
    //   label: 'Status',
    //   value: multiStatus,
    //   type: 'multi',
    //   name: 'multiStatus',
    // },

    {
      label: 'Min Shares % Change',
      value: minSharesChangePer
        ? minSharesChangePer
        : get(instSharesChange, 'min', null)
        ? get(instSharesChange, 'min', null)
        : undefined,
      type: 'percent',
      name: 'minSharesChangePer',
    },

    {
      label: 'Min % Change',
      value: minPerChange,
      type: 'percent',
      name: 'minPerChange',
    },
    {
      label: 'Max % Change',
      value: maxPerChange,
      type: 'percent',
      name: 'maxPerChange',
    },
    {
      label: 'Min % of Allocation',
      value: minPercentage,
      type: 'percent',
      name: 'minPercentage',
    },
    {
      label: 'Max % of Allocation',
      value: maxPercentage,
      type: 'percent',
      name: 'maxPercentage',
    },
    {
      label: 'Min % Count Change',
      value: minCountChange
        ? minCountChange
        : minInstCountChange
        ? minInstCountChange
        : undefined,
      type: 'percent',
      name: 'minCountChange',
    },
    {
      label: 'Max % Count Change',
      value: maxCountChange,
      type: 'percent',
      name: 'maxCountChange',
    },
    {
      label: 'Max % Possible Growth',
      value: maxPossibleGrowth,
      type: 'percent',
      name: 'maxPossibleGrowth',
    },
    {
      label: 'Fund Name',
      value: filerName,
      type: 'fundName',
      name: 'filerName',
    },
    {
      label: 'Series Name',
      value: seriesName,
      type: 'seriesName',
      name: 'seriesName',
    },
    {
      label: 'Min % Possible Growth',
      value: minPossibleGrowth,
      type: 'percent',
      name: 'minPossibleGrowth',
    },
    {
      label: 'Min % Shares Change',
      value: minSharesChange
        ? minSharesChange
        : minInstSharesChange
        ? minInstSharesChange
        : undefined,
      type: 'percent',
      name: 'minSharesChange',
    },
    {
      label: 'Min % Total Shares Change',
      value: minTotalSharesChange,
      type: 'percent',
      name: 'minTotalSharesChange',
    },
    {
      label: 'Max % Total Shares Change',
      value: maxTotalSharesChange,
      type: 'percent',
      name: 'maxTotalSharesChange',
    },
    {
      label: 'Min % Avg Volume Change',
      value: minVolumeChange,
      type: 'percent',
      name: 'minVolumeChange',
    },
    {
      label: 'Max % Avg Volume Change',
      value: maxVolumeChange,
      type: 'percent',
      name: 'maxVolumeChange',
    },
    {
      label: 'Min % Avg Price Change',
      value: minAvgChange,
      type: 'percent',
      name: 'minAvgChange',
    },
    {
      label: 'Max % Avg Price Change',
      value: maxAvgChange,
      type: 'percent',
      name: 'maxAvgChange',
    },
    {
      label: 'Min Top-10 HF NB/INCR/NC',
      value: minTop10HedgeFundsIn_NC_NB_CNT,
      type: 'string',
      name: 'minTop10HedgeFundsIn_NC_NB_CNT',
    },
    {
      label: 'Max Top-10 HF NB/INCR/NC',
      value: maxTop10HedgeFundsIn_NC_NB_CNT,
      type: 'string',
      name: 'maxTop10HedgeFundsIn_NC_NB_CNT',
    },
    {
      label: 'Min Top-10 Inst NB/INCR/NC',
      value: minTop10InstIn_NC_NB_CNT,
      type: 'string',
      name: 'minTop10InstIn_NC_NB_CNT',
    },
    {
      label: 'Max Top-10 Inst NB/INCR/NC',
      value: maxTop10InstIn_NC_NB_CNT,
      type: 'string',
      name: 'maxTop10InstIn_NC_NB_CNT',
    },
    {
      label: 'Max % Shares Change',
      value: maxSharesChange,
      type: 'percent',
      name: 'maxSharesChange',
    },
    // {
    //   label: 'Type',
    //   value: type,
    //   type: 'string',
    //   name: 'type',
    // },
    {
      label: 'Min Growth',
      value: minGrowth,
      type: 'percent',
      name: 'minGrowth',
    },
    {
      label: 'Start Date',
      value: startDate,
      type: 'date',
      name: 'startDate',
    },
    {
      label: 'From Date',
      value: fromDate,
      type: 'date',
      name: 'fromDate',
    },
    {
      label: 'End Date',
      value: endDate,
      type: 'date',
      name: 'endDate',
    },
    {
      label: 'Entry Start Date',
      value: entryStartDate,
      type: 'date',
      name: 'entryStartDate',
    },
    {
      label: 'Entry End Date',
      value: entryEndDate,
      type: 'date',
      name: 'entryEndDate',
    },
    {
      label: 'Min Start Price',
      value: minStartPrice,
      type: 'currency',
      name: 'minStartPrice',
    },
    {
      label: 'Max Start Price',
      value: maxStartPrice,
      type: 'currency',
      name: 'maxStartPrice',
    },
    {
      label: 'Min End Price',
      value: minEndPrice,
      type: 'currency',
      name: 'minEndPrice',
    },
    {
      label: 'Max End Price',
      value: maxEndPrice,
      type: 'currency',
      name: 'maxEndPrice',
    },
    {
      label: 'Min Vol Diff %',
      value: minVolDiff,
      type: 'percent',
      name: 'minVolDiff',
    },
    {
      label: 'Max Vol Diff %',
      value: maxVolDiff,
      type: 'percent',
      name: 'maxVolDiff',
    },
    {
      label: 'Min Latest Week Per Change',
      value: minLatestWeekPer,
      type: 'percent',
      name: 'minLatestWeekPer',
    },
    {
      label: 'Max Latest Week Per Change',
      value: maxLatestWeekPer,
      type: 'percent',
      name: 'maxLatestWeekPer',
    },
    {
      label: 'Date',
      value: queryDate,
      type: 'queryDate',
      name: 'queryDate',
    },
    {
      label: 'No of Weeks',
      value: noOfWeeklyYTDWeeks,
      type: 'string',
      name: 'noOfWeeklyYTDWeeks',
    },
    {
      label: 'Min Transaction Value',
      value: minInsiderTransactionValue
        ? currencyFormat(
            minInsiderTransactionValue,
            false,
            false,
            2,
            false,
            true,
          )
        : minInsiderTransactionValue,
      type: 'string',
      name: 'minInsiderTransactionValue',
    },
    {
      label: 'Max Transaction Value',
      value: maxInsiderTransactionValue
        ? currencyFormat(
            maxInsiderTransactionValue,
            false,
            false,
            2,
            false,
            true,
          )
        : maxInsiderTransactionValue,
      type: 'string',
      name: 'maxInsiderTransactionValue',
    },
  ];
  const nonClickableValue = NonClickAbleFilter(reportSubType || reportType);
  const isVisible = filtersNotToShow(reportSubType || reportType);
  const isCloseAble = (name) => {
    return !nonClickableValue.includes(name);
  };
  const showFilter = (name) => {
    return !isVisible.includes(name);
  };
  return (
    <>
      FILTERS:
      {reportSubType !== 'INDIVIDUAL_STATUS_REPORT' && (
        <Tag
          onClick={handleReset}
          style={{ margin: 5, cursor: 'pointer' }}
          className={styles.vClearAllNew}
        >
          CLEAR ALL
        </Tag>
      )}
      {symbols &&
        (Array.isArray(symbols) ? (
          symbols.map((symbol) => (
            <Tag
              key={symbol}
              color={'success'}
              closable
              onClose={() => handleClick('symbols', symbol)}
            >
              {symbol}
            </Tag>
          ))
        ) : (
          <Tag
            key={symbols}
            // icon={isLoading ? <SyncOutlined spin /> : <CheckCircleOutlined />}
            //color={isLoading ? 'processing' : 'success'}
            color={'success'}
            closable
            onClose={() => handleClick('symbols', symbols)}
          >
            {symbols}
          </Tag>
        ))}
      {/* {institutionsName &&
        (Array.isArray(institutionsName) ? (
          institutionsName.map((inst) => (
            <Tag
              key={inst}
              // closable
              //onClose={() => handleClick(name, value)}
              color="success"
            >
              {inst}
            </Tag>
          ))
        ) : (
          <Tag
            key={institutionsName}
            // icon={isLoading ? <SyncOutlined spin /> : <CheckCircleOutlined />}
            color="success"
          >
            {institutionsName}
          </Tag>
        ))} */}
      {institutions &&
        (Array.isArray(institutions) ? (
          institutions.map((ins) => (
            <Tag
              key={ins}
              closable
              onClose={() => handleClick('institutions', ins)}
              color={'success'}
            >
              {ins}
            </Tag>
          ))
        ) : (
          <Tag
            key={institutions}
            color={'success'}
            closable
            onClose={() => handleClick('institutions', institutions)}
          >
            {institutions}
          </Tag>
        ))}
      {/* eslint-disable-next-line */}
      {data.map((item) => {
        const { name, value, type, label } = item;
        if ((value || value === 0) && showFilter(name)) {
          switch (type) {
            case 'currency':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${currencyFormat(value, false, false)}`}</Tag>
              );
            case 'percent':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${formatNumber2(value)}%`}</Tag>
              );
            case 'bool':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${value ? 'Yes' : 'No'}`}</Tag>
              );
            case 'string':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${value}`}</Tag>
              );
            case 'date':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${moment(value).format('MMMM DD, YYYY')}`}</Tag>
              );
            case 'queryDate':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${moment(value).format('MMMM DD, YYYY')}`}</Tag>
              );
            case 'multi':
              return Array.isArray(value) ? (
                value.map((item) => (
                  <Tag
                    key={item}
                    closable={isCloseAble(name)}
                    onClose={() => handleClick(name, item)}
                    color={'success'}
                  >
                    {name === 'multiStatus' && item === 'DECREASED'
                      ? 'REDUCED'
                      : name === 'multiStatus' && item === 'NO_CHANGE'
                      ? 'NO CHANGE'
                      : name === 'multiStatus' && item === 'NEWLY_BOUGHT'
                      ? 'NEWLY BOUGHT'
                      : name === 'multiStatus' && item === 'SOLD_OFF'
                      ? 'SOLD OFF'
                      : name === 'multiStatus' &&
                        item === 'CONSISTENTLY_DECREASED'
                      ? 'CONSISTENTLY DECREASED'
                      : name === 'multiStatus' &&
                        item === 'CONSISTENTLY_INCREASED'
                      ? 'CONSISTENTLY INCREASED'
                      : name === 'multiSector' && item === ''
                      ? 'ALL'
                      : name === 'excludeMultiSector'
                      ? `Ex:${item}`
                      : item}
                  </Tag>
                ))
              ) : (
                <Tag
                  key={value}
                  color={'success'}
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                >
                  {value}
                </Tag>
              );
          }
        }
      })}
    </>
  );
};

export default CommonHeader;
